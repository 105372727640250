@import '../../../variables.scss';
@import '@material/elevation/mixins';

.landing-page_hero {
  min-height: 370px;
  width: 100%;
  background: url('../../../components/common/mp-header.svg') center no-repeat;
  background-size: 1050px;
  background-position-x: 103%;
  background-position-y: 0px;
}

@media screen and (max-width: 1225px) {
  .landing-page_hero {
    min-height: 292px;
    background-size: contain;
    background-position-x: 100%;
  }
}

@media screen and (max-width: 985px) {
  .landing-page_hero {
    min-height: 292px;
    background-size: cover;
    background-position-x: 12%;
  }
}

@media screen and (max-width: 650px) {
  .landing-page_hero {
    min-height: 130px;
    background-size: cover;
    background-position-x: 33%;
  }
}

.landing-page_hero_grid {
  height: 100%;
  width: calc(100% - 190px);
}

@media screen and (max-width: 1024px) {
  .landing-page_hero_grid {
    width: calc(100% - 152px);
  }
}

@media screen and (max-width: 768px) {
  .landing-page_hero_grid {
    width: calc(100% - 114px);
  }
}

@media screen and (max-width: 560px) {
  .landing-page_hero_grid {
    width: 94%;
  }
}

.landing-page_hero_title1 {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 48px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 57px;
  margin-top: 68px;
}

.landing-page_hero_title2 {
  color: #6d2a71;
  font-family: $Montserrat;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -1.2px;
  line-height: 57px;
  display: inline-block;
}

@media screen and (max-width: 985px) {
  .landing-page_hero_title1 {
    color: $fortellis_light_gray2;
    font-family: $Montserrat;
    font-size: 48px;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 57px;
    margin-top: 68px;
  }
}

@media (max-width: 750px) and (min-width: 560px) {
  .landing-page_hero_title1 {
    color: $fortellis_light_gray2;
    font-family: $Montserrat;
    font-size: 48px;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 57px;
    margin-top: 68px;
  }
}

@media screen and (max-width: 560px) {
  .landing-page_hero_title1 {
    color: $fortellis_light_gray2;
    font-family: $Montserrat;
    font-size: 48px;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 57px;
    margin-top: 68px;
  }
}

.landing-page-hero_button_div {
  margin-top: 2.4%;
}

.material-icons {
  font-family: 'Material Icons';
}

.landing-page_grid {
  padding-left: 0px;
}

.landing-page_title_div {
  width: 55%;
}

/* LandingPageSearchBox */

.search_box_div {
  width: 100%;
  z-index: 1;
  position: absolute;
}

.search_box_list_item:hover {
  background-color: rgba(0, 0, 0, 0.055);
  cursor: pointer;
}

.search_box_list_wrapper {
  border-left: 1px solid rgba(21, 27, 37, 0.5);
  border-right: 1px solid rgba(21, 27, 37, 0.5);
  border-bottom: 1px solid rgba(21, 27, 37, 0.5);
}

/* Footer Div */

.footer_div {
  min-height: 292px;
  width: 100%;
  background-color: $fortellis_blue1;
  margin-top: 70px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .footer_div {
    min-height: 292px;
  }
}

.footer__title_div {
  max-width: 350px;
  color: $white;
  font-family: $Montserrat;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
  margin: 60px auto 0px auto;
  display: inline-flex;
}

.footer__description_div {
  width: 58%;
  color: $white;
  font-family: $Raleway;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin: 16px auto 0px auto;
}

.footer__button {
  box-sizing: border-box;
  height: 40px;
  width: 218px;
  border: 2px solid $white;
  border-radius: 4px;
  margin-top: 32px;
  text-align: center;
}

.footer__button_text {
  height: 36px;
  color: $white;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 36px;
}
