@import '../../../../src/variables.scss';

.checkbox-holder-nl {
  // padding: 11px 0 11px 8px;
  display: flex;
  align-items: flex-start;
}

.checkbox-label-nl {
  color: $fortellis_light_gray2;
  font-family: $OpenSans;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  word-break: break-word;
  padding: 10px 0px;
  width: fit-content;
}
