$color_1: #3b3331;
$black: black;
$green: #59b83c;
$fortellis_dark_gray: #686868;
$white: #ffffff;
$white2: #fefefe;
$fortellis_black: #232323;
$fortellis_black1: #151b25;
$fortellis_purple: #904778;
$fortellis_purple_secondary: #c7a3bb;
$fortellis_purple_selected: #f1e9ee;
$fortellis_grey: #f5f5f5;
$Navlink_border: #686868;
$fortellis_error_red: #b00020;
$fortellis_blue: #308cb0;
$fortellis_blue1: #019cdf;
$fortellis_light_gray: rgba(0, 0, 0, 0.54);
$fortellis_light_gray2: rgba(0, 0, 0, 0.87);
$fortellis_light_gray3: rgba(0, 0, 0, 0.04);
$fortellis_light_gray4: rgba(0, 0, 0, 0.73);
$fortellis_light_gray5: rgba(0, 0, 0, 0.87);
$fortellis_footer_links_gray: rgba(255, 255, 255, 0.7);
$footer_vertical_separator: #979797;
$fortellis_divider_gray: #5f6165;
$fortellis_light_gray3: #f8f8f8;
$fortellis_light_gray6: #fafafa;
$fortellis_text_color1: #202632;
$fortellis_text_color2: rgba(0, 0, 0, 0.7);
$fortellis_text_color3: rgba(0, 0, 0, 0.6);
$fortellis_light_purple: rgba(144, 71, 120, 0.08);
$Montserrat: Montserrat;
$Raleway: Raleway;
$Roboto: Roboto;
$sans-serif: sans-serif;
$OpenSans: Open sans;
$color_left_nav_list_item: #424242;
$fortellis_badge_grey: #5a6377;
$static_image_path: 'components/common/images';
$hover_highlight: rgba(255, 255, 255, 0.2);
$border: rgba(0, 0, 0, 0.12);
$border2: rgba(0, 0, 0, 0.2);
$border3: rgba(0, 0, 0, 0.14);
$star_yellow: #ff9500;
$divider_background_color: rgba(0, 0, 0, 0.24);
$box_shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
  0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px 0 rgba(0, 0, 0, 0.2);
$box_shadow2: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
  0 1px 5px 0 rgba(0, 0, 0, 0.2);
$box_shadow3: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.2);
$fortellis-system-negative: #cc0000;
$fortellis-system-positive: #1e8700;
$capture-coordinates__page_navigator_button: rgb(242, 242, 242);
$capture-coordinates__page_navigator_button_i: #525659;
$capture-coordinates__currentPage_font: Roboto, system-ui, sans-serif;
$react-pdf__Document: rgb(233, 233, 233);
$capture-coordinates-pdf-container: rgb(233, 233, 233);
$capture-coordinates-no-border-button: rgba(0, 0, 0, 0.54) !important;
$capture-coordinates-button: rgba(0, 0, 0, 0.54) !important;
$terms-of-use-header-text: rgba(0, 0, 0, 0.54);
$terms-of-use-header-text-font: Montserrat;
$capture-coordinates-button-border: 2px solid #b00020;
$capture-coordinates__delete_button: #cc0000;
$capture-coordinates__field_container_font: Maven Pro, Arial, sans-serif;
$capture-coordinates__field: rgba(243, 193, 24, 0.54);
