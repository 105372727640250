.frc-header {
  background: url('../images/subpage-header-bg.svg') center no-repeat;
  background-size: cover;

  &--cover {
    width: 100%;
  }
}
.frc-drawer__header {
  &--primary {
    background: url('../images/subpage-header-bg.svg') center no-repeat;
    background-size: cover;
  }
}
.frc-user-card {
  background: url('../images/AvatarBackground.svg') no-repeat;

  &__row {
    max-width: 320px;
  }
}
