.background-rectangle {
  height: 32px;
  min-width: 48px;
  padding: 0px 12px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 5px;
  margin-left: 5px;
}

.toggle-background-rectangle {
  height: 32px;
  width: inherit;
  border-radius: 16px;
  background-color: #904778;
  margin-right: 5px;
  margin-left: 5px;
  min-width: 48px;
  padding: 0px 12px;
}

.chip-text {
  height: 30px;
  width: 100%;
  color: #232323;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.toggle-chip-text {
  height: 30px;
  width: 100%;
  color: #ffffff;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.container-shadow-2-dp {
  height: 652px;
  width: 1140px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.flat-bg {
  height: 64px;
  width: 1140px;
}

.table-text {
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  align-content: left;
}

.table-link {
  height: 48px;
  width: 63px;
  color: #904778;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 48px;
}

.app-name-holder {
  display: flex;
}

.app-name-image-holder {
  display: flex;
  align-items: center;
}

.app-name-caption-holder {
  max-width: 30vw;
  overflow: auto;
  white-space: pre-line;
  word-break: break-word;
}

.mp-dnp-app-name-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 12px;
  justify-content: center;
}

.solution-common-header-subtitles {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #151b25;
}

.solution-common-header-subtitles-value {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #151b25;
  max-width: 30vw;
  overflow: auto;
  white-space: pre-line;
  word-break: break-word;
}

.no-listing-fee-head {
  margin-top: -20px;
  margin-bottom: 4px;
  font-size: 14px;
}
