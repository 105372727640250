@import '../../variables.scss';

.video_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.play_button_wrapper {
  position: relative;
}
.play_button:hover svg {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.play_button svg {
  margin: 16px;
}
.play_button {
  position: absolute;
  height: 90px;
  width: 90px;
  top: 37px;
  left: 104px;
  cursor: pointer;
}
.videoCell__div {
  vertical-align: middle !important;
  position: relative;
}

.videoCell__div {
  .mdc-dialog .mdc-dialog__surface {
    max-width: unset;
  }
  @media (min-width: 592px) {
    .mdc-dialog .mdc-dialog__surface {
      max-width: unset;
    }
  }
}

.video_wrapper {
  height: 165px;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  display: inline-block;
}
.videoCell__video-list {
  height: 165px;
  width: 100%;
  display: inline-block;
}
.videoCell__close {
  float: right;
  margin-left: 93%;
  cursor: pointer;
}

.videoCell_navigation-arrow {
  position: relative;
  height: 0px !important;
  width: 0px !important;
}
.videoCell__mask {
  height: 268px;
  width: 288px;
  background-color: $white;
  margin: 5px 8px 5px 8px;
  display: inline-block;
  align-items: center;
  vertical-align: middle;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.videoCell__title {
  height: 25px;
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 8px 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.videoCell__description {
  height: 50px;
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 8px 11px;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
}
.videoCell__back-arrow {
  position: absolute !important;
  height: 45px !important;
  width: 45px !important;
  z-index: 1;
  cursor: pointer;
  left: 1px;
  top: 110px;
}
.videoCell__next-arrow {
  position: absolute !important;
  height: 45px !important;
  width: 45px !important;
  left: 96%;
  z-index: 1;
  cursor: pointer;
  top: 110px;
}
.videoCell_dilog_body {
  width: 50%;
}
.videoCell__dilog_title {
  display: inline-block;
  width: 95%;
  height: 32px;
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  @media (max-width: 768px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    width: 70%;
  }
}
.videoCell__dilog {
  display: none !important;
  visibility: hidden !important;
}

.mdc_dilog_parent {
  .mdc-dialog__surface {
    max-height: unset !important;
    height: '500px' !important;
    width: '600px' !important;
  }
  .videoCell__iframe {
    height: '100%' !important;
    width: 'calc(100% + 48px)' !important;
    margin: '0px -24px -31px' !important;
  }
}
.videoCell__dilog_header {
  height: 48px;
  margin: -10px;
}
.videoCell__close_button {
  float: right;
  margin-top: 6px;
  cursor: pointer;
}
.video-gallery-frame {
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  display: inline-block;
  scrollbar-width: none;
}
.video-gallery-frame::-webkit-scrollbar {
  display: none;
}
.video-gallery-images-container {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

/*-----------------------image gallery-----------------------------*/

.image-gallery {
  vertical-align: middle !important;
  position: relative;
}

.image-gallery-frame {
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  display: inline-block;
  scrollbar-width: none;
}

.image-gallery-images-container {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.image-gallery-image {
  height: 200px;
  width: 300px;
  margin: 5px 16px 5px 0;
  background-size: cover;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.image-gallery-frame::-webkit-scrollbar {
  display: none;
}

.image-gallery-frame_navigation-arrow-wrapper {
  position: absolute !important;
  z-index: 1;
  height: 45px !important;
  width: 45px !important;
  bottom: 90px;
  cursor: pointer;
}

.image-gallery-frame_next-arrow {
  position: absolute !important;
  height: 55px !important;
  width: 55px !important;
  z-index: 1;
  cursor: pointer;
}

.image-gallery-frame_back-arrow {
  position: absolute !important;
  height: 55px !important;
  width: 55px !important;
  z-index: 1;
  cursor: pointer;
}

.connect_with_stripe {
  height: 30px !important;
  width: 130px !important;
  cursor: pointer;
  margin: 0px 12px;
}

.account_number {
  font-size: 18px;
  letter-spacing: 1px;
}

.payment_loading {
  text-align: center;
}

.DayPickerNavigation_button__horizontal {
  position: initial;
}

.remittance-date-range {
  display: grid;
  grid-template-columns: 10fr 1fr;
}

.payment_info_header {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin: 4px 12px;
}

.payment_info_text {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin: 4px;
}

.account-icon {
  padding: 23px 0px 0px 24px;
  font-size: 32px !important;
}

.account-link-icon {
  vertical-align: middle;
  float: right;
  cursor: pointer;
  z-index: 1;
  position: relative;
  margin: 0px 6px;
}

.manually_ach-icon {
  padding: 23px 0px 0px 24px;
  font-size: 32px !important;
}

.payment_manually_ach {
  cursor: pointer;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin: 4px;
  display: flex;
}

.account-management-panel-header {
  padding: 24px 24px 10px 10px;
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
}

.account-metric-value {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.payment_divider {
  width: 370px;
  margin: auto 0px;
}

.ach_button {
  margin: auto;
}

.payment_divider_flex {
  display: flex;
  margin: 16px 0px;
}
.payment_divider_text {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  background-color: white;
  padding: 0px 16px;
}

.ach_form {
  width: 500px;
  margin: 0px 5px;
}

.ach_save_button {
  margin: 12px 0px;
}
.connect_with_stripe_text_box {
  display: flex;
}

.sc-qPwPv {
  display: contents !important;
}

.imageGallery_dialog {
  width: 45%;
  height: 55%;
}

.imageGallery_dialog_close {
  position: absolute !important;
  margin-bottom: 138% !important;
  margin-left: 90% !important;
}

.maximize {
  height: 100%;
  width: 100%;
}

.maximize_img {
  height: 100%;
  width: 100%;
  display: table !important;
}

@media screen and (min-width: 400px) and (max-height: 550px) {
  .maximize_img {
    height: 150px;
    width: 100%;
    display: table !important;
  }
}

@media screen and (min-width: 600px) and (min-height: 550px) {
  .maximize_img {
    height: 100%;
    width: 100%;
    display: table !important;
  }
}

/*-----------------------eo image gallery-----------------------------*/
