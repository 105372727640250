@import '../../../../src/variables.scss';

.expansion-panel-container-nl,
.new-expansion-panel {
  width: 225px;
}

.expansion-panel-container-nl {
  & .cdk-expansion-panel,
  & .cdk-expansion-panel.cdk-expansion-panel--expanded {
    box-shadow: none !important;
    margin: 0 0;
    background-color: $white;
  }

  & .cdk-expansion-panel:last-of-type,
  & .cdk-expansion-panel.cdk-expansion-panel--expanded:last-of-type {
    border-bottom: 0px solid $border;
  }

  & .cdk-expansion-panel__header {
    height: 32px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 0px 0px;
  }

  & .content-body {
    padding: 0px 0px 16px 0px;
  }

  & .cdk-expansion-panel__header:hover {
    background-color: $fortellis_light_gray6;
  }

  & .cdk-expansion-panel--expanded > .cdk-expansion-panel__header {
    margin-bottom: 8px;
  }

  & .cdk-expansion-panel--expanded > .cdk-expansion-panel__header:hover {
    background-color: $fortellis_light_gray6;
  }

  & .cdk-expansion-panel__header .header-title {
    flex-basis: 100%;
    color: $fortellis_light_gray2;
    font-family: $Montserrat;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 32px;
  }
}

.filters-section-container-nl .new-expansion-panel {
  & > div {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    transition: margin 225ms;
  }

  & > div:first-child {
    border-top: none;
  }

  & > div:last-child {
    border-bottom: none;
  }
}

.filters-section-container-nl .new-expansion-panel > div > button {
  padding: 1.25rem 0.75rem 0.75rem 0.25rem;
}

.filters-section-container-nl .new-expansion-panel > div > button:hover {
  background-color: inherit;
}
.filters-section-container-nl .new-expansion-panel > div > button:focus {
  background-color: inherit;
}

.filters-section-container-nl .new-expansion-panel > div > button > span {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
}

.filters-section-container-nl .panel-content {
  padding: 0px 1rem 1rem 0px;
}
