.mdc-menu--open {
  right: 0px;
  margin-top: 36px;
  margin-left: 50px;
}

.app-activation-subscriptions .mdc-menu-anchor .mdc-menu.mdc-menu--open {
  width: 120px;
  min-width: auto;
  left: unset !important;
}

.app-activation-subscriptions
  .mdc-menu-anchor
  .mdc-menu.mdc-menu--open
  ul
  > li {
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: Raleway !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  max-height: 30px !important;
}

.app-activation-subscriptions
  .mdc-menu-anchor
  .mdc-menu.mdc-menu--open
  ul
  > li:hover {
  color: #904778 !important;
  font-weight: 600 !important;
}

.app-activation-save-btn {
  margin-top: 80px;
  float: right;
  margin-bottom: 30px;
}

.app-activation-subscriptions
  .mdc-menu-anchor
  .mdc-button.mdc-button--icon-only {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.87) !important;
}

.app-activation-subscription-div .mdc-snackbar__text {
  height: 64px;
}

.info-icon {
  opacity: 0.6;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.app-activation-status-div {
  display: inline-flex;
}

.app-activation-subscription-div .cdk-tooltip {
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  right: 52%;
  position: absolute;
  bottom: 10%;
  min-width: 200px;
  white-space: pre-wrap;
  word-break: break-word;
}
