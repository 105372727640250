@import '../../variables.scss';

.purchase-sol__plans-pricing {
  height: 47px;
  width: 268px;
  color: #3b3331;
  font-family: Raleway;
  font-size: 28px;
  font-weight: bold;
  line-height: 47px;
  margin-top: 0px !important;
}

.solution_free_plan_div {
  padding-right: 10px;
}

.solution_free_plan {
  padding: 16px;
  margin: 0px;
  line-height: 0px;
}

.free-trial_badge {
  display: inline-block;
  background: #d9eff8;
  height: 25px;
  width: 77px;
  margin-top: 11px;
  margin-left: 10px;
}

.free-trial_text {
  font-family: $Montserrat;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  display: block;
  color: #0b587b;
  padding: 6px 11px;
}

.sol-details__headings {
  font-family: $Montserrat !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}

.purchase-sol__plans-list {
  .mdc-list-item {
    cursor: pointer;
    width: 100% !important;
    height: 100% !important;
    color: $color_left_nav_list_item !important;
    font-family: $Montserrat;
    font-size: 14px;
    font-weight: 600;
    /*line-height: 32px;*/
    padding: 0px 0px 0px 10px !important;
    text-overflow: ellipsis !important;
    word-wrap: break-word !important;
    white-space: pre-wrap !important;
  }
}

.purchase-sol__plans-list-item {
  height: 3.13% !important;
  width: 100% !important;
  border-radius: 4px;
}

.purchase-sol__plans-list {
  :hover {
    opacity: 0.85;
    color: $fortellis_black;
  }
  :active {
    background-color: $fortellis_purple;
    color: white !important;
  }
}

.pricing-media-card {
  height: 550px;
  width: 300px;
  margin: 10px 20px;
  border-radius: 0% !important;
  //overflow-y: scroll;

  div:first-child {
    display: block;
    border-radius: 0px;
  }
}

.iconCheckCircleLogo {
  width: 30px;
}

.purchase-sol__plans-list-item_selected {
  cursor: pointer !important;
  background-color: $fortellis_purple;
  color: white !important;
  border-radius: 4px;
  span {
    background-color: $fortellis_purple;
    color: white !important;
  }
}

.edition-feature__subtitle {
  float: right !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.edition-features__description {
  font-size: 14px !important;
  font-family: $OpenSans !important;
  font-weight: normal !important;
  color: #151b25 !important;
}

.edition-features__checkmark {
  height: 30px !important;
  width: 30px !important;
}

.edition-feature_sub-heading {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.1px;
  padding-left: 45px;
  padding-bottom: 10px;
}

.edition-feature__lists {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: $OpenSans !important;
  line-height: 20px !important;
  margin-bottom: 10px !important;
  color: #151b25;
  //width: 182px !important;
  height: auto !important;
}

.plans-and-pricing-feature-list {
  text-align: center;
  max-height: 200px;
  overflow-y: scroll;
  //padding-left: 45px !important;
  .mdc-list-item {
    height: auto;
  }
}

.view-feature-details {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding-left: 15% !important;
  padding-top: 5% !important;
}

[data-testid='view-more'] {
  border-radius: 0px !important;
  border: none !important;
  background-color: transparent !important;
}

.purchase-sol__plans-list {
  padding: 0px !important;
}

.purchase-sol__plans-list-item-secondary {
  height: 1.04%;
  color: $fortellis_black !important;
  font-family: Raleway;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 32px;
  padding-bottom: 8px;
}

.pricing-plan-div {
  padding: 24px 0px !important;
}

.purchase-sol__plans-list-item-name,
.mdc-list-item__meta {
  margin-top: 0;
  font-size: 24px;
  color: $fortellis_black;
  font-weight: 500;
  line-height: 25px;
  padding-bottom: 12px;
  padding-top: 12px;
}

.purchase-sol__card {
  height: 12.53%;
  border-radius: 4px;
  background-color: $white;
  box-shadow: $box_shadow2;
  padding: 10px !important;
  text-align: center;
}

.purchase-sol__card-title {
  color: $fortellis_black !important;
  font-family: Raleway !important;
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: 40px !important;
  float: left;
}

.purchase-sol__card-title-name {
  line-height: 24px !important;
}

.purchase-sol__video {
  height: 31.53%;
  width: 69.06%;
}

.purchase-sol__images {
  min-height: 30.13%;
  width: 100%;
}

.policies__data-table td {
  text-align: left;
}

.freeTrial-label {
  margin-left: 34px;
  height: 24px;
  width: 20%;
  margin-bottom: 7px;
}

.freeTrial-label-text {
  color: $white;
  background-color: #5a6377;
  font-family: $OpenSans;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 5px 8px 5px 8px;
  border-radius: 2px;
  vertical-align: middle;
}

.purchase-sol__details {
  color: #151b25;
  margin: 0;
  font-family: $OpenSans;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 1.33;
}

h1.purchase-sol__details {
  margin-bottom: 10px;
  font-size: 1.125rem;
}

h1.purchase-sol__details > span {
  color: #151b25;
  margin: 0;
  font-family: $Montserrat;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 1.33;
}

.edition-features__subheading {
  // height: 1.04%;
  // color: #151B25;
  // font-family: $OpenSans;;
  // font-size: 14px !important;
  // font-weight: normal !important;
  // line-height: 20px !important;
  // margin-top: 0px !important;
  // margin-bottom: 0px !important;
  font-family: $OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(21, 27, 37, 0.701961);
}

.editionFea__name {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #151b25;
}

.editionFea__Price {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #151b25;
  margin-top: 24px;
  margin-bottom: 10px;
  //height: 75px;
}

.editionFea__freeTrial {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(21, 27, 37, 0.701961);
}

.editionFea__frequency {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #151b25;
}

.editionFea__details {
  padding-bottom: 10px;
  line-height: 25px;
}

.purchase-sol__indicator-tag {
  vertical-align: middle;
  line-height: 7px;
  margin-left: 32px;
}

.mdc-list-item__graphic {
  margin-right: 5px !important;
}

.sol-description__read-more {
  border: 0px !important;
  text-transform: none !important;
  padding: 0px !important;
  margin: -5px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  :hover span,
  :active span {
    background: white !important;
  }
}

.plans-pricing__selected {
  height: 3.13% !important;
  width: 43.28%;
  border-radius: 4px;
  background-color: $fortellis_purple;
  color: white !important;
}

.features__list {
  font-weight: 500;
  font-family: Raleway;
  margin-top: 10px;
  margin-bottom: 10px;
  :active {
    font-weight: 500;
    font-family: Raleway;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $fortellis_purple;
    color: white !important;
  }
}

.features__list-selected {
  font-weight: 500;
  font-family: Raleway;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $fortellis_purple;
  color: white !important;
}

.resources__link {
  text-decoration: underline;
  color: $fortellis_purple !important;
  font-family: Raleway !important;
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  opacity: 54 !important;
}

.read-more-link {
  width: 95% !important;
  color: $fortellis_purple !important;
  font-family: $Raleway !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  opacity: 54;
  margin-bottom: 10px !important;
  margin-top: 4px !important;
  cursor: pointer !important;
}

.policies__value {
  :hover {
    cursor: pointer !important;
  }
  width: 95% !important;
  color: $fortellis_purple !important;
  font-family: Raleway !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  opacity: 54;
  margin-bottom: 10px !important;
  margin-top: 4px !important;
}

.policies__phone {
  width: 95% !important;
  color: black !important;
  font-family: Raleway !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  opacity: 54;
  margin-bottom: 10px !important;
  margin-top: 4px !important;
}

.sol-details_support_title {
  color: $fortellis_dark_gray !important;
  font-family: Raleway !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 12px !important;
  margin-top: 32px !important;
  margin-bottom: 0px !important;
}

.policies__divider {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.contact-publisher-top {
  padding: 0px !important;
  width: 100%;
  border-width: 1px !important;
  font-size: 13px !important;
  letter-spacing: 1px !important;
}

.manage-solution-top {
  padding: 0px !important;
  width: 100%;
  border-width: 1px !important;
  font-size: 13px !important;
  letter-spacing: 1px !important;
  margin-top: -6% !important;
  margin-bottom: 14px;
}

.manage-subscription-top {
  height: 36px !important;
  width: fit-content;
  box-shadow: $box_shadow2;
  margin-left: 3.5%;
}

.contact-publisher-email-textbox {
  margin-left: 1.5%;
}

.overview__copyright {
  width: 10px;
  height: 10px;
  margin-left: 58px;
  margin-top: -71px;
}

.subscribe-button {
  height: 36px !important;
  width: auto;
  box-shadow: $box_shadow2;
  margin-bottom: 20px !important;
  width: 90%;
}

/* -------------- rating --------------*/
.add_star_ratings {
  min-height: 24px;
  display: inline-block;
  color: $star_yellow;
  cursor: pointer;
}

.star_ratings {
  min-height: 24px;
  display: inline-block;
  color: $star_yellow;
}

.star_ratings i {
  vertical-align: middle;
}

.star_ratings .default-rating {
  cursor: pointer;
  height: 18px;
  width: 19px;
  color: $star_yellow;
  font-size: 18px;
  line-height: 18px;
}

.star_ratings .profile-rating-dead {
  height: 24px;
  width: 24px;
  color: rgba(0, 0, 0, 0.38) !important;
  font-size: 24px;
  line-height: 24px;
}

.star_ratings .profile-rating-colored {
  height: 24px;
  width: 24px;
  color: $star_yellow;
  font-size: 24px;
  line-height: 24px;
}

.star_ratings .add-rating {
  height: 36px;
  width: 40px;
  color: $star_yellow;
  font-size: 36px;
  line-height: 36px;
}

.star_ratings .review-rating {
  height: 18px;
  width: 26px;
  color: $star_yellow;
  font-size: 25px;
  line-height: 18px;
}

/* --------------eo rating --------------*/
/* -------------- review --------------*/
.be-the-first {
  margin-top: 8px;
  color: $fortellis_purple;
  font-family: $OpenSans;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}

.rating-number {
  color: #000000;
  font-family: $Montserrat;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  font-style: normal;
  line-height: 64px;
  letter-spacing: -1.2px;
}

.number-of-reviews {
  width: 40px;
  height: 20px;
  color: #151b25;
  font-family: $OpenSans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin-right: 4px;
}

.review-delete-subheading {
  display: block;
  font-size: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.review-delete-text {
  font-size: 14px;
  padding-left: 20px;
}

.number-of-reviews-text {
  height: 16px;
  color: #151b25;
  font-family: $OpenSans;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
}

[data-testid='base-review-input'] {
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid !important;
  background-color: transparent !important;
  height: 70px !important;

  &:hover {
    border: none !important;
    border-bottom: 1px solid !important;
    box-sizing: initial;
    box-shadow: none;
  }
}

[data-testid='review-input-label'] {
  font-size: 14px !important;
}

[data-testid='review-input'] {
  height: 37px !important;
}

[data-testid='review-textarea-content'] {
  margin-top: 30px !important;
}
[data-testid='review-textarea-label'] {
  display: inline-table;
}

[data-testid='review-textarea'] {
  margin-top: 10px !important;
}
/*--------------review Tab------------*/
.add-review-container {
  background-color: #f9f9f9;
  padding: 16px 32px 16px 47px;
}

.review-container {
  margin-top: 16px;
}

.add-a-review-for-app {
  color: $fortellis_black;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;

  margin-bottom: 10px;
}

.reviewer-mail {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #151b25;
}

.review-date {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #151b25;
}

.review-headline {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.review-desc {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.review-response-container {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.05);
}

[data-testid='review-response-textarea-label'] {
  display: inline-table;
}

.response-by {
  color: $fortellis_black;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.delete-review-icon {
  height: 24px;
  width: 24px;
  color: $fortellis_light_gray;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  float: right;
  cursor: pointer;
}

.submitted-msg {
  font-size: 12px;
  font-weight: 600;
  color: #aab7b8;
}

.add-review-response-link {
  cursor: pointer;

  color: $fortellis_purple;
  font-family: $OpenSans;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.add-review-container .mdc-text-field--textarea .mdc-floating-label {
  background: #f9f9f9 !important;
}

.appDetails-page-secondary-control-buttons {
  margin: 10px 0px;
}

button.appDetails-page-secondary-control-buttons,
.appDetails-page-secondary-control-buttons button {
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
}

/* -------------- eo review --------------*/

.contact_publisher_modal_clause {
  min-height: 84px;
  border-radius: 3px;
  background-color: #f7f7f7;
  align-self: center;
}

.contact_publisher_modal_clause_content {
  min-height: auto;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01px;
  line-height: 24px;
  padding: 16px;
  margin: 0px 0px;
}

.contact-publisher-dialog .mdc-dialog--open .mdc-dialog__surface {
  height: auto;
  width: 600px;
  border-radius: 2px;
  padding-bottom: 8px !important;
  background-color: $white;
  box-shadow: $box_shadow;

  .mdc-dialog__header__title {
    height: 24px;
    width: 112px;
    color: $fortellis_light_gray2;
    font-family: $Montserrat;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
}

div.contact-publisher-dialog-box {
  width: 600px;
  height: auto;
}

.contact-publisher-dialog-content {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.contact-publisher-user-name {
  margin-bottom: 20px;
}

.contact-publisher-email-input {
  margin-bottom: 20px;
}

.add-message_text-area-wrapper {
  margin-bottom: 20px;
}

[data-testid='add-message_text-area-label'] {
  display: inline-table;
}

.add-message_text-area-wrapper > .add-message-text-area > span {
  color: rgba(21, 27, 37, 0.7);
}

.contact-publisher-loader {
  display: flex;
  margin: 40px 0px;
}

.solution-details-header-div {
  display: flex !important;
}

.solution-subscription-status-div {
  margin-left: auto !important;
  text-align: right !important;
}

.subscription-action-disable-message {
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 24px !important;
  margin-top: 16px !important;
}
.solution-subscription-status-note {
  color: $fortellis_light_gray !important;
  font-family: $Raleway !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
}

.solution-subscription-status {
  height: 24px !important;
}

/*======================solution details page basic structure=========================*/

.solution-details-column-left {
  width: 190px;
  display: inline-block;
  margin-right: 24px;
}

.solution-details-column-right {
  width: calc(100% - 214px);
  display: inline-block;
  float: right;
  margin-bottom: 96px;
}

@media screen and (max-width: 730px) {
  .solution-details-column-left {
    display: unset;
    float: unset;
    margin: auto;
  }

  .solution-details-column-right {
    display: unset;
    float: unset;
    margin: auto;
  }
}

@media screen and (max-width: 730px) {
  .solution-details-column-left {
    text-align: center;
  }
}

.plans-and-pricing-container {
  background-color: $fortellis_grey;
  padding-top: 24px;
  padding-bottom: 24px;
  clear: both;
}

.no-plans-yet {
  text-align: center;
  font-family: $Montserrat;
  font-size: 24px;
  letter-spacing: -0.3px;
  line-height: 48px;
  font-weight: 500;
}

/*======================eo solution details page basic structure======================*/

/*========================== solution details page - subscribe ========================*/

.subscribe-stepper {
  &.loading-spinner-fortellis-dialog .mdc-dialog--open .mdc-dialog__surface {
    max-width: 768px;
  }
}
/*======================== eo solution details page - subscribe =======================*/

/*======================solution details - overview section======================*/
.solution-details-overview-briefDescription {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 32px;
  white-space: pre-line;
}
.solution-details-overview-detailedDescription {
  margin-top: 10px;
  color: $fortellis_light_gray;
  font-family: $OpenSans;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  white-space: pre-line;
}
.read-more-less-text {
  cursor: pointer;
  color: $fortellis_purple;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
/*======================eo solution details overview section=====================*/

/*======================solution details highlights section======================*/
.highlights-container {
  margin-bottom: 32px;
}

.highlights-header {
  color: $fortellis_light_gray2;
  font-family: $OpenSans;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
  margin-bottom: 8px;
}

.highlight-list ul {
  padding-left: 15px;
}

.highlight-list ul li {
  color: $fortellis_light_gray;
  font-family: $OpenSans;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.feature-list_feature {
  margin-bottom: 32px;
}

.feature-list-img-container {
  width: 240px;
}

.feature-list-img-shadow {
  border: 1px solid $border2;
}

@media screen and (min-width: 450px) {
  .feature-list-img-container {
    margin-right: 24px;
    display: inline-block;
    float: left;
  }

  .feature-list-description-column {
    display: inline-block;
    float: right;
    width: calc(100% - 240px - 24px);
  }
}

.feature-list-feature-title {
  color: $fortellis_light_gray2;
  font-family: $OpenSans;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin-bottom: 8px;
}

.feature-list-feature-description {
  color: $fortellis_light_gray;
  font-family: $OpenSans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.feature-title {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
  margin-bottom: 16px;
}
/*======================eo solution details highlights section===================*/
/*======================overview - whats new section===================*/
.whats-new-section {
  padding: 24px;
  background-color: $fortellis_light_gray3;
}

.whats-new-section {
  .title {
    color: $fortellis_light_gray2;
    font-family: $OpenSans;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 32px;
  }

  .sub-title {
    color: $fortellis_dark_gray;
    font-family: $OpenSans;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
  }

  .description {
    color: $fortellis_light_gray;
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .whats-new-list-items {
    color: $fortellis_light_gray;
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;

    ul {
      padding-left: 15px;

      li {
        margin-bottom: 8px;
      }
    }
  }
}
/*====================== eo overview - whats new section===================*/
/*====================== support - tutorials and documentation section===================*/
.solution-details-support-section {
  .tutorial-docs-section {
    .title {
      color: #151b25;
      font-family: $OpenSans;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.15px;
      line-height: 20px;
      margin: 8px 0px;
    }

    .document-list-item {
      margin-bottom: 16px !important;

      .link {
        color: #5e2368;
        font-family: $OpenSans;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }

      .description {
        color: rgba(21, 27, 37, 0.701961);
        font-family: $OpenSans;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .solution-support-section {
    margin-top: 32px;

    .header {
      position: static;
      height: 20px;
      font-family: $Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #151b25;
      margin: 8px 0px;
    }

    .support-note {
      position: static;
      width: 80%;
      top: 28px;
      font-family: $OpenSans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: rgba(21, 27, 37, 0.701961);
    }

    .support-details {
      margin-top: 16px;

      .support-details-item {
        font-family: $OpenSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: rgba(21, 27, 37, 0.701961);
        mix-blend-mode: normal;
        opacity: 0.7;
        padding-right: 70px;
      }
    }

    .link {
      color: #5e2368;
      font-family: $OpenSans;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }

    .phone {
      color: #151b25;
      font-family: $OpenSans;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .support-community {
    //container
    background: #f3f3f3;
    border-radius: 8px;
    padding: 8px 16px;
    margin-top: 16px;
    //text
    font-family: $OpenSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #151b25;

    .url {
      color: #5e2368;
    }
  }
}
/*====================== eo support - tutorials and documentation section===================*/
