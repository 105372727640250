@import '../../../../src/variables.scss';

.no_results_container-nl {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}

.no-results-found-for_label-nl {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
}

.no-results-found-for_searchText-nl {
  font-weight: bold;
}

.check-spelling-and_label-nl {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
