@import '../../variables.scss';

.filters-section-container-nl {
  display: inline-block;
  width: 235px;
  overflow-y: hidden;
  overflow-x: hidden;
  float: left;
}

.filters-section-container-nl:hover {
  overflow-y: scroll;
}

.filters-section-container-nl::-webkit-scrollbar {
  width: 0.4em;
}
.filters-section-container-nl::-webkit-scrollbar,
.filters-section-container-nl::-webkit-scrollbar-thumb {
  overflow-y: visible;
  border-radius: 4px;
}
.filters-section-container-nl::-webkit-scrollbar-thumb {
  background: #d8d8d8;
}

.filters-section-container-nl::-webkit-scrollbar,
.filters-section-container-nl::-webkit-scrollbar-thumb {
  overflow-y: scroll;
}

.cards-section-container-nl {
  width: calc(100% - 235px);
  display: inline-block;
  padding-top: 24px;
}

body {
  background-color: $white;
}

.error_div {
  margin-top: 75px;
  text-align: center;
}
