@import '../../../../src/variables.scss';

.filters-section-container-nl .show-more-div-nl {
  padding: 8px 0 8px 8px;
}

.filters-section-container-nl .show-more-div-nl .show-more-nl {
  color: $fortellis_purple;
  font-family: $Montserrat;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
}
