.subscriptions-data-page-title {
  height: 40px;
  font-size: 30px;
  letter-spacing: 0.25px;
  line-height: 40px;
  margin-bottom: 25px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
}

.subscription-search-radio-buttons {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.subscription-search-bar {
  display: grid;
  grid-template-columns: 8fr 2fr;
  grid-gap: 10px;
  -ms-grid-columns: 8fr 2fr;
  margin-bottom: 20px;
}

.search-subscriptions {
  max-width: 70%;
  margin: auto;
}

.search-results-container {
  position: absolute;
  width: 58%;
  display: grid;
  grid-template-columns: 8fr 2fr;
  margin-top: 2px;
}

.search-results-popup {
  max-height: 300px;
  overflow-y: auto;
  border: 0.5px solid #e8e8e9;
  border-radius: 8px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.fetch-suggestion-loader {
  stroke: #5e2368;
}

.fetch-subscriptions-loader {
  text-align: center;
  margin-top: 50px;
}

.table {
  margin-top: 50px;

  &_header {
    &_id {
      padding-left: 25px;
      font-size: 15px;
    }
  }

  .ag-body-viewport {
    min-height: 3.25rem !important;
  }
}

.subscription {
  &_table-title,
  &_table-app-name-container {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &_table-unpublished-app-name {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_app-privacy-protection {
    padding-top: 4px;
    fill: rgba(21, 27, 37, 0.7);
  }

  &_details-dialog {
    width: 80vw !important;
    max-height: 90vh !important;
    overflow-y: auto !important;
  }

  &_details-privacy-protection {
    display: inline-block;
    margin-left: 0.15rem;
  }

  /* TODO remove this once subscription details are brought out onto their own page */
  &_details-fields-dialog {
    z-index: 7 !important;
    overflow-y: auto !important;
  }

  &_body-grid {
    display: grid;
    line-height: 35px;
    padding: 5px 10px;
    grid-template-columns: 1fr 2fr;
    border-bottom: 1px solid #e0e0e0;
  }

  &_loader {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    display: inline-block;
    margin: 1px;
    animation: pulse 1s ease-in infinite;
    -webkit-animation: pulse 1.2s ease-in infinite;
  }

  &_cpp_info {
    display: inline-flex;
  }

  &_cpp_info_icon {
    padding-top: 6px;
    padding-left: 5px;
  }
}

/* background overlay */
/* TODO remove this once subscription details are brought out onto their own page */
#subscription_details-fields-dialog-portal > div > div > :last-child {
  z-index: 6 !important;
}

/* put the tooltip above the dialog */
#api-privacy-protection-info-tooltip,
#app-privacy-protection-info-tooltip {
  z-index: 10;
}
