.Admin-sol-subs__chip {
    background-color: rgba(144, 71, 120, 0.38) !important;
  }
  
  .Admin-sol-subs__data-table th {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
  
  .Admin-sol-subs__data-table td {
    text-align: left;
    height: 3.59%;
    width: -25%;
    color: rgba(0, 0, 0, 0.87);
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 48px;
  }
  
  .Admin-sol-subs__card {
    margin-top: 25px;
    margin-left: 73px;
    margin-right: 67px;
    align-self: center;
    min-height: 35px;
    padding-top: 10px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);
    height: 48.8%;
    width: 89.06%;
  }
  
  .Admin-sol-subs__table-title {
    padding-left: 20px;
    height: 34px;
    width: 1140px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Raleway;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  
  .cdk-data-table__toolbar {
    min-height: 34px !important;
  }

  .admin-review__logo {
      margin:auto; 
      cursor: pointer !important;
      width: 85.43%;
      height: 66.68%;
      max-height: 36px;
      max-width: 41px;
      margin-top:50%;
  }

  .admin-review__button {
      margin:10% !important;
      width:100% !important;
    }

  .review__replied {
    font-size: 14px;
    font-family: Raleway;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 11px;
  }

  .review__view-resp {
    font-family: Raleway;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    color: purple;
    margin-bottom: 11px;
    margin-left: 3px;
    cursor: pointer;
  }

  .admin-review__heading {
    margin-top: 29px;
    height: 2.48%;
    width: 45%;
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
  }

  .review__approve {
    margin : 0% 3%;
    border: solid 1px #904778 !important;
  }

  .reviews__chip {
    width : 8.43%;
    min-width : 32px;
  }

  .review__back {
    display : flex;
    padding-left: 35px;
  }

  .review__heading {
    margin-top:16px;
    margin-left: 16px;
    height: 32px;
    width: 100%;
    color: rgba(0,0,0,0.87);
    font-family: Raleway;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
  }

  .review__group {
    margin: 0px !important;
    line-height: 47px !important;
    font-family: Montserrat !important;
    color: #232323 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }


  .padding-less-grid {
    padding: 0px !important;
  }

  .resp_dialog {
    padding: 1.24% 1.72% 0% 2.03%;
  }

.admin-review-actions {
  text-align: right !important;
}
  .review__sol-name {
    color: rgba(0, 0, 0, 0.87);
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.12px;
    margin-top: 10%;
  }

  .review__load-more {
    height: 40px !important;
    width: 100% !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Raleway !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    padding: 0px !important;
    text-align: center !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-radius: 2px !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  .review__read-more {
    color: #904778;
    font-family: Raleway;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
