@import '../../variables.scss';

.sell-solution-banner {
  margin: 25px 70px 0px 70px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
}

.banner-subtitle {
  margin-top: 24px;
}

.registered-sol-subtitle {
  color: $fortellis_purple;
  font-weight: 300;
}

.registered-sol-subtitle div {
  font-weight: 300;
}

.sell-solution-field-items .field-item > .even {
  padding: 0px 0px 90px 0px;
  background-color: $fortellis_light_gray3;
}

.sell-solution-field-items .field-item > .odd {
  padding: 0px 0px 90px 0px;
}

.sell-cards {
  display: flex;
  flex-direction: row;
  margin: 38px 70px 0px 70px;
}

.fortellis-sell-card {
  margin-right: 24px;
}

.close-solutions-panel {
  text-align: right;
  margin-right: 5px;
}

.sell-solution-main-header {
  color: $white;
  font-family: $Montserrat;
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
}

.registered-solutions-grid {
  margin: 18px 0px 65px 0px;
}

.solutions-hide-icon {
  float: right;
}

.banner-title-panel {
  position: absolute;
  left: 69px;
  top: 96px;
  margin-right: 668px;
  max-width: 550px;
  z-index: 1;
}

.banner-panel {
  position: relative;
}

.process-grid {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.banner-img {
  float: right;
  opacity: 0.9;
  width: 100%;
}

@media screen and (max-width: 720px) {
  .banner-img {
    float: left;
    opacity: 0.9;
  }
  .banner-img-panel {
    overflow-x: hidden;
  }
  .banner-title {
    .fortellis-title-1 {
      font-size: 48px;
      line-height: 58px;
    }
  }
}

@media screen and (max-width: 600px) {
  .banner-title {
    .fortellis-title-1 {
      font-size: 40px;
      line-height: 48px;
    }
  }
}

@media screen and (max-width: 520px) {
  .banner-title {
    .fortellis-title-1 {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .banner-title-panel {
    left: 20px;
  }
}

@media screen and (max-width: 436px) {
  .banner-title {
    .fortellis-title-1 {
      font-size: 32px;
      line-height: 38px;
    }
  }
  .banner-title-panel {
    left: 8px;
  }
}

.sell-cards > .action-card:not(:last-child) {
  margin-right: 24px;
}

.process-grid > .summary-info-card:not(:last-child) {
  margin-right: 24px;
}

.solution-registration-options {
  text-align: center;
  margin-top: 72px;
  margin-bottom: 90px;
}

.solution-registration-options .fortellis-subtitle-1 {
  padding: 24px 120px 0px 120px;
}

@media screen and (max-width: 768px) {
  .solution-registration-options .fortellis-subtitle-1 {
    padding: 0;
  }

  .solution-registration-options
    .mdc-button
    .mdc-button--raised
    .mdc-button--primary {
    height: auto !important;
  }

  .sell-solution-field-items .page-padding-common {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.fortellis-solution-registration.partner-solution-registration {
  display: inline-block;
}

.solution-registration-divider {
  position: relative;
  margin: 56px 0px 56px 0px;
}

.divider-line {
  height: 1.09px;
  background-color: rgba(0, 0, 0, 0.12);
  padding: 0px 30px 0px 30px;
}

.divider-text-parent {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%) translatey(-50%);
  background-color: $white;
  padding: 0px 30px 0px 30px;
}

.divider-text {
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.56;
  line-height: 32px;
}

.register-button {
  margin-top: 48px;
  height: 40px;
}

.solutions-grid {
  margin-top: 38px;
  margin-bottom: 38px;
}

@media screen and (max-width: 450px) {
  .solutions-grid {
    margin: 0 16px 32px 16px;
  }

  .even > .cards {
    margin: 16px 32px !important;
  }
}

.solutions-grid .max-width-resp {
  //adding !important is required to override child css that is written using !important
  max-width: none !important;
}

.solutions-grid .cdk-data-table {
  width: 100%;
}

.odd > .cards {
  margin: 72px 0px 0px 0px;
}

.even > .cards {
  margin-top: 38px;
  margin-bottom: 38px;
}

.sell-banner {
  margin-bottom: 48px;
  background-color: $white;
}

@media screen and (max-width: 992px) {
  .sell-cards {
    flex-direction: column;
  }

  .sell-solution-banner {
    flex-direction: column;
  }

  .sell-cards > .action-card:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 24px;
  }

  .process-grid > .summary-info-card:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 24px;
  }

  .process-grid {
    flex-direction: column;
  }
}

.summary-title-sell-page {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 16px;
}

.summary-desc-sell-page {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 57px;
}

.sell-solution-section-header {
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-family: $Montserrat;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
}

.go-to-market-section {
  background-color: #019cdf;
  height: 288px;
}

.prospective-partner-txt {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
  text-align: center;
  padding-top: 70px;
}

.review-txt {
  color: #ffffff;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
}

.view-go-to-market-guide {
  border: 2px solid #ffffff;
  border-radius: 4px;
}

.btn-div {
  text-align: center;
  margin-top: 32px;
}

.btn-txt {
  color: #ffffff;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 36px;
}

.highlighted-text {
  color: $fortellis_purple;
  cursor: pointer;
}

.published-apps-title {
  color: #f70d72;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
  text-align: center;
  padding-top: 38px;
}

.published-apps-subtitle {
  color: #f6f9fa;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
}

.published-apps-panel {
  position: absolute;
  width: 100%;
}

.featured-sol-grid {
  width: 80%;
  text-align: center;
  padding-left: 10%;
}

.featured-sol-name {
  color: rgba(255, 255, 255, 0.87);
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.icon-background-circle {
  border-radius: 50%;
  height: 64px;
  width: 64px;
  background-color: #6d2a71;
  margin: auto;
}

.icon-sell-page {
  color: white;
  padding-top: 19px;
  padding-left: 20px;
}

.published-apps-banner-img {
  float: right;
  width: 100%;
  height: 321px;
}

.no-published-apps {
  width: 100%;
  height: 150px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .published-apps-banner-img {
    float: left;
    height: 621px;
  }
  .go-to-market-section {
    height: 380px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .published-apps-banner-img {
    float: left;
    height: 451px;
  }
}
