@import '../../../../src/variables.scss';

.chips-container-nl {
  & .chip-nl {
    margin: 8px;
  }

  & .chip-nl .mdc-chip__icon--trailing {
    margin: 0 -5px 0 7px;
  }

  & .chip-nl .mdc-chip__text {
    color: $fortellis_light_gray2;
    font-family: $OpenSans;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
  }

  .clear-all-nl {
    margin: 0 8px 0 8px;
    height: 24px;
    width: 57px;
    color: $fortellis_purple;
    font-family: $OpenSans;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    white-space: nowrap;
    cursor: pointer;
  }

  .results-span-nl {
    margin: 0 8px 0 8px;
    color: $fortellis_light_gray2;
    font-family: $OpenSans;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 32px;
  }

  .chip-nl label {
    font-size: 13px;
    font-weight: 500;
    font-family: $OpenSans;
  }
}

.chips-container-nl > .chip-nl > div {
  background-color: #e0e0e0;
  border: none;
}
