@import './variables.scss';

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  background: #fafafa;
}

a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

a:focus,
button:focus {
  outline: 0;
}

/*CDK UIP REACT OVERRIDES*/

.mdc-button--primary.mdc-button--raised:not(:disabled),
.mdc-button--primary.mdc-button--unelevated:not(:disabled),
.mdc-dialog__action.mdc-button--raised:not(:disabled),
.mdc-dialog__action.mdc-button--unelevated:not(:disabled) {
  background-color: $fortellis_purple;
}
/*END OF CDK UIP REACT OVERRIDES*/

.fortellis-header {
  .cdkicon-arrow_drop_down {
    margin-left: 8px;
    height: 16px;
    width: 16px;
    color: #ffffff;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
  }
}

.fortellis-link {
  color: $fortellis_purple;
  font-family: $Raleway;
  cursor: pointer;
}

.fortellis-link:hover {
  color: $fortellis_purple;
  font-family: $Raleway;
}

.fortellis-link:focus {
  color: $fortellis_purple;
  font-family: $Raleway;
}

.mdc-menu--showTemporary {
  display: inline-block !important;
  transform: scale(1) !important;
  visibility: hidden !important;
}
.header__top-bar {
  padding-left: 32px;
}

.mdc-menu--open {
  z-index: 1005 !important;
}

.appjs-page-container {
  background-color: $white;
  min-height: calc(100vh - 80px - 252px); //100vh-header-footer
}

.appjs-page-container-anon {
  background-color: $white;
  min-height: calc(
    100vh - 80px - 252px - 100px
  ); //100vh-header-footer-footer_cta
}

.cdk-circular-progress--circle {
  border-color: $fortellis_purple;
}

.row {
  display: flex;
}

.drawerContent {
  padding-bottom: 4.07%;
}

.tabCell {
  border-bottom: 1px solid $border;
}

.tab-component {
  height: auto;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 22px;

  #tab-1:after,
  #tab-2:after,
  #tab-3:after,
  #tab-4:after,
  #tab-5:after {
    background-color: transparent;
  }

  #tab-1,
  #tab-2,
  #tab-3,
  #tab-4,
  #tab-5 {
    background-color: white;

    &[aria-selected='true'] {
      border-bottom: 1px solid purple;

      span {
        color: purple;
      }
    }
  }

  [role='tablist'] {
    border-bottom: 1px solid;
  }

  #panel-1,
  #panel-2,
  #panel-3,
  #panel-4,
  #panel-5,
  #panel-6 {
    padding: 65px 10px 10px 10px;
  }
}

.solution-details-column-right {
  .tabCell {
    margin-bottom: 24px !important;
    padding-top: 16px;
  }
}

@media screen and (max-width: 991px) {
  .solution-details-column-right {
    .tabCell {
      padding-top: 8px;
    }
  }

  .tabCell {
    .mdc-tab-bar {
      height: auto;
      display: grid;

      .mdc-tab {
        line-height: 48px;
        text-align: left;
      }
    }
  }
}

.tabCell .mdc-tab {
  color: $fortellis_text_color2;
  font-family: $Raleway;
  font-size: 13px;
  font-weight: 600;
  // line-height: 24px;
  min-width: unset;
}

.formContent {
  padding: 0px !important;
}

.mdc-tab__indicator {
  background-color: $fortellis_purple !important;
}

.formFields {
  padding-top: 1.04%;
  height: 4.02%;
}

.form-text-area {
  margin-top: 0px;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.solution-access-type-container {
  margin-top: 16px;
}

.sub-input-chipset {
  margin-left: 40px;
  width: 60%;
  max-width: 499px;
  min-width: 280px;
  border: 1px solid $fortellis_light_gray4;
  border-radius: 4px;

  .mdc-text-field--box-focus {
    width: calc(100% - 32px);
    margin-left: 16px !important;
    margin-top: 8px !important;
  }
}

.sub-notification-input-chipset {
  width: 60%;
  max-width: 499px;
  min-width: 280px;
  border: 1px solid $fortellis_light_gray4;
  border-radius: 4px;

  .mdc-text-field--box-focus {
    width: calc(100% - 32px);
    margin-left: 16px !important;
    margin-top: 8px !important;
  }
}
.input-chipset-container {
  margin-bottom: 24px;
}

.form-input-chipset {
  margin-left: 16px;
}

.form-action-buttons {
  margin: 56px 0 !important;
}

.edition-description-area {
  margin-bottom: 24px !important;
}

.mdc-text-field--read-only .mdc-text-field__input {
  border-bottom: 1px solid #b00020 !important;
}

.mdc-text-field--invalid .form-field-label {
  color: #b00020 !important;
}

.form-box
  .mdc-text-field--focused
  .mdc-text-field__input:required
  + .mdc-text-field__label::after {
  color: $fortellis_dark_gray !important;
}

.mdc-floating-label {
  font-family: $Raleway !important;
  font-size: 12px;
  font-weight: 500;
  color: $fortellis_light_gray;
  pointer-events: none;
}

.form-field-label {
  font-family: $Raleway !important;
  font-size: 12px;
  font-weight: 500;
  color: $fortellis_light_gray;
}

.form-field-label-floatup {
  height: 14px;
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 14px;
  display: inline-block;
  width: 90%;
}

.form-field-label-toggle {
  height: 14px;
  width: 352.1px;
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 14px;
}

.provisioningcaption {
  margin-top: -10px;
  max-width: 499px;
  min-width: 280px;
}

.appsubscriptionscaption {
  font-size: 14px;
  line-height: 24px;
  max-width: 499px;
  min-width: 280px;
}

.appsubscriptionstitle {
  margin-top: 3px;
  font-size: 16px;
  line-height: 24px;
  max-width: 499px;
  min-width: 280px;
}

.max-width-unset {
  max-width: unset;
}

.provisioningtitle {
  margin-top: 10px;
  margin-bottom: 10px;
}

.provisionstaticform {
  padding: 8px 24px !important;
  border-radius: 2px;
  background-color: $fortellis_light_purple;
}

.subnotificationform {
  margin-top: 22px;
}

.subnotificationcaption {
  margin-top: -6px;
}

.subnotificationtitle {
  height: 32px;
  width: 845px;
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
}
.help-label-icon:hover {
  color: $fortellis_purple !important;
}

.mdc-text-field__input.mdc-text-field__label.form-field-label {
  cursor: pointer;
  font-family: $Raleway !important;
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__label .form-field-label {
  top: 10px;
}

.mdc-text-field--upgraded
  .mdc-floating-label
  .form-field-label
  .help-label-icon {
  cursor: pointer;
}

.mdc-select:not(.mdc-select--disabled) .mdc-floating-label .help-label-icon {
  cursor: pointer;
}

.mdc-select:not(.mdc-select--disabled) .mdc-floating-label .help-label-icon,
.mdc-text-field--upgraded
  .mdc-floating-label
  .form-field-label
  .help-label-icon {
  pointer-events: all;
}

.mdc-select:not(.mdc-select--disabled)
  .mdc-select__native-control:focus
  ~ .mdc-floating-label {
  color: $fortellis_purple !important;
}

.cdk-data-table {
  color: $fortellis_light_gray2 !important;
  font-family: $Raleway !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
}

.default-fortellis-dialog .mdc-dialog--open .mdc-dialog__surface {
  width: 560px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: $box_shadow;

  .mdc-dialog__header__title {
    color: $fortellis_light_gray2;
    font-family: $Montserrat;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
  .mdc-dialog__body {
    color: $fortellis_light_gray;
    font-family: $Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

.api-error-dialog {
  .mdc-dialog__body {
    padding-bottom: 0;
  }
}

.loading-spinner-fortellis-dialog .mdc-dialog--open .mdc-dialog__surface {
  width: min-content;

  .cdk-stepper__divider {
    width: 100px;
  }
  .mdc-dialog__header {
    text-align: center;
  }
  .loading-stepper-title {
    margin-bottom: 16px;
  }
}

.help-label-icon {
  font-size: 14px !important;
  line-height: 14px !important;
  padding-left: 4px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.2) !important;
  vertical-align: text-top;
}

.help-label-icon:hover {
  color: $fortellis_purple;
}

.form-field-with-tooltip {
  position: relative;
  width: 100%;
  text-align: left;
}

.form-field-with-tooltip-caret {
  position: relative;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-field-with-spinner {
  flex-grow: 1;
}

.form-field-with-toggle {
  position: relative;
  width: 100%;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px 16px;
}

.form-field-with-toggle-checked {
  background-color: $fortellis_light_purple;
}

.form-toggle-checkbox-wrapper {
  width: 8%;
  display: inline-block;
  text-align: right;
}

.form-toggle-text-wrapper {
  width: 92%;
  display: inline-block;
}

.form-field-with-paddingleft {
  position: relative;
  width: 100%;
  text-align: left;
  padding-left: 40px;
}

.onoff-switch-item-helper-text {
  padding-left: 40px;
}

.check-box-div {
  width: 80%;
}

.form-field-title {
  height: 24px !important;
  color: $fortellis_black !important;
  font-family: $Raleway !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.form-field-tooltip {
  border-radius: 2px !important;
  background-color: rgba(97, 97, 97, 0.9) !important;
  position: absolute !important;
  top: unset !important;
  left: 0px !important;
  max-width: 500px !important;
  min-height: 24px !important;
  padding: 5px 10px !important;
  z-index: 100 !important;

  font-family: $Raleway !important;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 14px;
  text-align: left !important;
}

.form-field-check-box-group {
  border-radius: 2px !important;
  background-color: rgba(97, 97, 97, 0.9) !important;

  max-width: 500px !important;
  min-height: 24px !important;
  padding: 5px 10px !important;
  z-index: 100 !important;

  font-family: $Raleway !important;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 14px;
  text-align: left !important;
}

.button-with-tooltip {
  position: relative;
  width: 80%;
  text-align: right;
}

.button-span {
  cursor: pointer;
}

.static-image {
  padding: 0 !important;
}

.static-form-image {
  width: 200px;
  height: 200px;
  border: 1px dashed $fortellis_light_gray;
}

.multiple-add-form-div {
  border-radius: 2px;
  background-color: #fafafa;
  //padding: 0.67% 0.67% 0.67% 1.88%;
  margin-bottom: 1.88%;
  display: flex;
  width: 100%;
}

.form-highlight-text-area .mdc-floating-label {
  background-color: #fafafa !important;
}

.multiple-add-form-delete {
  text-align: left;
  width: 5%;
  cursor: pointer;
  padding: 16px 16px 0 16px;
}

.multiple-add-form-fields {
  width: 95%;
}

.add-multiple-item-form-link {
  color: $fortellis_purple;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
  padding-left: 16px;
  cursor: pointer;
}

.add-multiple-item-form-icon {
  height: 36px;
  width: 14px;
  color: $fortellis_purple;
  font-size: 16px !important;
  line-height: 36px !important;
  padding-right: 24px !important;
  vertical-align: top;
}

.dependent-div {
  border-radius: 2px;
  background-color: #fafafa;
}

.dependentNote {
  color: $fortellis_dark_gray;
  height: 2.23%;
  font-family: $Raleway;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin: 0px;
}

.dependent-field-title {
  color: $fortellis_dark_gray !important;
  font-family: Raleway !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin-top: 0px;
}

.dependent-field-div {
  border-radius: 2px;
  background-color: $white;
  padding: 1.67% 30px 1.92%;
  margin-top: 1.92%;
}

.free-gross-period-field {
  width: 260px !important;
}

.select-multiple {
  vertical-align: middle;
  padding-bottom: 0px;
}

option.is-focused {
  background-color: $fortellis_purple !important;
}

.mdc-line-ripple--active {
  background-color: $fortellis_purple !important;
}

.mdc-select__selected-text {
  font-weight: 500;
  font-family: Raleway;
}

.mdc-select__surface {
  padding-left: 0px;
}

.mdc-layout-grid {
  color: $color_1;
  font-family: $Montserrat;
}

.custom-font--grid {
  font-size: 19px;
  font-weight: bold;
  line-height: 29px;
}

.mdc-drawer--permanent {
  width: 100% !important;
  border: 0 !important;
  .mdc-drawer__header {
    height: 25px;
    width: 100%;
    display: flex;
  }

  .mdc-drawer__header-content {
    color: $black !important;
    font-weight: bold !important;
    height: 29px;
    width: 90%;
    color: $color_1;
    text-overflow: ellipsis !important;
    word-wrap: break-word !important;
    font-family: $Raleway;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-transform: capitalize;
    padding: 0px 0px 0px 10px !important;
  }
}

.side-nav-item {
  cursor: pointer;
  width: 100% !important;
  height: 100% !important;
  color: $fortellis_dark_gray !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  padding: 0px 0px 0px 10px !important;
  text-overflow: ellipsis !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
}

.form-list-item {
  padding: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  cursor: default;
}

// .form-list-item .mdc-list-item__text {
//   cursor: default;
// }

.form-list-secondary-text {
  line-height: 30px !important;
}

.mdc-typography--title {
  height: 47px !important;
  color: $fortellis_black !important;
  font-family: $Raleway !important;
  font-size: 28px !important;
  font-weight: bold !important;
  line-height: 47px !important;
  margin: 0px !important;
}

.mdc-typography--display1 {
  height: 100% !important;
  width: 100% !important;
  color: $fortellis_black !important;
  font-family: $Raleway !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  margin: 0px !important;
}

// .mdc-typography--body2 {
//   color: $fortellis_black !important;
//   font-family: $Raleway !important;
//   font-weight: 400 !important;
//   font-size: 16px !important;
//   width: 1036px !important;
// }

.mdc-typography--subheading2 {
  color: $fortellis_black;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.static-fields {
  min-height: 40px !important;
  width: 100% !important;
}

.static-value-with-pre-post-text {
  height: 24px !important;
  width: 0px !important;
  color: $fortellis_light_gray2 !important;
  font-family: $Raleway !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  letter-spacing: 0.1px !important;
  line-height: 24px !important;
}

.static-value-with-pre-post-checkbox {
  height: 24px !important;
  width: 100px !important;
  color: $fortellis_light_gray2 !important;
  font-family: $Raleway !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  letter-spacing: 0.1px !important;
  line-height: 24px !important;
}

.review-fields-div {
  .mdc-typography--caption {
    font-size: 14px;
    line-height: 2.5;
  }
}

.mdc-text-field__bottom-line--active {
  border: 1px solid $fortellis_purple !important;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $fortellis_purple !important;
}

.mdc-text-field__label--float-above {
  font-family: $Raleway !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: $fortellis_light_gray !important;
  line-height: 16px !important;
}

.mdc-text-field--box-focus:not(mdc-text-field--box):not(.mdc-text-field--outlined).mdc-text-field--focused {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.mdc-select__label--float-above {
  font-family: $Raleway !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: $fortellis_light_gray !important;
}

.mdc-select__label {
  left: 0 !important;
  font-size: 16px !important;
}

.mdc-select__bottom-line--active {
  border: 1px solid $fortellis_purple !important;
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__bottom-line::after {
  background-color: $fortellis_purple !important;
}

.mdc-form-field > label {
  padding-top: 5px;
}

.mdc-checkbox {
  font-size: 20px !important;
}

.mdc-radio {
  font-size: 20px !important;
}

.mdc-form-field {
  width: 100%;
  padding-bottom: 8px;
}

.mdc-floating-label {
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.formFields {
  .mdc-text-field__input {
    position: relative !important;
    font-family: $Raleway !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: $fortellis_light_gray2 !important;
    line-height: 28px !important;
  }
}
label {
  color: $fortellis_black;
  font-size: 16px;
}

.formFields {
  .mdc-text-field__input:required + .mdc-floating-label:after {
    font-size: 24px;
    display: inline-block;
    height: 8px;
    vertical-align: text-top;
  }

  .mdc-text-field {
    .mdc-text-field__input {
      padding-top: 26px;
    }
    .mdc-floating-label--float-above {
      -webkit-transform: translateY(-100%) scale(1);
      transform: translateY(-100%) scale(1);
    }
  }

  .mdc-text-field--textarea {
    .mdc-text-field__input {
      border: none;
      padding-top: 26px;
    }
    .mdc-text-field__input:required + .mdc-floating-label:after {
      transform: translateY(-6px);
    }
    .mdc-floating-label {
      margin-top: -4px;
      -webkit-transform: translateY(-60%) scale(1);
      transform: translateY(-60%) scale(1);
    }
    .mdc-floating-label--float-above {
      margin-top: -4px;
      -webkit-transform: translateY(-60%) scale(1);
      transform: translateY(-60%) scale(1);
    }
  }

  .mdc-select {
    .mdc-floating-label,
    .mdc-floating-label--float-above {
      margin-top: -4px;
      -webkit-transform: translateY(-60%) scale(1);
      transform: translateY(-60%) scale(1);
    }
  }
}

.mdc-text-field__label {
  height: 24px !important;
  width: 100% !important;
  color: $fortellis_light_gray !important;
  font-family: $Raleway !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.mdc-text-field--textarea {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
}

.review-form,
.contact-publisher-dialog {
  .mdc-text-field--textarea {
    .mdc-text-field__input {
      padding-top: 28px;
      margin-top: 10px;
    }
  }
}

.contact-publisher-textarea {
  textarea::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  textarea::-webkit-scrollbar:vertical {
    width: 11px;
  }

  textarea::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.mdc-text-field--textarea .mdc-text-field__input {
  border: none;
  padding-top: 28px;
}

.form-text-area.mdc-text-field--focused {
  color: $fortellis_light_gray !important;
  border-bottom: 2px solid $fortellis_purple !important;
}

.mdc-text-field--focused .mdc-floating-label {
  color: $fortellis_light_gray !important;
}

.mdc-text-field .mdc-line-ripple {
  background-color: $fortellis_purple !important;
}

.formFields {
  .mdc-text-field--textarea .mdc-text-field__input {
    border: 0px;
    min-height: 65px;
    max-height: 140px;
  }
  textarea {
    resize: vertical;
  }
  textarea::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  textarea::-webkit-scrollbar:vertical {
    width: 11px;
  }

  textarea::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  textarea::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.form-text-area .mdc-floating-label {
  color: $fortellis_light_gray;
  font-family: $Raleway !important;
  font-weight: 500 !important;
}

.layoutCellList {
  height: 100% !important;
}

.layoutcellRadio {
  padding-left: 20px !important;
  margin-top: -10px !important;
}

.text-field-post-text {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  font-family: $Raleway !important;
  vertical-align: bottom;
  font-weight: 600;
  font-size: 16px;
  color: $fortellis_black !important;
}

.text-field-pre-text {
  display: inline-block;
  margin-right: 15px;
  vertical-align: bottom;
  font-family: $Raleway !important;
  font-weight: 600;
  font-size: 16px;
  color: $fortellis_black !important;
}

.static-field-post-text {
  display: inline-block;
  margin-left: 5px;
  margin-right: 20px;
  margin-bottom: 1px;
  position: relative;
  font-weight: 500;
  top: 33px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  text-transform: capitalize;
}

.static-field-pre-text {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 1px;
  position: relative;
  font-weight: 500;
  top: 33px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  text-transform: capitalize;
}

.static-select-field {
  display: inline-block;
  width: 0px;
}

.text-field-with-pre-post-text {
  display: inline-block;
  vertical-align: top;
  padding-top: 5px;
}

.select-field-with-pre-post-text {
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 10px;
}

.select-plan-field-dependent {
  top: unset !important;
}

.lastUpdatedNotes {
  font-size: 12px;
  color: $fortellis_dark_gray;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 20px;
  font-family: $Raleway;
}

.lastUpdatedDiv {
  margin-left: 5.02%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 33%;
}

.statusDiv {
  margin-left: 6.02%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 60%;
}

// .mdc-button--primary:not(:disabled) {
//   background-color: $fortellis_purple !important;
//   border: solid 1px $fortellis_purple !important;
//   color: $white !important;
// }

// .mdc-button--outlined {
//   border-color: $fortellis_purple !important;
//   border-width: 1px !important;
// }

// .mdc-button {
//   font-family: $Raleway !important;
//   font-size: 14px !important;
//   font-weight: 600 !important;
//   color: $fortellis_purple !important;
//   line-height: 24px !important;
//   text-align: center !important;
//   text-transform: uppercase !important;
//   border-radius: 2px !important;
//   cursor: pointer !important;
//   letter-spacing: 1.25px !important;

//   /* background-color: $fortellis_purple !important; In suggestions from UX but not according to UX mockup*/
// }

// .mdc-button--stroked {
//   border: 0px !important;
// }

.button-right {
  float: right;
  margin-left: 0.63%;
  pointer-events: auto !important;
}

.view-document {
  margin-top: 15px;
}

.sol-submission__action-spinner {
  margin-right: 36px;
}

.submission-action-button-margin {
  margin-left: 8px;
}

.float-right {
  display: inline-block;
  float: right;
}

.float-left {
  display: inline-block;
  float: left;
}

.clear-both {
  clear: both;
}

.full-width {
  width: 100%;
}

.capitalize-text {
  text-transform: capitalize;
}

a.breadcrumbs__item {
  color: $fortellis_purple !important;
  &:visited {
    color: $fortellis_purple !important;
  }
}

a:hover {
  text-decoration: none !important;
}

.input-file {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}

.mdc-tab-bar {
  margin-left: 0px !important;
}

.mdc-tab--active {
  color: $fortellis_purple !important;
}

.formSelectfield {
  min-width: 220px !important;
  width: 100% !important;
}

.mdc-ripple-upgraded--background-focused {
  color: $fortellis_purple;
}

.checkbox-group-notes {
  color: $fortellis_dark_gray;
  height: 2.23%;
  font-family: $Raleway;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.notes {
  color: $fortellis_dark_gray;
  height: 2.23%;
  font-family: $Raleway;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin: 0;
}

.featured-image {
  width: 420px !important;
  height: 236px !important;
  border: 1px dashed $fortellis_light_gray;
  text-align: center;
  padding: 30px 0;
  margin-top: 1.61%;
}

.gallery-image {
  width: 242px !important;
  height: 136px !important;
  border: 1px dashed $fortellis_light_gray;
  text-align: center;
  padding: 30px 0;
  margin: 1.61% 0;
  cursor: pointer;
}

.drop-box-text-note {
  font-size: 12px !important;
}

.drop-zone {
  width: 185px !important;
  height: auto !important;
  border: 1px dashed $fortellis_light_gray;
  text-align: center;
  padding: 30px 0;
  margin-top: 1.61%;
  cursor: pointer;
}

.drop-zone-content {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  width: 100%;
  padding: 16px 0;
}

.drop-zone-content-with-image {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  height: 174px;
  width: 100%;
  padding: 0;
}

button:focus {
  outline: 0 !important;
}

.menu-link {
  color: $fortellis_purple !important;
  text-decoration: none;
}

.logout-link {
  color: $black !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 40px !important;
  padding: 0 20px !important;
}

.menu-item-link {
  color: $black !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 40px !important;
  padding: 0 20px !important;
  margin: 0px !important;
}

.mdc-menu .mdc-list-item {
  color: $black !important;
  font-family: $Raleway !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 0 20px !important;
  white-space: unset !important;
}

.menu-sub-item-link {
  color: $black !important;
  font-family: $Raleway !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  padding: 0 20px 0 30px !important;
  line-height: 40px !important;
}

.mySolutionButton {
  margin-top: 10px !important;
}

.menuIcon {
  vertical-align: text-top !important;
  font-size: 20px !important;
  padding-right: 10px;
  color: $fortellis_purple;
}

.mdc-tab-bar:not(.mdc-tab-bar-upgraded) .mdc-tab::after,
.mdc-tab-bar__indicator {
  background-color: $fortellis_purple !important;
}

.mdc-text-field-helper-text--validation-msg {
  font-weight: normal !important;
  line-height: 0px !important;
  margin-top: 5px !important;
}

.delete-icon {
  vertical-align: middle;
  float: right;
  /*
  margin-right: 24px;
  */
  cursor: pointer;
  z-index: 1;
  position: relative;
}
.last-updated {
  margin: 0px;
  height: 10px;
  float: right;
  font-weight: 300 !important;
  font-size: 14px !important;
}

.mdc-checkbox {
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.mdc-checkbox__background {
  width: 18px !important;
  height: 18px !important;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
}

.mdc-checkbox__radio {
  width: 18px !important;
  height: 18px !important;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
}

.checkbox_cell {
  margin-top: 2.38%;
  height: 0px;
  margin-bottom: 2.38%;
}

.selectBox_cell {
  margin-bottom: 0.73%;
}

.check-box-group-title {
  font-weight: 600;
  margin-top: 0px;
}

.text-field-title {
  font-weight: 600 !important;
  margin-top: 0px !important;
  height: auto !important;
  line-height: 25px !important;
  font-family: $Montserrat !important;
}

.dynamic-label-title {
  color: white !important;
  font-family: $Raleway !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding-top: 12px !important;
  padding-left: 24px !important;
  padding-right: 16px !important;
  margin: 0px !important;
}

.dynamic-label-grid {
  height: 45px !important;
  margin: 16px 0 0;
  border-radius: 2px !important;
  background-color: $fortellis_purple !important;
}

.form-title {
  height: 40px !important;
}

.mySolutionButton {
  margin-top: 10px !important;
}

.cdk-table td {
  padding: 0px;
  vertical-align: middle;
}

.table-api-label {
  height: 16px;
  width: 283px !important;
  color: $fortellis_black;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  padding-left: 23.5px !important;
  padding-right: 23.5px !important;
}

.select-prov__select-cell {
  width: 283px;
}

.select-prov__select-provider {
  height: 63px;
  width: 230px;
}

.button-toggle-connection {
  height: 32px !important;
  width: 150px !important;
  border-radius: 16px !important;
  background-color: rgba(144, 71, 120, 0.38) !important;
  text-transform: inherit !important;
}

.toggle-text-connect {
  height: 30px;
  width: 52px;
  color: $fortellis_black !important;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.toggle-text-disconnect {
  height: 30px;
  width: 51px;
  color: $fortellis_black !important;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.select-prov__table {
  tr {
    height: 62px;
    width: 850px;
  }
}

.select__loc {
  font-size: 15px;
  font-weight: normal;
}

.sol-details__trail {
  padding-left: 20px !important;
  color: $fortellis_black;
  font-family: $OpenSans;
  font-size: 14px;
  font-weight: bold;
  height: 18px;
  width: 236px;
  line-height: 18px;
}

.sol-details__logo {
  width: 100%;
}

.solution-left-details {
  margin-bottom: 48px;
}

.solution-left-details p {
  line-height: 0px !important;
  margin: 0px !important;
}

.solution-left-panel-header {
  margin-bottom: 8px !important;
  margin-top: 25px;
}

.solution-left-details-section {
  margin: 0 0 16px 0 !important;
}

.solution-left-details-section > .sol-details__support {
  word-break: break-word;
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(21, 27, 37, 0.7);
}

.solution-left-details-item {
  line-height: 16px !important;
}

.sol-details__email {
  color: $fortellis_purple;
  font-family: $OpenSans !important;
  font-weight: Bold !important;
  font-size: 13px !important;
  padding: 0px !important;
  margin: 0px !important;
}

#{'.sol-details__email:hover,.sol-details__email:active,.sol-details__email:visited,.sol-details__email:focus'} {
  color: $fortellis_purple !important;
}

.solution-left-details-item .sol-details__email .external-link-icon {
  font-size: 13px;
  font-weight: Bold;
}

.solution-left-details-section .sol-details__subheading-copyright {
  padding-top: 5px;
  margin: 0px;
  color: rgba(21, 27, 37, 0.7);
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.sol-details__copyright {
  font-family: $OpenSans;
  font-size: 13px !important;
  margin: 0px !important;
  font-weight: bold;
}

.external-link-icon {
  font-size: 16px !important;
  vertical-align: text-bottom !important;
}

.features-expansion
  .cdk-expansion-panel__header
  .header-title.feature-list-title {
  padding: 0px !important;
}

.feature-content-body {
  padding: 0px !important;
}

.sol-details__support {
  color: rgba(21, 27, 37, 0.7);
  font-family: $sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  // margin: 0px !important;
}

.solution-left-panel-header .appDetails-left-side-section-divider {
  margin-bottom: 16px;
  margin-top: -5px;
}

.sol-details__image-box {
  width: 100%;
  border-radius: 8px;
  background-color: $white;
  box-shadow: $box_shadow2;
}

.sol-details__image-box img {
  border-radius: 8px;
}

.sol-details__sol-name {
  font-size: 36px;
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 10px;
  max-width: 750px;
  word-break: break-word;
}

.gallery-carousel {
  margin-top: 16px !important;
}

.title-header {
  color: $fortellis_light_gray2 !important;
  font-family: $Montserrat !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.overview-links {
  font-size: 14px !important;
  color: $fortellis_purple !important;
  font-family: $OpenSans !important;
  font-weight: 500 !important;
}

.overview-links a {
  color: $fortellis_purple !important;
}

.solution-details-developed-by {
  color: $fortellis_light_gray2 !important;
  font-family: $OpenSans !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 16px;
}

.solution-details-developed-by a {
  color: $fortellis_purple !important;
}

.sol-details__sol-type {
  height: 24px;
  color: $fortellis_black;
  font-family: Raleway;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 0px !important;
}

.sol-details__buy-button {
  height: 1.92%;
  width: 86.94%;
  border-radius: 2px;
  background-color: $fortellis_purple !important;
  color: $white !important;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin: 0px !important;
}

.sol-details__outer-grid {
  padding: 0px !important;
  width: 97% !important;
}

.admin-solution-review-content {
  padding: 0px !important;
}

.admin-subs__chip {
  background-color: rgba(144, 71, 120, 0.38) !important;
}

.admin-subs__data-table {
  th {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
  td {
    text-align: left;
    height: 3.59%;
    width: -25%;
    color: $fortellis_light_gray2;
    font-family: Raleway;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 48px;
  }
}

.admin-subs__card {
  margin-top: 25px;
  margin-left: 73px;
  margin-right: 67px;
  align-self: center;
  min-height: 35px;
  padding-top: 10px;
  border-radius: 2px;
  background-color: $white;
  box-shadow: $box_shadow2;
  height: 48.8%;
  width: 89.06%;
}

.admin-subs__table-title {
  padding-left: 20px;
  height: 34px;
  width: 1140px;
  color: $fortellis_light_gray2;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.cdk-data-table__toolbar {
  min-height: 34px !important;
}

.conn__apis-header-table {
  box-sizing: border-box;
  height: 20px;
  width: 900px;
  border-radius: 2px 2px 0px 0px;
}

.conn__api-name-header {
  height: 62px;
  width: 850px;
  color: $fortellis_dark_gray;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 90px !important;
}

.conn__api-status-header {
  height: 24px;
  width: 200px !important;
  color: $fortellis_dark_gray;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}

.conn__api-provider-header {
  height: 24px;
  width: 220px !important;
  color: $fortellis_dark_gray;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}

.conn__enable-solution {
  height: 40px;
  width: 217px;
  border-radius: 2px;
  background-color: $fortellis_purple;
  box-shadow: $box_shadow3;
}

.conn__disable-solution {
  height: 40px;
  width: 217px;
  box-shadow: $box_shadow3;
}

.conn__apis-list-table {
  box-sizing: border-box;
  height: 150px;
  width: 95%;
  border: 1px solid $border;
  border-radius: 2px;
  tr {
    height: 2.08%;
  }
  th {
    width: 95%;
    height: 62px;
    font-weight: bold;
  }
}

.conn__apis-header-table th {
  width: 283px !important;
  vertical-align: middle;
}

.main-table-element .cdk-table {
  th,
  td {
    border-top: 0px solid $border;
  }
}

.conn__connect-to-api {
  height: 32px;
  width: 197px !important;
  color: $fortellis_black;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.conn__layout-grid {
  padding: 0px !important;
}

.admin-subs__chip {
  height: 32px;
  width: 92px;
  border-radius: 16px;
  background-color: $fortellis_purple;
  margin-right: 5px;
  margin-left: 5px;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.drop-box-text-with-image {
  display: flex;
  top: -16px;
  position: relative;
  padding: 0px 16px;
}

.drop-box-text-with-image-header {
  text-align: left;
  width: 90%;
}

.form-field-drop-box {
  float: left;
}

.drop-box-text-with-image-delete {
  width: 20%;
  top: 25px;
  position: relative;
  z-index: 1;
  right: 12px;
}

.featured-drop-box-image {
  width: 420px;
  height: 247px;
}

.gallery-drop-box-image {
  width: 242px;
  height: 147px;
}

.drop-box-text-with-gallery-image-delete {
  width: 28%;
  top: 16px;
  position: relative;
  right: 12px;
}

.drop-box-text-with-features-gallery-image-delete {
  width: 27%;
  top: 25px;
  position: relative;
  z-index: 1;
  right: 12px;
}

.drop-box-text-with-featured-image-delete {
  width: 44%;
  top: 20px;
  position: relative;
  z-index: 1;
  right: 12px;
}

.drop-box-image {
  width: 185px;
  height: 185px;
}

.form-box {
  min-height: 500px;
}

.commentBox {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 2px;
  background-color: #fafafa;
  padding-left: 1.88%;
  padding-right: 1.88%;
  padding-bottom: 1.56%;
}

.multiple-add-select-field {
  margin-bottom: 10px;
}

.onoff-switch-item {
  width: 80%;
  padding-top: 8px !important;
}

.onoff-switch-item-switch {
  margin-right: 9px;
}

.onoff-switch-item-label {
  color: #686868;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.check-box-group-item {
  padding-bottom: 10px;
}

.check-box-item-checkBox {
  margin-bottom: 8px;
}

.radio-item-radio {
  margin-bottom: -25px;
}
.radio-item-noLabel {
  margin-bottom: -17px;
}
.radio-item-label {
  height: 24px;
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.check-box-item-label {
  color: $fortellis_black;
  font-family: $Raleway;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.check-box-group-item-label {
  color: $fortellis_black;
  font-family: $Raleway;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 24px;
}

.suggestion-table {
  width: 64%;
  position: absolute;
  z-index: 1;
  margin-top: -15px;
}

.suggestion-row {
  width: 100%;
}

.suggestion-cell {
  width: 100%;
  text-align: left !important;
}

.save-loading-spinner {
  z-index: 100;
  padding: 100px;
  margin: 0px;
  background: rgba(255, 255, 255, 1);
}

.save-loading-spinner-overlay {
  z-index: 100;
  padding: 20%;
  width: 100% !important;
  height: 100%;
  margin: 0px !important;
  position: fixed;
  background: rgba(255, 255, 255, 0.54);
  top: 0;
}

// .app-container {
//   top:0;
//   position: absolute;
//   width: 100%;
//   background-image: url(#{$static_image_path}/marketplace.png);
//   /*background-size: 100%;*/
//   /*  background-size: cover;*/
//   background-size: 100vw 120vh;
//   min-height: 100vh;
//   /*  background-position: top;
//     background-position-x: center;*/
// }

.my-header {
  min-height: 99px;
  width: 100%;
  color: $white !important;
}

.navbar {
  box-shadow: none !important;
}

.navbar-inverse {
  background-color: $border2 !important;
}

.navbar-static-top {
  z-index: 1000 !important;
}

.spinner-container-width {
  width: 90%;
}

.spinner {
  display: flex !important;
}
/*-------------------------------Hero----------------------------------*/
.hero {
  /* margin-top: 99px;*/
  /* display: flex;*/
  min-height: 446px;
  padding-top: 80px;
  width: 100%;
  background: url('./components/common/landingpage-bg.jpg') center no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .hero {
    min-height: 292px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 560px) {
  .hero {
    min-height: 130px;
  }
  .solution-details-column-right {
    .sol-details__sol-name {
      word-break: break-word;
    }
  }
}

.hero-grid {
  height: 100%;
  width: calc(100% - 190px);
}

@media screen and (max-width: 1024px) {
  .hero-grid {
    width: calc(100% - 152px);
  }
}

@media screen and (max-width: 768px) {
  .hero-grid {
    width: calc(100% - 114px);
  }
}

@media screen and (max-width: 560px) {
  .hero-grid {
    width: 94%;
  }
}

.hero-title {
  color: $white;
  font-family: Montserrat;
  font-size: 90px;
  font-weight: bold;
  line-height: 110px;
  margin-top: 24px;
  letter-spacing: 5.9px;
}

@media screen and (max-width: 940px) {
  .hero-title {
    font-size: 72px;
    font-weight: bold;
    line-height: 86px;
  }
}

@media (max-width: 750px) and (min-width: 560px) {
  .hero-title {
    font-size: 54px;
    font-weight: bold;
    line-height: 64px;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 560px) {
  .hero-title {
    font-size: 32px;
    font-weight: bold;
    line-height: 38px;
    letter-spacing: 2px;
  }
}

.hero-subtitle {
  color: $white;
  font-family: Raleway;
  font-size: 36px;
  font-weight: 300;
  line-height: 42px;
}

@media screen and (max-width: 940px) {
  .hero-subtitle {
    font-size: 28px;
    font-weight: 300;
    line-height: 33px;
  }
}

@media (max-width: 750px) and (min-width: 560px) {
  .hero-subtitle {
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
  }
}

@media screen and (max-width: 560px) {
  .hero-subtitle {
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
  }
}

.search-box-div {
  height: 40px;
  max-width: 43%;
  min-width: 330px;
  border: 1px solid $white;
  border-radius: 50px;
  margin-top: 64px;
  margin-bottom: 36px;
}

@media screen and (max-width: 560px) {
  .search-box-div {
    min-width: 220px;
    margin-top: 32px;
    margin-bottom: 18px;
    max-width: unset;
  }
}

.hero-search-input {
  height: 26px;
  width: calc(100% - 110px);
  min-width: 100px;
  color: $white !important;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;

  margin-left: 25px;
  margin-top: 8px;
  margin-bottom: 6px;

  background: transparent;
  border: none;
}

.hero-search-input:focus {
  outline: none;
  outline-offset: initial;
}

.hero-search-input::placeholder {
  color: $white;
}

.search-icon-button {
  height: 34px !important;
  width: 34px !important;
  border: none;
  color: $white !important;
  background-color: $fortellis_purple !important;
  float: right;
  margin: 2px 3px 3px;
  cursor: pointer;
  font-weight: 900;
}

.reset-icon-button {
  height: 34px !important;
  width: 34px !important;
  border: none;
  color: $white !important;
  float: right;
  margin: 2px 3px 3px;
  cursor: pointer;
}

.hero-search-input::-ms-clear {
  display: none;
}

/*-----------------------------eo Hero----------------------------------*/

/*-----------------------------sell in market CTA------------------------------------*/
.sell-in-market-cta-container {
  min-height: 100px;
  display: flex;
  text-align: center;
  background-image: url('./components/common/images/subpage-header-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.sell-in-market__cta-message {
  display: inline-block;
  margin: auto;
  margin-right: 0;

  min-height: 60px;
  color: $white;
  font-family: $Montserrat;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: -0.3px;
  line-height: 60px;
}

.sell-in-market__cta-button {
  display: inline-block;
  margin: 26px auto 26px 32px;
  border-radius: 4px;
  background-color: $white;

  .mdc-button.mdc-ripple-upgraded {
    min-height: 48px;
    padding: 6px 24px;

    .mdc-button__label {
      color: $fortellis_purple;
      font-family: $Raleway;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1.61px;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .sell-in-market__cta-message {
    font-size: 32px;
    line-height: 56px;
  }
  .sell-in-market__cta-button {
    margin-left: 16px;
  }
}

@media screen and (max-width: 768px) {
  .sell-in-market-cta-container {
    display: block;
    min-height: unset;
    padding-left: 16px;
    padding-right: 16px;
  }
  .sell-in-market__cta-message {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
  }

  .sell-in-market__cta-button {
    min-height: unset;
    margin-top: 16px;
    .mdc-button.mdc-ripple-upgraded {
      min-height: unset;
      padding: 0 12px;
      .mdc-button__label {
        font-size: 14px;
        font-weight: 600;
        line-height: 40px;
      }
    }
  }
}
/*-----------------------------eo sell in market CTA----------------------------------*/

.line-height-inherit {
  line-height: inherit;
}

.component-content {
  background: $white;
  min-height: 650px;
  width: 100%;
}

.solution-details-screen_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-padding-common {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

@media screen and (max-width: 520px) {
  .page-padding-common {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.bg-whitesmoke {
  background: whitesmoke;
}

.featured-offerings {
  width: 100%;
  background-color: $fortellis_black;
  text-align: center;
  color: $white !important;
}

.featured-solution-list-item {
  height: 100%;
  width: 100%;
  justify-content: flex-start !important;
  background: transparent;
  box-shadow: none;
  border-radius: 4px !important;
  cursor: pointer;

  position: relative; /*16:9 aspect ratio, child needs to be absolute top left etc*/
  padding-top: 56.25%; /*16:9 aspect ratio*/
}

.featured-solution-description-container {
  background-color: $fortellis_black !important;
  position: relative;
  bottom: 0;
  padding-top: 17px;
  /* overflow-y: auto;*/
  text-align: start;
  cursor: pointer;
}

.padding-left-zero {
  padding-left: 0 !important;
}

.margin-left-zero {
  margin-left: 0 !important;
}

.featured-solution-title {
  color: rgba(255, 255, 255, 0.7) !important;
  font-family: $Montserrat !important;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-align: start !important;
}

.featured-solution-orgName {
  color: $white;
  font-family: $Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 11px;
}

.featured-solution-description {
  min-height: 80px;
  color: rgba(255, 255, 255, 0.7) !important;
  font-family: $Raleway !important;
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: start;
  margin-bottom: 24px;
}

@media screen and (max-width: 560px) {
  .featured-solution-title {
    font-size: 14px;
  }

  .featured-solution-orgName {
    font-size: 11px;
    margin-bottom: 8px;
  }

  .featured-solution-description {
    font-size: 12px;
  }
}

.featured-offerings-title {
  color: $white !important;
  font-family: Raleway;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 48px;
  text-align: center;
}

.featured-offerings-subtitle {
  color: rgba(255, 255, 255, 0.7);
  font-family: Raleway;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .featured-offerings-title {
    font-size: 28px;
    letter-spacing: 2px;
    line-height: 28px;
  }
  .featured-offerings-subtitle {
    font-size: 32px;
    line-height: 48px;
  }
}

@media screen and (max-width: 560px) {
  .featured-offerings-title {
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 28px;
  }
  .featured-offerings-subtitle {
    font-size: 20px;
    line-height: 24px;
  }
}

.featured-solution-image {
  margin: 0px;
  border: 2px solid $fortellis_black;
  border-radius: 4px;
  background-color: $white;
  background-size: 100% 100% !important;
  box-shadow: $box_shadow2;

  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.category-chip-selected {
  background-color: $fortellis_purple !important;
  color: $white !important;
}

.category-chip {
  border-radius: 16px;
  background-color: #c9c5c5;
  color: $fortellis_black;
  font-family: $Raleway !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  margin-right: 8px !important;
}

.category-chip:active {
  border-radius: 16px;
  background-color: $fortellis_purple;
  color: $white;
  font-family: $Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
}

.solution-section-container {
  padding: 0px 98px 32px 98px;

  .mdc-card__primary:last-child {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 1120px) {
  .solution-section-container {
    padding: 0px 16px;

    .solution-list-item {
      box-shadow: none !important;
    }
  }
}

@media screen and (max-width: 560px) {
  .solution-section-container {
    padding: 0px 16px;

    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 8;
    }

    .solution-list-item {
      box-shadow: none !important;
    }
  }
}

@media screen and (max-width: 560px) {
  .show-more-solutions-div {
    padding: 0px 16px;
    grid-column-end: span 8;
  }
}

@media screen and (max-width: 839px) and (min-width: 480px) {
  .solution-section-container {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 8 !important;
    }
  }
}

.solution-section-heading {
  color: $fortellis_black;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 48px;
}

.solution-section-subheading {
  color: $fortellis_dark_gray;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.solution-list-item {
  display: flex !important;
  flex-direction: row !important;
  /*min-height: 133px;*/
  justify-content: flex-start !important;
  border-radius: 2px !important;
  background-color: $white !important;
  box-shadow: $box_shadow2 !important;
  cursor: pointer;
}

.solution-list-item:hover {
  background-color: #e6e6e6;
}

.private-badge-icon-div {
  margin: 0.3px 0.3px 1px 9px;
}

.private-solution-badge-container-storefront {
  height: 24px;
  position: absolute;
  right: 0px;
}

.private-solution-badge-container-sol-details {
  margin-bottom: 16px;
}

.private-solution-badge {
  display: inline-block;
  height: 24px;
  width: 80px;
  border: 1px solid $border2;
  border-radius: 12px;
  text-align: center;

  .material-icons {
    display: inline-block;
    height: 25px;
    width: 25px;
    opacity: 0.5;
  }

  .private-badge-text {
    //display: inline-block;
    color: $fortellis_light_gray;
    font-family: $Raleway;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    vertical-align: 4px;
  }
}

.private-badge-icon-div {
  float: right;
  opacity: 0.5;
}
.solution-icon {
  height: 80px;
  width: 80px;
  margin-top: 16px !important;
  margin-left: 16px !important;
  margin-bottom: 16px !important;
  background-size: 100% 100% !important;
}

.solution-list-item-text {
  width: calc(100% - 96px) !important;
}

.solution-title-div {
  display: flex;
  position: relative;
}

.solution-title {
  font-family: $Montserrat !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-align: start !important;
  color: $fortellis_black;
  float: left;
  margin-bottom: 2px;
}

.solution-subtitle {
  min-height: 72px;
  /*overflow-y: auto;*/
  color: $fortellis_dark_gray;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: start;
  float: left;
}

.solution-subtitle-orgName {
  color: $fortellis_light_gray5;
  font-family: $Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 8px;
}

.learn-more-button {
  width: 235.16px;
  border-radius: 2px;
  background-color: $fortellis_purple;
}

.show-more-solutions-div {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.show-more-solutions-text-div {
  height: 40px;
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 560px) {
  .solution-title {
    font-size: 14px !important;
  }

  .solution-subtitle {
    font-size: 11px !important;
  }

  .solution-subtitle-orgName {
    font-size: 12px !important;
  }
  .solution-title-spacer-div {
    width: 20px;
    display: inline-block;
  }
}

.join-exchange-strip {
  /*padding: 0px !important;
  padding-left: 0 !important;*/
  min-height: 220px;
  width: 100%;
  background-color: $white;
}

.join-exchange-grid {
  height: 100%;
  /*padding: 0;
  padding-left: 0px !important;*/
  padding-top: 0;
}

.join-exchange-cell {
  height: 220px;
  display: flex;
  justify-content: center !important;
}

.join-exchange-cell-card {
  /* padding: 60px 0px;*/
  height: 100%;
  justify-content: center !important;
  background: transparent;
  box-shadow: none;
}

.center-aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-fortellis-marketplace-title {
  padding-top: 0 !important;
  color: $fortellis_black;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.join-exchange-strip-item-title {
  padding-top: 0;
  color: $fortellis_purple;
  font-family: Raleway;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}

.join-exchange-strip-item-subtitle {
  color: $fortellis_dark_gray;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.expansion-sidenav-header {
  border-bottom: 1px solid black;
  width: 100% !important;
}

.expansion-sidenav-step {
  border-bottom: 1px solid black;
}

.fm-page-title-cell {
  position: relative;
  top: -27px;
  height: 5px;
}

.fm-page-title {
  font-size: 26px;
  font-weight: bold;
  font-family: $Raleway;
  margin: 0px;
}

.back-link {
  text-decoration: none !important;
  display: flex;
  color: #3b3331 !important;
}

.fm-page-title-text {
  padding-left: 0px !important;
}

.fm-side-nav {
  padding-left: 0px;
}

.fm-solution-form-content {
  padding-left: 0px !important;
}

.fm-page-content-grid {
  padding-top: 20px !important;
  padding-bottom: 96px !important;
}

.fm-page-title-grid {
  padding: 20px 0 0 0 !important;
  font-size: 19px;
  font-weight: bold;
  line-height: 29px;
}

.fm-page-solution-subscription-header-grid {
  padding: 0px !important;
}

.fm-page-breadcrumb-grid {
  padding: 20px 0 0 0 !important;
}

.fm-form-layout-grid {
  padding: 0px !important;
}

.fm-page-solution-submission-title {
  margin: 0px !important;
}

.fm-page-content-title {
  margin: 0px !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  font-family: $Montserrat !important;
  line-height: 40px !important;
  letter-spacing: 0.25px;
  color: $fortellis_light_gray2 !important;
}

.fm-page-content-title-cell {
  width: 100% !important;
}

.fm-page-content-title-note {
  font-family: $Raleway;
  font-size: 16px;
  color: $fortellis_dark_gray;
  font-weight: 500;
  line-height: 24px;
  padding-top: 8px;
  margin: 0px;
}

.fm-page-content-title-note2 {
  font-family: $Raleway;
  font-size: 12px;
  color: $fortellis_dark_gray;
  font-weight: 500;
  line-height: 14px;
  padding-top: 8px;
  margin: 0px;
}

.fm-card-title {
  font-size: 24px !important;
  padding-bottom: 20px !important;
  font-family: $Raleway !important;
  margin-right: 8px !important;
}

.fm-card-title-right-link {
  float: right;
  color: $fortellis_purple;
  font-family: $Raleway;
  font-size: 13px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.fm-card-description {
  font-size: 14px;
  color: $fortellis_dark_gray;
  font-weight: 400;
  font-family: $Raleway;
}

.fm-card-text {
  padding: 20px 0px 0px 0px !important;
}

.fm-expansion-panel-group {
  padding: 0px !important;
}

.fm-expansion-panel {
  margin: 0px;
}

.fm-expansion-panel-header {
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fm-expansion-panel-title-logo {
  flex-basis: 40px !important;
  height: 40px;
  width: 40px;
  margin-right: 15px;
  vertical-align: middle;
}

.fm-expansion-panel-title {
  width: 35%;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
}

.fm-expansion-panel-description {
  width: 35%;
  margin-right: 0px;
  .description {
    width: 100%;
    min-width: 230px;
    white-space: normal;
    word-wrap: break-word;
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 500;
    color: $fortellis_dark_gray;
  }
}

.fm-expansion-panel-app-activation {
  width: 10%;
  margin-right: 0px;
  .app-activation-column {
    width: 100%;
    min-width: 230px;
    white-space: normal;
    word-wrap: break-word;
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 500;
  }
  .app-activation-column-spinner {
    display: table;
  }
  .app-activation-column-pending {
    background-color: #f3c119;
    max-width: 140px;
    padding-top: 3px;
    font-size: 12px;
    text-align: center;
    height: 23px;
  }
}

.app-activation-solution-management-column-pending {
  background-color: #f3c119;
  width: 22%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 40px;
  height: 18px;
  font-size: 12px;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.app-activation-solution-management-spinner {
  margin-right: 110px;
}
.app-activation-solution-management-manual {
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 120px;
}

.fm-solution-row {
  cursor: pointer;
  background-color: white !important;
}

.fm-solution-row:hover {
  cursor: pointer;
  background-color: #f9f9f9 !important;
}

.fm-expansion-panel-status {
  width: 14% !important;
  text-align: right !important;
  color: $fortellis_black !important;
  line-height: 50px !important;
  font-family: $Raleway !important;
  font-weight: 500 !important;
}

.features-expansion .cdk-expansion-panel--expanded .fm-expansion-panel-content {
  min-height: 65px;
}

.features-expansion .cdk-expansion-panel--expanded .content-actionbar {
  padding-right: 30px;
}

.fm-expansion-panel .header-expansion-indicator {
  display: none;
}

.fm-status-indicator {
  font-size: 16px !important;
  margin-left: 8px;
  vertical-align: text-bottom !important;
}

.drawerContent .cdk-expansion-panel {
  box-shadow: none !important;
  border: 0px !important;
  width: 100% !important;
  padding: 0px !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
}

.drawerContent .cdk-expansion-panel__header {
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;
  color: $fortellis_dark_gray !important;
}

.drawerContent .header-title {
  padding: 0px !important;
  color: $fortellis_dark_gray !important;
  width: 100% !important;
  flex-direction: unset !important;
  flex-basis: unset !important;
}

.drawerContent .cdk-expansion-panel__header:hover span {
  background-color: inherit !important;
}

.features-expansion .cdk-expansion-panel__header .header-title {
  padding: 0px 16px !important;
  flex-basis: 100% !important;
}

.drawerContent .header-expansion-indicator {
  float: right !important;
}

.drawerContent .cdk-expansion-panel__content {
  padding: 0px !important;
  width: 100% !important;
}

.submit-dialog-message {
  font-size: 16px;
  font-family: $Raleway;
  color: $fortellis_light_gray;
  font-weight: 500 !important;
  line-height: 24px;
}

// .mdc-dialog__footer .mdc-button {
//   border: 0px !important;
// }

.title-content {
  width: 100% !important;
  padding-top: 0px !important;
}

.form-title-container {
  margin-top: 20px;
  display: flex;
}

.form-title-text {
  width: 70%;
}

.form-title-action {
  width: 30%;
}

.solution-name-title {
  border-radius: 2px;
  background-color: $fortellis_grey;
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
}

.solution-name-title-div {
  display: flex;
  padding: 5px 10px;
  margin-top: 0px;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  .solution-details-sec {
    flex: 1;
  }
}

.solution-name-title-name {
  font-size: 16px;
  line-height: 12px;
  font-family: $Raleway;
  max-width: 500px;
  word-break: break-word;
  margin-bottom: 4px;
  font-style: normal;
}
.solution-common-header-link {
  width: 103px;
  height: 14px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #5e2368;
  margin-right: 8px;
  cursor: pointer;
  text-decoration: none;
}
.solution-common-header-subtitles {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #151b25;
}

.solution-common-header-subtitles-value {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #151b25;
}
.solution-name-title-name.admin-listing-version {
  margin-bottom: 6px;
  font-weight: 600;
}

.solution-name-title-last-updated {
  font-size: 12px;
  font-family: $Raleway;
  color: $fortellis_dark_gray;
  font-weight: 500;
  line-height: 16px;
}

.solution-name-title-status {
  position: absolute;
  right: 25px;
  top: 5px;
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  font-family: $Raleway;
}

.solution-name-title-status .status-info {
  top: 19px !important;
  right: 20px !important;
}

.fm-solution-name-title-logo {
  flex-basis: 48px !important;
  height: 64px;
  width: 64px;
  margin-right: 10px;
  margin-left: 6px;
  border-radius: 8px;
}

.fm-page-back-title-div {
  display: flex;
  display: flex;
}

.fm-page-back-title-arrow {
  position: relative;
  top: 22px;
  padding-right: 32px;
  cursor: pointer;
}

.fm-page-back-title-arrow-icon {
  color: $fortellis_light_gray;
}

.fm-page-back-title-text {
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: $Raleway !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.max-width-resp {
  max-width: 1280px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  overflow-x: auto; //for table overflow in any page
  /* border: 1px solid;*/
}

.max-width-resp-landing-page {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto; //for table overflow in any page

  position: relative;
}

.cards-filters-container-landing-page {
  margin-top: 58px;
}

.overflow-x-unset {
  overflow-x: unset;
}

.list-menu {
  font-family: $Raleway;
}

.list-menu .mdc-list-item {
  padding-left: 16px !important;
}

.fm-menu-item-with-icon {
  vertical-align: sub !important;
  color: $fortellis_purple;
}

.mdc-snackbar__text {
  color: white !important;
  font-family: $Raleway !important;
}

.mdc-image-list__image {
  margin-left: auto;
  margin-right: auto;
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: $black;
}

.title-divider {
  padding: 0px !important;
}

.fm-page-title-div {
  padding: 0px 70px !important;
}

.fm-page-component-grid {
  padding: 0px !important;
}

.login-button {
  border: 1px solid $white !important;
  position: relative !important;
  height: 35px !important;
  top: -6px !important;
}

.character-remaining-div {
  font-size: 12px;
  color: $fortellis_dark_gray;
  font-weight: 500;
  font-family: $Raleway;
  float: right;
}

.status-info {
  position: absolute;
  top: 33px;
  right: 39px;
  font-size: 12px;
  font-family: $Raleway;
  color: $fortellis_purple;
  text-align: right;
}
.status-info-header-table {
  top: 33px;
  right: 39px;
  font-size: 12px;
  font-family: $Raleway;
  color: $fortellis_purple;
  text-align: center;
}

.solution-status-in-sol-management {
  display: inline-block;
  float: right;
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin: auto;
}

.status-info-in-solution-management {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: right;
}

.status-info-in-mysolutions {
  font-size: 12px;
  font-family: $Raleway;
  color: $fortellis_purple;
  line-height: 12px;
  text-align: end;
  margin-right: 20px;
  margin-top: -12px;
}

.view-error-tooltip {
  border-radius: 2px !important;
  background-color: $white !important;
  color: $fortellis_light_gray2 !important;
  border: 1px solid $border2 !important;
  font-family: $Raleway !important;
  padding: 12px !important;
  font-weight: 500 !important;
  position: absolute !important;
  top: 70px !important;
  left: unset !important;
  right: 0px !important;
  font-size: 12px !important;
  max-width: 800px !important;
  min-height: 28px !important;
  z-index: 100 !important;
  white-space: pre-wrap !important;
  text-align: left !important;
  box-shadow: $box_shadow3 !important;
  width: 220px;
  line-height: 24px;
}

.fortellis-field-info {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  font-family: $Raleway !important;
  text-align: left !important;
  color: $fortellis_light_gray2 !important;
  background-color: $white !important;
  max-width: 470px !important;

  border-radius: 2px !important;
  border: 1px solid $border2 !important;
  box-shadow: $box_shadow3 !important;
  z-index: 100 !important;
}

.review-error-tooltip {
  border-radius: 2px;
  background-color: #fff;
  color: $fortellis_light_gray2;
  border: 1px solid $border2;
  font-family: $Raleway;
  padding: 12px !important;
  font-weight: 500;
  position: absolute;
  top: 70px !important;
  left: unset !important;
  font-size: 12px;
  max-width: 800px;
  min-height: 28px;
  z-index: 100 !important;
  white-space: pre-wrap;
  text-align: left;
  right: 0px;
}

.review-fields-label {
  font-family: $Raleway;
  font-size: 12px !important;
  color: $fortellis_light_gray !important;
  font-weight: 600 !important;
}

.review-fields-value {
  font-family: $Raleway !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: $fortellis_light_gray2 !important;
  line-height: 24px !important;
  width: 100% !important;
  word-wrap: break-word !important;
}

.review-fields-div {
  padding-bottom: 16px !important;
}

.static-demo-field-div {
  border-radius: 2px;
  background-color: $fortellis_grey;
  padding: 16px;
  display: -ms-flexbox;
  white-space: pre;
}

.static-demo-field-label {
  font-family: $Raleway;
  font-size: 12px !important;
  color: $fortellis_light_gray !important;
  font-weight: 600 !important;
}

.static-demo-field-value {
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  color: $fortellis_light_gray2;
  line-height: 24px;
}

.form-image-display {
  border: 1px solid $border2 !important;
}

.review-solution-version {
  font-weight: 600;
  max-width: 5.25vw;
  overflow: auto;
  white-space: pre-line;
}

.review-listing-version {
  font-weight: 600;
}

.review-solution-date {
  font-weight: 600;
}
.review-solution-developername {
  font-weight: 600;
}

.review-solution-developername a {
  color: #904778;
  position: relative;
  display: inline-block;
}

.review-solution-developername div {
  display: none;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  font-family: Raleway;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
    rgba(0, 0, 0, 0.12) 0px 3px 14px 3px, rgba(0, 0, 0, 0.2) 0px 4px 5px 0px;
  cursor: auto;
  position: absolute;
  z-index: 1;
  left: -5%;
  border-radius: 5px;
}

.review-solution-developername div::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
}

.review-solution-developername div:hover {
  display: flex;
}

.review-solution-developername a:hover + div {
  display: flex;
}

.tooltip-link {
  cursor: pointer;
}

.tooltip-submission-form-error {
  right: 0px !important;
  left: unset !important;
  top: 45px !important;
}

.required-icon {
  margin-left: 2px;
  color: inherit !important;
  font-size: 8px !important;
  position: relative;
  top: -7px;
}

.required-error {
  color: #d50000 !important;
}

.required-error .form-field-label {
  color: #d50000 !important;
}

.required-error .mdc-select__bottom-line {
  background-color: #d50000 !important;
}

//footer
.fortellis-footer-container {
  align-content: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.footer-alignment {
  margin: auto;
}

.block-social-link {
  font-family: 'Raleway', sans-serif;
  line-height: 27px;
  letter-spacing: 1.28px;
  font-size: 14px;
  font-weight: 400;
}

.social-links li:after {
  padding-left: 10px;
  content: '|';
  font-weight: 400;
  color: grey;
}

.footer-alignment {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
  }
}

.footer.footer-fixed-bottom {
  position: static;
}

.copyright-container {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $fortellis_dark_gray;
  line-height: 27px;
  letter-spacing: 1.28px;
}

footer.footer {
  .top-footer-container {
    border-top: 2px solid #5f6165;
    border-bottom: 2px solid #5f6165;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .region-footer-cta {
    background-color: #171e2b;
    border-radius: 8px;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.36);
    padding: 50px;
  }
  .footer-menus {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

footer.footer .footer-menus .region-footer1 {
  font-size: 15px;
  font-weight: 300;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

footer.footer {
  .footer-menus {
    .region-footer1 p span.logo-title {
      font-size: 32px;
      font-weight: 700;
      color: $fortellis_purple;
      letter-spacing: 4.94px;
      font-family: 'Raleway', sans-serif;
    }
    .block-title {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #fff;
      letter-spacing: 0.72px;
      margin-bottom: 10px;
    }
  }
  .copyright-container {
    padding-top: 25px;
    border-top: 2px solid #5f6165;
  }
}

.form-content-label {
  color: $fortellis_black !important;
  font-family: $Montserrat !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  padding: 16px 0 0 0 !important;
}

.form-content-divider {
  margin-bottom: 20px !important;
  height: 10px !important;
  padding: 10px !important;
}

.select-freeeTiral {
  width: 100px !important;
  top: 2px;
  min-width: 100px !important;
}

.select-transactionalType {
  width: 50px !important;
  top: 2px;
  min-width: 50px !important;
}

.select-field-post-text {
  display: inline-block;
  margin-left: 24px;
  margin-right: 24px;
  vertical-align: bottom;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 30px;
}

.multiple-field-form-div {
  border-radius: 2px;
  background-color: #fafafa;
  padding: 21px 30px 30px 19px;
  margin-bottom: 1.88%;
  display: -ms-flexbox;
}

.pricing-dependent-filed-title {
  font-weight: 600;
  margin-top: 35px;
  height: auto !important;
  line-height: 0px !important;
  font-family: $Montserrat !important;
}

.multiple-add-title-sub {
  color: $fortellis_black !important;
  font-family: $Montserrat !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  padding: 16px 0 0 0 !important;
}

.unauthorized-alert-message {
  font-size: 20px;
  padding-top: 10px;
}

.form-action-snackbar {
  background-color: rgba(0, 0, 0, 1) !important;
  z-index: 1 !important;
}

.table-list {
  width: 100%;
}

[data-testid='apiInfo-simple-table-wrapper'] {
  border: none !important;
}

.published-history-list {
  table-layout: fixed;
  width: 100%;
  margin-top: 20px;
  white-space: normal;
}

.beta-tag {
  //position
  /*position: relative;
  top: -5px;*/
  vertical-align: super;
  display: inline-block;

  //size
  height: 12px;
  width: 30px;
  text-align: center;
  border: 1px solid $white;
  border-radius: 2px;

  //font
  color: $white;
  font-family: $Raleway;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.51px;
  line-height: 10px;
}

.alert-dialog {
}

.alert-dialog .mdc-dialog__surface {
  min-width: 500px !important;
  width: 500px !important;
}

.alert-dialog .submit-dialog-message {
  width: inherit !important;
  margin-top: 30px !important;
  padding: 0px 30px 30px !important;
}

.alert-dialog .mdc-dialog__footer {
  width: inherit !important;
}

.featured-app-nullstate-height {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  body footer.footer .footer-menus .region-footer1 p {
    padding-top: 25px !important;
  }
}

@media screen and (min-width: 768px) {
  body footer.footer .footer-menus .region-footer1 p {
    padding-top: 0 !important;
  }
}

.show-more-leads {
  height: 40px !important;
  width: 100% !important;
  color: $fortellis_light_gray2 !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 40px !important;
  padding: 0px !important;
  text-align: center !important;
  border: 1px solid $border !important;
  border-radius: 2px !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.fm-expansion-row-more-menu {
  z-index: 1;
}

.icon-toggle {
  z-index: 1;
}

.fm-expansion-row-more-menu .mdc-ripple-upgraded--background-focused {
  color: $fortellis_dark_gray !important;
}

.more-menu-item {
  font-family: $Raleway !important;
  font-size: 15px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
  color: black !important;
  padding: 12px 16px !important;
}

.leads-table-container {
  width: 100%;
  overflow-x: auto;
}

.lead-message {
  width: 100%;
  word-wrap: break-word;
  font-family: $Raleway;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  white-space: pre-wrap;
}

.lead-row {
  white-space: normal;
  word-wrap: break-word;
  font-family: $Raleway;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.1px;
  background-color: white !important;
  color: $fortellis_light_gray2;
}

.lead-row a {
  color: $fortellis_purple;
}

.lead-card-chips-div {
  width: 70%;
}

.leads-card-avatar {
  padding: 13px !important;
  border-radius: 0% !important;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.leads-card-avatar img {
  border-radius: 0% !important;
  margin: 10px;
}

.lead-card-title {
  width: 100% !important;
  color: $fortellis_light_gray2 !important;
  font-family: $Montserrat !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 0px !important;
  letter-spacing: 0.12px !important;
  margin-top: 23px !important;
  margin-left: 60px !important;
}

.fm-leads-solution-title-logo {
  height: 32px !important;
  width: 32px !important;
  margin-right: 10px;
}

.empty-content-div {
  text-align: center;
  padding: 24px;
}

.empty-content-card-img {
  width: 100%;
}

.empty-content-card-message {
  position: relative;
  height: 0px;
  top: -50px;
  width: 100%;
  text-align: center;
  color: $fortellis_light_gray;
  font-size: 14px;
  font-family: $Raleway;
  font-weight: 500;
}

.view-lead-message-tooltip {
  border-radius: 2px !important;
  background-color: $white !important;
  color: $fortellis_light_gray2 !important;
  line-height: 20px !important;
  font-family: $Raleway !important;
  padding: 12px !important;
  font-weight: 500 !important;
  position: absolute;
  left: unset !important;
  top: unset !important;
  right: 0.5vw !important;
  font-size: 14px !important;
  max-width: 400px !important;
  min-height: 28px !important;
  z-index: 100 !important;
  white-space: pre-wrap !important;
  text-align: left !important;
  box-shadow: $box_shadow3 !important;
}

.admin-leads-tooltip-position {
  width: 327px !important;
  white-space: pre-wrap;
}

.lead-message-with-tooltip {
  z-index: 1;
  white-space: pre-wrap;
}

.leads-sort-header-column {
  font-weight: 600 !important;
  cursor: pointer;
}

.leads-header-column {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.09px;
  text-align: left !important;
}

.leads-header-column-right {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.09px;
  text-align: right !important;
}

.leads-sort-header-column .material-icons {
  font-weight: 600 !important;
}

.admin-lead-table {
  padding: 10px 0px 0px !important;
}

.empty-content-card-message-myown {
  position: relative;
  height: 0px;
  top: -50px;
  width: 100%;
  text-align: center;
  color: $fortellis_light_gray;
  font-size: 14px;
  font-family: $Raleway;
  font-weight: 500;
}

.admin-leads-solution-logo-div {
  display: inline-block;
  width: 40px;
  margin-right: 16px;
  vertical-align: bottom;
}

.admin-leads-solution-name-div {
  display: inline-block;
  max-width: 150px;
  height: 40px;
  position: absolute;
  margin-left: 16px;
}

.admin-leads-solution-logo {
  flex-basis: 40px !important;
  height: 40px;
  width: 40px;
  vertical-align: super;
}

.admin-leads-solution-div {
  height: 40px;
}

.admin-leads-solution-name {
  vertical-align: middle;
}

.cursor-pointer {
  cursor: pointer;
}

.empty-state__solutions {
  width: 200%;
  height: 100%;
  padding: 16px;
}

.formtextfield {
  width: 100% !important;
  /*align-content: center;*/
}

.relativeformtextfield {
  height: 30px !important;
  margin-top: 0px !important;
}

.toggleformtextfield {
  height: 30px !important;
  margin-top: 0px !important;
}

.lead-card-filters-div {
  display: flex !important;
  flex-direction: row !important;
}

.lead-card-search-box-div {
  position: relative;
  width: 30%;
  z-index: 0;
}

.lead-card-search-box-div .cdk-persistent-search__container {
  position: absolute;
  z-index: 1000;
  background: #f5f5f5;
  width: 90%;
}

.lead-card-search-box {
  height: 32px !important;
}

.lead-card-search-box .mdc-text-field__input {
  color: $fortellis_light_gray2 !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  padding-left: 10px !important;
}

.lead-card-search-box-div
  .cdk-persistent-search__result-container
  .cdk-persistent-search__results {
  color: $fortellis_light_gray !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  letter-spacing: 0.25px !important;
  line-height: 26px !important;
  padding-left: 50px !important;
}

.lead-card-search-box-div
  .cdk-persistent-search__result-container
  .cdk-persistent-search__results
  .cdk-persistent-search__watch-icon {
  padding-right: 18px !important;
  display: none !important;
}

.lead-card-search-box-div .cdk-persistent-search__container {
  box-shadow: none !important;
  border-bottom: 1px solid $border !important;
  border-radius: 1px !important;
}

.cdk-persistent-search__back {
  cursor: pointer !important;
}

.cdk-persistent-search__cancel {
  cursor: pointer !important;
}

.features-expansion
  .cdk-expansion-panel--expanded
  .cdk-expansion-panel__content {
  height: 100% !important;
}

.status_history_title {
  height: 24px !important;
  width: 720px !important;
  color: $fortellis_light_gray2 !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.12px !important;
  line-height: 24px !important;
}

.status_history_optional {
  height: 18px !important;
  width: 720px !important;
  color: $fortellis_light_gray !important;
  font-family: $Raleway !important;
  font-size: 12px !important;
  letter-spacing: 0.1px !important;
  line-height: 18px !important;
  padding-top: 8px;
  padding-left: 28px;
}

.status_history_oval {
  height: 16px;
  width: 16px;
}

.status_history_card_text {
  color: $fortellis_dark_gray !important;
  font-family: Raleway !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  word-wrap: break-word !important;
  background-color: white;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 8px 20px 20px 44px;
}

.subscription-stepper {
}

.subscription-stepper .cdk-stepper__step:first-child {
  padding-left: 0px !important;
}

.subscription-plan-details.cdk-expansion-panel--expanded {
  border-radius: 3px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.subscription-plan-details .cdk-expansion-panel__header {
  height: 80px !important;
}

.subscription-plan-details .cdk-expansion-panel__header .header-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.5px;
  flex-basis: unset;
}

.subscription-plan-details .cdk-expansion-panel__header .header-description {
  flex-direction: row-reverse;
}

.subscription-plan-details.cdk-expansion-panel--expanded
  > .cdk-expansion-panel__content {
  height: auto !important;
}

.subscription-plan-details .cdk-expansion-panel__content .content-actionbar {
  border: none !important;
  min-height: 36px !important;
  padding: 0px !important;
  margin-bottom: 8px;
}

.subscription-plan-details.cdk-expansion-panel--expanded
  > .cdk-expansion-panel__header {
  background-color: #f5f5f5 !important;
  min-height: 80px !important;
  border-radius: 3px 3px 0 0;
}

.subscription-plan-details .cdk-expansion-panel__content .content-body {
  padding: 24px !important;
}

.subscription-plan-details.cdk-expansion-panel--expanded
  > .cdk-expansion-panel__header
  .header-title {
  font-weight: 500 !important;
  font-size: 24px !important;
  font-family: $Montserrat !important;
  color: rgba(0, 0, 0, 0.87);
  line-height: 29px !important;
}

.subscription-plan-details.cdk-expansion-panel--expanded
  .cdk-expansion-panel__header
  .header-expansion-indicator {
}

.subscription-plan-details.cdk-expansion-panel
  .cdk-expansion-panel__header
  .header-expansion-indicator {
  color: rgba(0, 0, 0, 0.54);
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}

.subscription-plan-details.cdk-expansion-panel .plan-price-title {
  color: rgba(0, 0, 0, 0.54);
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  text-align: right;
}

.subscription-plan-details.cdk-expansion-panel .plan-price-title-postfix {
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
}

.subscription-plan-details.cdk-expansion-panel--expanded .plan-price-title {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  font-family: $Montserrat !important;
  line-height: 32px !important;
  letter-spacing: -0.5px;
}

.subscription-plan-details.cdk-expansion-panel--expanded
  .plan-price-title-postfix {
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: $Raleway !important;
  line-height: 32px !important;
}

.plan-content-includes-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
}

.plan-content-includes-list-item {
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding-right: 24px;
}

.plan-content-pricing-details {
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
}

.plan-content-pricing-details-includes {
  width: 100%;
}

.plan-content-pricing-details-final {
  display: flex;
  flex-basis: unset !important;
  flex-direction: row-reverse !important;
  text-align: right;
  width: 100%;
}

.plan-pricing-include-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.plan-pricing-include-title-note {
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-top: 6px;
}

.plan-pricing-include-items {
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
}

.plan-pricing-include-item {
  width: 100%;
  margin-top: 8px;
}

.plan-pricing-include-item-title {
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
}

.plan-pricing-include-item-count {
  margin: 0 0 0 30px;
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.plan-checkout-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  margin-top: 24px;
}

.plan-checkout-amount {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
  text-align: right;
  padding-left: 32px;
  margin-top: 16px;
}

.plan-checkout-notes {
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 14px;
  text-align: right;
  margin-top: 8px;
}

.plan-action-panel {
  margin-bottom: 8px;
  height: 36px;
}

.plan-action-terms {
  width: 100%;
  margin: 24px 0 0;
}

.plan-terms-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-right: 25px;
}

.plan-terms-title-notes {
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.sub_error_span {
  width: 46px;
  display: inline-block;
}
.sub_error_span_text {
  margin-left: 10px;
}
.plan-action-buttons {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.plan-action-button-terms {
  margin-top: 8px;
}
.plan-term-text {
  margin-top: 8px;
}

.plan-terms-link {
  height: 24px;
  width: 146px;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $fortellis_purple !important;
  cursor: pointer !important;
}

.plan-action-button-subscribe {
  width: 20%;
  text-align: right;
}

.plan-action-panel-note {
  text-align: left;
  width: 100%;
  border-radius: 0 0 3px 3px;
  background-color: rgba(144, 71, 120, 0.08);
  height: 48px;
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 48px;
  padding: 0 24px !important;
}

.cdk-stepper__step--inactive .cdk-stepper__step__icon {
  background-color: rgba(0, 0, 0, 0.38) !important;
}

.cdk-stepper__step__icon {
  background-color: $fortellis_purple !important;
}

.cdk-stepper {
  background: none !important;
}

.solution-subscription-header {
  height: 84px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
}

.solution-subscription-header-content {
  display: flex;
}

.solution-subscription-header-logo-div {
  height: 48px;
  width: 48px;
  margin: 0 20px 20px 0 !important;
}

.solution-subscription-header-logo {
  height: 48px;
  width: 48px;
}

.solution-subscription-header-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 48px;
}

.plan-content-list-check-icon {
  height: 24px;
  width: 22px;
  margin-right: 16px;
}

.plan-list-header {
  width: 100%;
  display: flex;
}

.subscription-plan-details.cdk-expansion-panel--expanded .plan-title-note {
  color: rgba(0, 0, 0, 0.54) !important;
}

.subscription-plan-details.cdk-expansion-panel .plan-title-note {
  text-align: left !important;
  flex-direction: row !important;
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 32px;
}

.plan-content-section {
  display: flex !important;
}

.plan-content-feature-div {
  width: 60%;
}

.plan-content-pricing-div {
  width: 40%;
}

.plan-terms-dialog-header {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
}

.plan-terms-dialog-body-container {
  overflow: unset !important;
  max-height: unset !important;
  margin-top: 16px !important;
}

.plan-terms-accept-checkbox {
  padding: 0px !important;
}

.plan-terms-accept-checkbox-label {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.plan-terms-content {
  max-height: 426px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  overflow-y: scroll;
}

.plan-terms-dialog {
  z-index: 100000 !important;
}

.plan-terms-content-iframe {
  width: 100%;
  height: 426px;
  overflow-y: scroll;
  margin-top: 24px;
  padding: 10px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  background-color: #f5f5f5;
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.plan-terms-content-text {
  padding: 10px 16px;
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background {
  background-color: $fortellis_purple !important;
  border-color: $fortellis_purple !important;
}

.mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: $fortellis_purple !important;
}

.mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: $fortellis_purple !important;
}

.mdc-switch__native-control:enabled:checked ~ .mdc-switch__background::before {
  background-color: $fortellis_purple !important;
  border-color: $fortellis_purple !important;
}

.mdc-switch__native-control:enabled:checked
  ~ .mdc-switch__background
  .mdc-switch__knob {
  background-color: $fortellis_purple !important;
  border-color: $fortellis_purple !important;
}
.subscribed-plan-details {
}

.subscribed-plan-header {
  cursor: default !important;
}

.subscribed-plan-details .header-expansion-indicator {
  display: none !important;
}

.plan-content-contact-title-div {
  margin-top: 24px;
}

.plan-content-contact-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 24px;
}

.plan-content-contact-title-name {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 24px;
}

.plan-content-contact-details {
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 24px;
  margin-top: 8px;
}

.plan-content-contact-details a {
  color: $fortellis_purple;
}

.subscribed-plan-terms-link {
  color: $fortellis_purple;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 24px;
  cursor: pointer;
}

.plan-pricing-div-note {
  color: rgba(0, 0, 0, 0.54);
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-top: 8px;
}

.plan-content-note a {
  color: $fortellis_purple !important;
}

.fm-page-header-title {
  margin: 0 !important;
}

.fm-page-description-bold {
  font-weight: 700 !important;
}

.card-action-panel {
  display: flex;
  flex-direction: row-reverse !important;
}

.card-action-button {
  background-color: $white !important;
}

.subscription-card-header {
  background-color: #f5f5f5 !important;
  display: flex;
  padding: 16px 0px 16px 24px !important;
}

.subscription-card-header-content {
  width: 100%;
  margin-right: 24px;
}

.subscription-card-title {
  color: $fortellis_light_gray2 !important;
  font-family: $Montserrat !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  letter-spacing: 0.15px !important;
  line-height: 32px !important;
}

.subscription-card-sub-title {
  color: $fortellis_light_gray !important;
  font-family: $Raleway !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
}

.subscription-card-title-logo {
  flex-basis: 48px !important;
  height: 48px;
  width: 48px;
  margin-right: 16px;
  background-size: contain !important;
}

.subscription-card-title-label-text {
  margin-left: 16px;
  border-radius: 2px;
  height: 24px;
  padding: 5px;
  background-color: #5a6377;
  color: $white;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.subscription-card-title-div {
  display: flex;
  width: 100%;
}
.subscription-page-card-title-div {
  display: inline-block;
}

.cancel-subscription-button {
  display: flex;
  float: right;
  margin-top: 20px;
}
.subscription-card-status-div {
  margin-right: 24px;
  text-align: right;
  width: 20%;
  line-height: 48px;
}

.subscription-card-status-text {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px !important;
  text-transform: capitalize;
}

.subscription-card-status-indicator {
  margin-left: 8px !important;
  font-size: 20px !important;
  vertical-align: middle !important;
}

.subscription-card-content {
  margin: 8px 24px 8px 24px !important;
  padding: 0px !important;
}
.subscription-billing-card-content {
  background-color: $white;
  margin: 16px 0px 16px 0px !important;
  padding: 0px !important;
}
.subscription-card button {
  background-color: transparent !important;
}

.subscription-billing-card-parent .subscription-card button {
  background-color: $white !important;
}

.subscription-card-content button {
  background-color: $white;
}

.subscription-card-content-title-text-left {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

.subscription-card-content-title-text-right {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  float: right;
}

.subscription-card-content-title-text-right-sub {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
}

.subscription-card-content-steps {
  margin-top: 16px;
}

.subscription-card-content-steps .panel-title {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 24px;
}

.subscription-card-content-steps .panel-content-div {
  max-width: 70%;
  color: $fortellis_dark_gray;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 24px;
}

.subscription-card-content-steps .panel-action-div {
  min-height: 70px;
}

.subscription-card-content-steps .mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  color: $fortellis_purple;
}

.subscription-panel-content-contact-div {
  margin-top: 0px;
}

.subscription-panel-content-contact-title {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.subscription-card-action-panel {
  flex-direction: row-reverse !important;
  padding: 16px 8px 8px !important;
}

.subscription-card-action-panel .card-action-button {
  padding: 0 15px 0 16px !important;
}

.panel-content-div .bold-text {
  color: $fortellis_light_gray2 !important;
  font-weight: 700 !important;
}

.mdc-card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.cdk-expansion-panel {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
}

.cdk-expansion-panel.cdk-expansion-panel--expanded {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.12),
    0 1px 10px 0 rgba(0, 0, 0, 0.2) !important;
}

.feature-expansion-menu .cdk-expansion-panel.cdk-expansion-panel--expanded {
  box-shadow: none !important;
}

.subscription-card {
  margin-bottom: 56px;
  border-radius: 2px;
  background-color: $fortellis_grey !important;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.subscription-mamagement-card {
  margin-bottom: 56px;
  border-radius: 2px;
  background-color: $fortellis_grey !important;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.view-plan-dialog {
  z-index: 100000 !important;
}

.view-plan-dialog .header-expansion-indicator {
  display: none !important;
}

.view-plan-dialog .plan-terms-dialog-body-container {
  border: none !important;
}

.view-plan-dialog .subscription-plan-details {
  box-shadow: none !important;
  border: none !important;
}

.view-plan-dialog
  .subscription-plan-details
  .cdk-expansion-panel__content
  .content-body {
  padding: 24px 0 !important;
}

.view-plan-dialog .mdc-dialog__surface {
  max-width: 990px !important;
}

.view-plan-dialog .plan-content-section {
  padding-right: 1px !important;
}

.view-plan-dialog .plan-content-feature-div {
  width: 50% !important;
}

.view-plan-dialog .plan-content-pricing-div {
  width: 50% !important;
}

.view-plan-dialog .plan-checkout-title {
  display: none !important;
}

.view-plan-dialog .plan-checkout-notes {
  display: none !important;
}

.fm-subscription-view-page .empty-content-div {
  padding: 24px 0 !important;
}

/*==============Api Configuration Screen==============*/
.page-title {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
  padding: 24px 0px;
}

.solution-strip {
  border-radius: 2px;
  background-color: $fortellis_grey !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;

  box-shadow: none !important;
}

.solution-strip-logo {
  height: 60px;
  width: 60px;
  margin: 16px 0px 16px 16px !important;
  background-size: 100% 100% !important;
}

.solution-strip-name {
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-align: start;
  line-height: 24px;
  color: $fortellis_light_gray2;
}

.solution-strip-company {
  color: $fortellis_light_gray;
  font-family: $Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
}

.solution-strip-content {
  padding: 16px;
  width: calc(100% - 64px);
}

.solution-strip-content-left {
  display: inline-block;
  margin-top: 9px;
}

.solution-strip-content-right {
  display: inline-block;
  float: right;
  margin-top: 4px;
  margin-right: 8px;
}

.solution-strip-entity-label {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-align: right;
}

.solution-strip-entity-name {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
}

.apiconfig-table-header {
  color: $fortellis_light_gray !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.11px !important;
  line-height: 56px !important;
}

.apiconfig-table-text {
  color: $fortellis_light_gray2 !important;
  font-family: $Raleway !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.11px !important;
  line-height: 22px !important;
}

.free-trial-indicator-text {
  padding: 5px 9px;
  margin-left: 16px;
  display: inline-block;

  color: $white;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;

  border-radius: 2px;
  background-color: $fortellis_badge_grey;
}

.api-provider-and-consent-table {
  td {
    vertical-align: top;
  }
  th {
    vertical-align: top;
  }
}

.api-config-description {
  margin-top: 32px;
  margin-bottom: 24px;
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.api-config-select {
  min-width: 250px !important;
}

.api-config__required_div {
  padding: 4px 9px;
  margin-left: 8px;
  display: inline-block;

  color: $white;
  font-family: $Raleway;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;

  border-radius: 2px;
  background-color: $fortellis_purple;
}

.uppercase_text {
  text-transform: capitalize;
}

.api-enablement-status-indicator {
  margin-left: 8px;
  margin-top: 0px !important;
}

/*==============end of Api Configuration Screen==============*/
.mysolution-empty-content-div {
  padding: 24px 0 !important;
}

.spinner-div {
  min-height: 400px !important;
}

.expansion-header-section {
  width: 100% !important;
}

.expansion-header-section .panel-description {
  float: right;
  line-height: 24px;
  display: unset !important;
  text-align: right !important;
  margin-right: 30px !important;
}

.subscription-action-note {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-family: $Raleway;
  font-weight: 600;
  line-height: 36px;
  margin-right: 16px;
  letter-spacing: 0.54px;
}

.mdb-button:hover {
  background-color: rgba(0, 0, 0, 0.01) !important;
}

// .mdc-button:disabled {
//   color: rgba(0, 0, 0, 0.37) !important;
//   cursor: default !important;
//   pointer-events: none !important;
// }

// .mdc-text-field--disabled .mdc-text-field__input{
//   border-bottom: 1px dotted white !important
// }

// .mdc-button--outlined:disabled {
//   border-color: rgba(0, 0, 0, 0.37) !important;
// }

// .mdc-button--primary::before, .mdc-button--primary::after, .mdc-dialog__action::before, .mdc-dialog__action::after {
//   background-color: $black !important;
// }

.subscription-cancel-solution-name {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.subscription-cancel-dialog-text {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 16px !important;
}

.mdc-dialog__surface {
  border-radius: 4px !important;
}

.cancel-subscription-dialog .mdc-dialog__surface {
  max-width: 700px !important;
}

.subscription-title-parent {
  margin-bottom: 16px;
}

.subscription-card-content-activated {
  display: flex;
  background-color: $white;
}

.subscription-card-content-activated .plan-name {
  width: 32%;
}

.subscription-card-content-activated .plan-dates {
  width: 24%;
}

.subscription-card-content-activated .plan-price {
  width: 20%;
  text-align: right;
}

.plan-price {
  margin-right: 24px;
}

.subscription-card-content-activated .plan-details-label {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 24px;
}

.subscription-card-content-activated .plan-details-value {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
}

.subscription-card-content-activated .plan-details-value-date {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.38px;
  line-height: 32px;
}

.subscription-card-content-activated .plan-details-price-digit {
  font-size: 32px;
  letter-spacing: -0.75px;
}

.subscription-card-content-activated .plan-details-price-per {
  color: $fortellis_dark_gray;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
}

.form-field-dropbox-title {
  height: 24px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: $Raleway !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin-bottom: 0;
}

.mdc-tab__indicator {
  right: 0px !important;
  left: 0px !important;
  width: 100% !important;
  bottom: 0px !important;
  vertical-align: bottom;
}

.solution-logo-avatar {
  border-radius: unset !important;
}

.purchase-sol__card .solution-logo-avatar {
  display: block;
}

.solution-logo-avatar img {
  border-radius: unset !important;
}

.generate-invoice-dialog .mdc-dialog--open .mdc-dialog__surface {
  width: 640px;
  border-radius: 2px;
  padding-bottom: 8px !important;
  background-color: $white;
  box-shadow: $box_shadow;

  .mdc-dialog__header {
    height: 76px;
  }

  .mdc-dialog__header__title {
    height: 24px;
    width: 112px;
    color: $fortellis_light_gray2;
    font-family: $Montserrat;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
}

.generate-invoice-title {
  height: 14px;
  width: 150px;
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 14px;
  margin-bottom: 12px;
}

.generate-invoice-text {
  height: 90px;
  width: 588px;
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.fdn-page__notfound {
  width: 100%;
  max-width: 1200px;
  margin: 32px auto;

  &-image {
    display: flex;

    @media (max-width: 1039px) {
      flex-direction: column;
      padding: 0em 2em;
    }

    img {
      @media (max-width: 1039px) {
        width: 100%;
      }
    }

    &-text {
      align-self: end;
      margin: 48px 0px;
      max-width: 425px;
      width: 100%;

      hr {
        width: 125px;
        border: none;
        border-top: 6px solid #e83a83;
        margin: 24px 0px;
      }

      h1,
      h2 {
        font-family: Montserrat, sans-serif;
        margin: 0px;
        text-transform: uppercase;
      }

      h1 {
        font-size: 36px;
        font-weight: 400;
        line-height: 36px;
        color: #461363;
      }

      h2 {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 18px;
        color: #00aae7;
      }

      .redirect {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none;
        color: #e83a83;
        border: none;
        background-color: transparent;
        padding: 0;

        &-icon {
          vertical-align: top;
          position: relative;
          width: 30px;
          height: 24px;

          .one,
          .two {
            position: absolute;
            top: 0;
          }
          .one {
            left: 0;
          }
          .two {
            left: 6px;
          }
        }
      }
    }
  }
}

/*========Custom Private Route=========*/
.unauthorized-page-container {
  min-height: calc(100vh - 80px - 372px); //100vh-header-footer
  background-color: #ffffff;
  padding-bottom: 1px;
  text-align: center;
}

.solution-belongs-to-diff-entity-txt-container {
  min-height: calc(60vh);
  background-color: $white;
  padding-bottom: 1px;
  text-align: center;
}

.unauthorized-page-content-text {
  font-family: Raleway;
  font-size: 24px !important;
  color: #686868 !important;
  font-weight: 500 !important;
}

.solution-not-accessible-container {
  padding: 48px 16px 16px 16px;
  text-align: center;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+ for tabs
  .mdc-tab__indicator {
    position: absolute;
    top: 46px; //tabs height - 2 px
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
/*======== eo Custom Private Route=========*/

.payment-card-header {
}

.payment-card-title {
  color: $fortellis_light_gray2 !important;
  font-family: $Montserrat !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  letter-spacing: -0.5px !important;
  line-height: 32px !important;
}

#z-header {
  display: none !important;
}

#z_hppm_iframe {
  min-height: 350px !important;
}

/* =============== solution-management ================ */
.manage-solution-page-title {
  font-size: 34px !important;
  font-weight: 600 !important;
  font-family: $Montserrat !important;
  line-height: 40px !important;
  letter-spacing: 0.25px;
  color: $fortellis_light_gray2 !important;
  margin-top: 24px;
  margin-bottom: 16px;
}

.solution-management-panel-header {
  padding: 24px 24px 10px 24px;
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
}

.solution-metric-label {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 14px;
  margin-bottom: 3px;
}

.solution-metric-value {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.listing-version-title {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;

  padding-bottom: 16px;
}

.view_in_marketplace_link {
  color: $fortellis_purple;
  font-family: $Raleway;
  font-size: 13px;
  padding: 0px;
  margin: 0px;
  font-weight: 600;
}

/* =============== eo solution-management ================ */

/* =============== solution-management - subscriptions ================ */
.sol-managemnt-subscriptions_table {
  .cdk-data-table td,
  .cdk-data-table th {
    text-align: left;
  }
  overflow-x: auto;
}

.sol-managemnt-subscriptions_table-header-text {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  color: $fortellis_light_gray !important;
  font-family: $Raleway !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
}

.sol-managemnt-subscriptions_table-body-text {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
  color: $fortellis_light_gray2 !important;
  font-family: $Raleway !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  letter-spacing: 0.15px !important;
  line-height: 16px !important;
}

.sol-managemnt-subscriptions-table-row {
  white-space: normal;
  word-wrap: break-word;
}

.subscription-status-text {
  text-transform: capitalize;
  color: $fortellis_light_gray2 !important;
  font-family: $Raleway !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  text-align: right !important;
}

.subscription-status-info {
  font-size: 12px;
  font-family: $Raleway;
  color: $fortellis_purple;
  text-align: right;
  text-transform: capitalize;
}

.entity-default-avatar {
  height: 24px !important;
  width: 24px !important;
  background-color: $fortellis_purple !important;
  margin-right: 16px;

  color: $white !important;
  font-family: $Montserrat !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  text-align: center !important;
}

.solution-default-avatar {
  height: 24px !important;
  width: 24px !important;
  margin-right: 16px;
}
/* =============== eo solution-management - subscriptions ================ */

.nowrap {
  white-space: nowrap !important;
}

.textAlign-right {
  text-align: right !important;
}
.textAlign-left {
  text-align: left !important;
}

.textAlign-center {
  text-align: center !important;
}

.dialogbtn-txt {
  width: fit-content;
  left: 470px;
}

.inline-block {
  display: inline-block !important;
}

.fortellis-title-1 {
  font-family: $Montserrat;
  opacity: 0.87;
  font-size: 60px;
  font-weight: 500;
  line-height: 73px;
  letter-spacing: -0.5px;
}

.fortellis-title-2 {
  font-family: $Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 46px;
}

.fortellis-title-2-1 {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
}

.fortellis-title-3 {
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
}

.fortellis-title-4 {
  font-family: $Raleway;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 32px;
}

.fortellis-title-5 {
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
}

.fortellis-subtitle-1 {
  font-family: $Raleway;
  font-size: 16px;
  letter-spacing: 1.46px;
  line-height: 27px;
  font-weight: normal;
}

.fortellis-subtitle-2 {
  font-family: $Raleway;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}

.summary-info-card {
  min-height: 100%;
  background-color: $fortellis_grey;
  flex: 1;
  padding: 37px 25px 0px 23px;
}

.summary-info-card-subtitle {
  color: #202632;
  margin-top: 19px;
}

.summary-info-card-footer {
  margin: 2em 0 0 0;
}

.fortellis-badge {
  padding: 5px 9px;
  margin-left: 16px;
  display: inline-block;

  color: $white;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;

  border-radius: 2px;
  background-color: $fortellis_badge_grey;
}

.published-solutions-title,
.registered-solutions-title {
  margin-bottom: 16px;
}

.published-history,
.last-published {
  margin-top: 23px;
}

.non-published .button-border {
  border: 1px solid $fortellis_purple;
}

.payment-method-card-icon {
  font-size: 32px !important;
}

.payment-method-card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-family: 'Montserrat';
  font-size: 20px;
  letter-spacing: 0.15px;
}

.payment-method-card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin: 32px 0px 0px 0px;
}

.payment-method-card-label {
  color: $fortellis_light_gray;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.payment-method-card-value {
  color: #151b25;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.manage-listing-header {
  display: flex;
  flex-direction: row;
}

.manage-listing-header-title,
.manage-listing-header-options {
  flex: 1;
  float: right;
}

.manage-listing-header-options.verify-ach {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.manage-listing-header-options {
  margin: 33px 24px 0px 0px;
}

.manage-listing-header-options > div {
  float: right;
}

.manage-listing-header-options span {
  color: $black;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  opacity: 0.87;
  text-align: right;
}

.summary-info-card-subtitle2 span {
  font-weight: bold;
}

.fortellis-color {
  color: $fortellis_purple !important;
}

.commonSelectfield {
  min-width: 220px !important;
}

.whitespace-24px {
  display: inline-block;
  width: 24px;
}

.lead-filters {
  .mdc-select {
    .mdc-floating-label {
      color: $fortellis_light_gray !important;
      font-family: $Raleway !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      letter-spacing: 0.2px !important;
      line-height: 14px !important;
    }

    .mdc-floating-label--float-above {
      -webkit-transform: translateY(-100%) scale(1);
      -ms-transform: translateY(-100%) scale(1);
      transform: translateY(-100%) scale(1);
      margin-bottom: 10px;
    }
  }
}

.review-filters {
  margin-bottom: 10px;
  display: inline-block;
  float: right;
}

.lead-filters {
  margin-bottom: 20px;
}

.review-filter-container {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
}

.lead-search {
  padding-left: 0px;
}

.lead-search-parent {
  width: 280px;
  float: left;
  margin-top: 8px;
  margin-left: 16px;
}

.listing-filters-parent {
  margin-top: 16px;
  margin-left: 24px;
}

.lead-search input {
  padding-left: 30px !important;
}

.solution-type-list {
  margin-top: 16px;
}

.published-list {
  margin-top: 40px;
}

.invoice-address-form-field {
  display: inline-block;
  margin-left: 0px;
  width: 32%;
  height: 80px;
}

.invoice-address-form-field-half {
  display: inline-block;
  margin-left: 0px;
  margin-right: 20px;
  width: 45%;
  height: 80px;
}

.invoice-address-form-field-text {
  width: 100%;
}

.invoice-dialog-body {
  overflow: visible;
  height: 290px;
}

.invoice-billing-address-form .form-field-with-tooltip .cdk-tooltip--show {
  top: auto !important;
  left: auto !important;
}

//Breadcrumb Realated CSS Starts Here

.breadcrumb-page-title-cell {
  position: relative;
  top: -27px;
  height: 5px;
}

.breadcrumb-page-grid {
  padding: 20px 0 0 0 !important;
}

.breadcrumb-background {
  background-color: $white;
}

.breadcrumb-max-width {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.breadcrumb-parent {
  display: inline-flex;
}

.breadcrumb-back-link {
  display: flex;
}

.breadcrumb-page-back-title-text-active {
  color: $fortellis_light_gray;
  font-family: $Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.breadcrumb-page-back-title-text {
  color: $fortellis_light_gray;
  font-family: $Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.breadcrumb-page-back-title-arrow {
  position: relative;
  color: $fortellis_light_gray;
  top: 20px;
  padding-right: 8px;
  padding-left: 8px;
  cursor: pointer;
}

//Breadcrumb Realated CSS Ends Here

.subscription-management-card-body {
  background-color: $fortellis_grey;
}

.subscription-management-card-item {
  height: 196px;
  margin: 0px 24px 24px 24px;
  padding: 16px;
  background-color: $white;
}

.subscription-management-card-item-header {
  height: 56px;
  background-color: $white;
}

.subscription-management-card-item-title {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.subscription-management-card-item-title-status {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  margin-right: 16px;
}

.subscription-management-card-item-body {
  height: 56px;
  color: $Navlink_border;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 24px;
}

.sol-management-table {
  max-height: 600px;
  overflow: auto;
}
.subscription-management-card-item-footer {
  padding: 16px 0px;
}

.subscription-management-subscription-active-button {
  margin: 0px 24px 20px 24px !important;
}

.subscription-management-subscription-active-spinner {
  margin: 0px 24px 20px 24px !important;
}

.subscription-management-card-footer {
  margin: -16px 0px 16px 32px;
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.subscription-management-form-title {
  margin-bottom: 32px;
}

.subscription-entity {
  margin-bottom: 24px;
}

.subscription-entity-expansion-panel-header {
  height: 80px !important;
}
.subscription-entity-expansion-panel-header-title {
  width: 100%;
}

.subscription-entity-expansion-panel-header-title-info {
  display: inline-block;
}

.subscription-entity-expansion-panel-header-logo {
  height: 58px !important;
  width: 66px !important;
  margin-right: 16px;
  background-color: $fortellis_purple;
  color: $white;
}
.subscription-entity-expansion-panel-header-logo-text {
  text-transform: uppercase;
}
.subscription-entity-expansion-panel-header-status {
  margin-top: 16px;
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
}

.subscription-entity-expansion-panel-body {
  margin-left: 16px;
}

.subscription-entity-expansion-panel-body-title {
  margin: 16px;
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}

.subscription-entity-expansion-panel-body-fields {
  margin: 16px;
}

.subscription-entity-expansion-panel-body-field {
  display: inline-block;
  margin-right: 128px;
}

.subscription-entity-expansion-panel-body-field-title {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.subscription-entity-expansion-panel-body-field-content {
  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.subscription-entity-expansion-panel-body-field-content-url {
  color: $fortellis_purple;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

#preview-dialog .mdc-dialog__surface {
  max-width: 80%;
  max-height: 80%;
  margin-top: 20px;
  padding: 10px;
}

.complete-provisioning-dialog .mdc-dialog__surface {
  width: 560px !important;
}

.subscription-provisioning-completion-comment {
  display: inline-block;
  margin-bottom: 16px;
}

.complete-provisioning-text {
  margin-top: 16px;
}

.subscription-management-plan-details {
  margin-bottom: 40px;
}
.subscription-management-plan-details-header {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
}

.subscription-management-plan-details-body {
  margin-top: 16px;
}

.subscription-management-plan-name {
  color: $fortellis_black;
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.subscription-management-plan-availability {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
}

.subscription-management-plan-feature {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  align-items: center;
  display: flex;
  margin-top: 8px;
}

.subscription-management-plan-feature-icon {
  color: $green;
  font-size: 20px;
  line-height: 32px;
}

.subscription-management-plan-feature-text {
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 8px;
}

.subscription-api-config-card-body {
  background-color: $fortellis_grey;
  padding: 0px 24px 24px 24px;
}

.subscription-management-billing-details {
  margin: 0px 24px 24px 24px;
  padding: 24px 8px 0px 24px;
  background-color: $white;
}

.subscription-management-billing-details-price {
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
  text-align: center;
}

.subscription-management-billing-details-unit {
  color: $fortellis_dark_gray;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-align: right;
}

.subscription-management-billing-details .cdk-data-table td {
  border: none;
}

.insight-plan-report .cdk-data-table tbody tr {
  height: 72px;
}

.insight-plan-report .cdk-data-table thead tr {
  height: 54px;
}

.insight-plan-report .cdk-data-table--hover tr {
  background-color: $white !important;
}

.insight-plan-report .cdk-data-table--hover tr {
  background-color: $white !important;
}

/*---------------------------------admin-all-subcriptions-------------------------------------*/

.admin-subcriptions-title-container {
  margin-bottom: 28px !important;
  overflow-x: visible;
}
/*--------------------------------eo admin-all-subcriptions-----------------------------------*/

.subscription-list-chip-parent {
  margin-bottom: 24px;
  //IE-11 specific
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top: 24px;
  }
}

.subscription-management-complete-message-divider {
  margin: 40px 0px 0px 0px;
}

.subscription-management-billing-details .cdk-data-table thead th {
  padding-bottom: 0px;
}

.subscription-management-billing-details .cdk-data-table td {
  padding-top: 0px;
}

.subscription-billing-card-parent-bg {
  background-color: $white;
  margin: 8px 24px 24px 24px;
}

.subscription-billing-card-parent {
  margin: 42px 16px 0px 24px;
}
.subscription-billing-card-divider {
  margin-top: 48px;
}

.subscribed-plan-details-parent {
  margin-top: 16px;
}

.plans-pricing-entity-details-parent {
  margin-bottom: 24px;
}

.provisioning-billing-details-container {
  border: solid 1px;
  margin-right: 24px;
  border-radius: 8px;
  padding: 24px 24px 8px 24px;
  border-color: $border;
}

.subscription-management-billing-details-content {
  padding-bottom: 24px;
}

.insights-dashboard {
  margin-top: 48px;
  margin-bottom: 48px;
}

.insights-dashboard-item {
  display: inline-block;
  box-sizing: border-box;
  height: 113px;
  width: 33.33%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px 0 0 2px;
  background-color: $white;
}

.insight-plan-report table {
  box-shadow: none;
}

.insights-dashboard-item-left {
  border-radius: 4px 0px 0px 4px;
}
.solutioncount-dashboard {
  margin-top: 20px;
}

.insights-dashboard-item-right {
  border-radius: 0px 4px 4px 0px;
}

.insights-dashboard-item-label {
  margin-top: 16px;
  text-align: center;
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 14px;
}

.insights-dashboard-item-value-container {
  text-align: center;
}

.insights-dashboard-item-value-container button {
  height: unset;
}

.insights-dashboard-item-value-button {
  text-align: center;
  font-family: $Montserrat;
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
}

.insight-usage-value {
  height: 32px;
  width: 28px;
  opacity: 0.87;
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  text-align: right;
  display: inline-block;
}

.insight-plan-title {
  text-align: left;
}

.app-activation-subscriber-title {
  text-align: left;
}

.app-activation-date-title {
  text-align: left;
  margin-left: 40%;
}

.app-activation-subscriber-text {
  height: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 16px;
  text-align: left;
}

.app-activation-date-text {
  height: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 16px;
  margin-left: 40%;
  text-align: left;
}

.app-activation-status-text {
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: right;
}

.app-activation-help-text {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 8px;
}

.app-activation-link {
  color: #5e2368;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
}

.app-activation-subscriptions-header {
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: 20px;
}

.app-activation-subscriptions {
  min-height: 350px;
}

.app-activation-subscriptions table {
  box-shadow: none;
}

.app-activation-subscriptions .cdk-data-table tbody tr {
  height: 56px;
}

.app-activation-subscriptions .cdk-data-table thead tr {
  height: 54px;
}

.app-activation-subscriptions .cdk-data-table--hover tr {
  background-color: $white !important;
}

.app-activation-subscriptions .cdk-data-table--hover tr {
  background-color: $white !important;
}

.app-activation-denied-status {
  color: #cc0000;
}

.app-activation-active-status {
  color: #1e8700;
}

.app-activation-ruler {
  margin-top: 15px;
  margin-bottom: 0px;
}

.app-activation-solution-header-auto {
  height: 18px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 12%;
}

.app-activation-no-subscriber {
  margin-top: 15px;
}

.entity-reg-confirmation-page-container {
  min-height: calc(100vh - 80px - 372px); //100vh-header-footer
  background-color: $white;
  padding-bottom: 1px;
  text-align: center;
}

.entity-reg-confirmation-page-content-text {
  font-family: $Raleway;
  font-size: 24px !important;
  color: $Navlink_border !important;
  font-weight: 500 !important;
}

.entity-registration-redirect-btn {
  margin-top: 24px;
}

.entity-registration-message {
  margin-top: 24px;
}

.form-url-test-button-container {
  display: inline-block;
  margin-left: 16px;
  float: right;
  margin-top: 30px;
}

.form-url-field {
  display: inline-block;
  width: 86%;
}

.form-url-test-success {
  color: $green !important;
}

.api-info-subtitle {
  color: rgba(0, 0, 0, 0.54);
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.loading-api-details {
  text-align: center;
}

.apiInfo-table-body-api-info a {
  color: $fortellis_purple !important;
}

.apiInfo-subtitle {
  position: static;
  width: 898px;
  height: 40px;
  left: 32px;
  top: 32px;
  font-family: $OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(21, 27, 37, 0.701961);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 32px 0px;
}

.apiInfo-table-body-api-info {
  position: static;
  padding-top: 10px;
  width: 267.33px;
  // height: 20px;
  height: auto;
  left: 0px;
  top: 0px;
  font-family: $OpenSans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #151b25;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 0px;
}

.apiInfo-table-body-providers {
  position: static;
  padding-top: 10px;
  width: fit-content;
  height: 40px;
  left: 0px;
  top: 0px;
  font-family: $OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #151b25;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 0px;
}

.api-info-table {
  margin-top: 32px;
  overflow-x: auto;
}

.api-info-table-body-api-without-desc {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 40px;
  overflow: hidden;
  max-width: 245px;
  text-overflow: ellipsis;
}

.api-info-table-body-api {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 24px;
  overflow: hidden;
  max-width: 215px;
  text-overflow: ellipsis;
}

.api-info-table-body-api a {
  color: #904778;
}

.api-info-table-body-description {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 16px;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.api-info-table-body-description-column {
  margin-bottom: 8px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.api-info-table-body-providers-item {
  margin-bottom: 16px;
  font-family: $OpenSans;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 16px;
}

.api-info-table-body-providers-item-solution-submission {
  margin-bottom: 8px;
  margin-top: 8px;
  font-family: $OpenSans;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  overflow: hidden;
  max-width: 215px;
  text-overflow: ellipsis;
}

.api-info-table-body-providers-consent {
  display: flex;
  text-align: left;
  cursor: pointer;
  line-height: 32px;
}

.providers-consent-transparent {
  opacity: 0;
}

.consent-status-icon {
  font-size: 15px !important;
  margin-right: 8px;
  line-height: 32px;
}

.empty-div-for-consent {
  height: 32px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.edit-pricing-plans {
  color: darkgray;
  font-size: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.api-info-table table {
  box-shadow: none;
  white-space: normal;
}

.api-info-table .cdk-data-table--hover tr {
  background-color: $white !important;
}

.api-info-table-cell-checkbox {
  td {
    vertical-align: top;
  }
}

.api-info-table-cell-20 {
  text-align: left !important;
  width: 20%;
  vertical-align: top !important;
}

.api-info-table-cell-25 {
  text-align: left !important;
  width: 25%;
  vertical-align: top !important;
}

.api-info-table-cell-50 {
  text-align: left !important;
  width: 50%;
  vertical-align: top !important;
}

.api-info-table-cell-60 {
  text-align: left !important;
  width: 60%;
  vertical-align: top !important;
}

.solution-registration-error {
  color: $fortellis_error_red;
}

.image-container-image {
  height: 111px;
  width: 198px;
  background-size: cover;
}

.form-text-area-error {
  color: $fortellis_error_red !important;
}

.process-grid {
  .mdc-layout-grid {
    padding: 0px;
    /*Added from ie11 support for sell page*/
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.icon-bar-container {
  .mdc-layout-grid {
    padding: 0px;
  }
}

.icon-sell-divider-line {
  height: 1.09px;
  display: inline-block !important;
  background-color: rgba(0, 0, 0, 0.24);
  width: calc(100% - 114px);
  margin-left: 48px;
}

@media screen and (max-width: 880px) {
  .icon-bar-container {
    display: none;
  }
}

.my-solution-list-header {
  padding: 24px 24px 24px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.in-developement-sol-loading {
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-desktop {
    width: 100% !important;
  }
}

.subscription-api-config-card-body .cdk-data-table--hover tr {
  background-color: $white !important;
}

.subscription-spec-status {
  text-align: right !important;
  padding-right: 0px !important;
}

.character-remaining-div {
  line-height: 14px;
}
/* ===== 16:9 image container =====*/

.image-wrapper-16_9 {
  position: relative;
  padding-bottom: 56.2%; //for 16-9 ratio
  text-align: justify; //to nullify effect of textalign:center from parent if any
}

.image-wrapper-16_9 img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
/* ===== eo 16:9 image container =====*/

.spinner-container {
  min-height: calc(100vh - 80px - 372px);
  background-color: $white;
}

.complete-provisioning-loading {
  padding-top: 136px;
  padding-bottom: 136px;
}

li.mdc-list-item.active {
  background-color: $fortellis_purple_selected !important;
}

.side-nav-item.mdc-list-item {
  margin: 0 !important;
  text-overflow: ellipsis !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  &--activated {
    background-color: #904778;
    color: white !important;
  }
}
.drawerContent {
  .mdc-drawer__header {
    min-height: 0;
  }
}

.fmk-cover {
  background: url('./components/common/landingpage-bg.jpg');
  background-size: cover;
  box-sizing: border-box;
  font-family: $Raleway;
  min-height: 100vh;
  padding-top: 197px;

  &--mobile {
    padding-top: 90px;
  }

  &__headline {
    max-width: 816px;
  }

  &__text {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 17px;
  }

  &__title {
    font-size: 90px;
    line-height: 110px;
    font-family: $Montserrat;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 24px;
    letter-spacing: 5.9px;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 42px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 42px;
  }

  &__cta-buttons {
    margin-bottom: 179px;
    a + a {
      margin-left: 50px;
    }
  }
}

@media (max-width: 1039px) {
  .fmk-cover {
    &__headline {
      width: inherit;
      text-align: center;
    }
    &__title {
      font-size: 45px;
      line-height: 70px;
      letter-spacing: 5.9px;
    }
    &__subtitle {
      font-size: 18px;
      font-weight: 300;
      line-height: 22px;
    }
    &__cta-buttons {
      a,
      button {
        width: 75%;
      }
      a + a,
      a + a > button {
        margin-top: 25px;
        margin-left: 0px;
      }
    }
  }

  .fmk-page {
    &__header {
      margin: 10px 16px;
    }

    &__body {
      margin: 32px 16px;
      width: inherit;
    }
  }
}
/** responsive styles */
/** responsive styles */
@media (max-width: 1039px) {
  .fmk-content {
    &__action {
      align-self: flex-start !important;
      margin-top: 8px;
    }

    &__header {
      flex-direction: column;
    }

    &__empty-area {
      .mdc-typography--subtitle1 {
        padding: 0 8px;
      }
    }
  }
}

.header-expansion-indicator i.cdkicon-expand_more:before {
  content: '\e5cf';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  outline: 0;
  color: rgba(0, 0, 0, 0.38);
}

.formFields .mdc-list-item {
  &__text {
    color: #424242;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
  }
  &__secondary-text {
    font-weight: 400;
    letter-spacing: 0.01786em;
    text-decoration: inherit;
    text-transform: inherit;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    font-family: Roboto, Arial, sans-serif;
  }
}

.mdc-select:not(.mdc-select--disabled).mdc-select--invalid
  label.mdc-floating-label {
  .form-field-label {
    color: #b00020;
  }
}

.frc-file-input {
  display: flex;
}
.frc-file-input__item {
  &--button {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 16px;
    height: 56px;
  }
  &--full-width {
    width: 100%;
  }
}
.frc-file-input__input {
  display: none;
}

.mdc-text-field__input {
  &.frc-file-input__text-field-input {
    padding-top: 12px !important;
  }
}

.mdc-text-field-helper-text--validation-msg {
  &.mdc-text-field-helper-text--persistent {
    &.mdc-text-field-helper-text {
      color: $fortellis-system-negative !important;
    }
  }
}

.frc-file-input__text-field {
  &.mdc-text-field--disabled {
    &.mdc-text-field__input {
      color: rgba(0, 0, 0, 0.6) !important;
    }
    &.mdc-text-field--invalid {
      .mdc-floating-label,
      .mdc-line-ripple {
        color: $fortellis-system-negative;
      }
      .mdc-text-field__input {
        border-bottom-color: $fortellis-system-negative;
      }
      &.mdc-text-field--with-trailing-icon {
        .frc-file-input__text-field-icon {
          color: $fortellis-system-negative;
        }
      }
    }
    &.mdc-text-field--with-trailing-icon {
      .frc-file-input__text-field-icon {
        pointer-events: auto;
      }
    }
  }
}
.frc-file-input__text-field-icon {
  left: 105% !important;
}
.frc-file-input__button--success,
.frc-test-button--success {
  &.mdc-button:disabled {
    color: $fortellis-system-positive;
    &.mdc-button::before,
    &.mdc-button::after {
      background-color: transparent;
    }
  }
}
.frc-test-button--failure {
  &.mdc-button:disabled {
    color: $fortellis-system-negative;
    &.mdc-button::before,
    &.mdc-button::after {
      background-color: transparent;
    }
  }
}

.file-upload-spinner {
  display: flex;
  align-self: center;
}

.providerConsentDialog {
  .consent-input-container {
    display: grid;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.spinnerDialog .mdc-dialog__surface {
  //position: fixed;
  // max-width: 340px;
  //max-width: 382px;
  @media (min-width: 592px) {
    max-width: 340px;
  }
}

.util-expand-panel .cdk-expansion-panel--expanded {
  background: #f0f0f0 !important;
}
.util-expand-panel
  .cdk-expansion-panel-group
  .cdk-expansion-panel__header:hover {
  background: #f0f0f0 !important;
}

.util-expand-panel .header-description {
  display: flex;
  flex-grow: 1;
  margin-right: 32px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 15px;
}
.util-expand-panel .cdk-expansion-panel__header .header-title {
  display: flex;
  flex-basis: 55%;
  flex-direction: column;
  margin-right: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
}

.utilization-dashboard-item {
  display: inline-block;
  box-sizing: border-box;
  height: 113px;
  width: 33.33%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px 0 0 2px;
  background-color: $white;
}

.utilization-dashboard {
  margin-top: 48px;
  margin-bottom: 48px;
}

.utilization-dashboard-item {
  display: inline-block;
  box-sizing: border-box;
  height: 113px;
  width: 33.33%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px 0 0 2px;
  background-color: $white;
}

.utilization-plan-report table {
  box-shadow: none;
}

.utilization-dashboard-item-left {
  border-radius: 4px 0px 0px 4px;
}

.utilization-dashboard-item-right {
  border-radius: 0px 4px 4px 0px;
}

.utilization-dashboard-item-label {
  margin-top: 16px;
  text-align: center;
  color: $fortellis_light_gray;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 14px;
}

.utilization-dashboard-item-value-container {
  text-align: center;
}

.utilization-dashboard-item-value-container button {
  height: unset;
}

.utilization-dashboard-item-value-button {
  text-align: center;
  color: $fortellis_purple;
  font-family: $Montserrat;
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
}

.solutions-search-box-div {
  height: 40px;
  max-width: 100%;
  min-width: 330px;
  border: 1px solid $white;
  border-radius: 50px;
  margin-left: auto;
  border-color: rgba(0, 0, 0, 0.54);
}
.solution-search-input {
  height: 26px;
  border: 0px;
  width: 275px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  margin-left: 10px;
  margin-top: 5px;
  background: transparent;
  border-radius: 50px;
}
.solution-search-input:focus {
  outline: none;
  outline-offset: initial;
}

.status-card-chips-div {
  width: 100%;
  display: flex;
}

@import '@fortellis/style/variables';
@import '@fortellis/style/mixins';
@import '@material/theme/mdc-theme.scss';
@import '@material/react-typography/index.scss';

@import '@material/react-button/index.scss';
@import '@material/react-card/index.scss';
@import '@material/react-checkbox/index.scss';
@import '@material/react-chips/index.scss';
@import '@material/react-dialog/index.scss';
@import '@material/react-drawer/index.scss';
@import '@material/react-icon-button/index.scss';
@import '@material/react-list/index.scss';
@import '@material/react-material-icon/index.scss';
@import '@material/react-menu-surface/index.scss';
@import '@material/react-radio/index.scss';
@import '@material/react-ripple/index.scss';

@import '@fortellis/expansion-panel/expansion-panel.scss';
@import '@fortellis/flex/flex.scss';
@import '@fortellis/logo/logo.scss';
@import '@fortellis/typography/typography.scss';

@import '@fortellis/style/index.scss';
@import '@fortellis/header/header.scss';
@import '@fortellis/footer/footer.scss';

.spinnerDialog .mdc-dialog__surface {
  //position: fixed;
  // max-width: 340px;
  //max-width: 382px;
  @media (min-width: 592px) {
    max-width: 340px;
  }
}

.MuiFormLabel-root {
  color: $fortellis_light_gray !important;
  font-size: 0.75rem !important;
}

.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.75) !important;
  color: $fortellis_purple !important;
  transform-origin: top left;
}

.MuiInput-underline:after {
  border-bottom: 1.5px solid $fortellis_purple !important;
}
.MuiInput-underline:focus {
  border-bottom: 1.5px solid $fortellis_purple !important;
}
.MuiInput-underline {
  border-bottom-color: $fortellis_grey !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1.5px solid $fortellis_purple !important;
}
.margin-top-10px {
  margin-top: 10px !important;
}

.frc-file-input__item--button .mdc-button .mdc-button__icon {
  margin-left: -9px;
}

.fullScreenOverlay_dialog {
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 0;
  background: white;
  z-index: 1;
  animation: rollUp 0.3s forwards;
  -webkit-animation: rollUp 0.3s forwards;
}

@keyframes rollUp {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

.fullScreenOverlay_title {
  font-size: 1.25rem;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: white;
}

.fullScreenOverlay_backButton {
  color: white !important;
}

.fullScreenOverlay_appBar {
  position: fixed;
  min-height: 64px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  width: 100%;
}

.fullScreenOverlay_content {
  margin-top: 64px;
}

#developerAgreement-dialog .mdc-dialog__surface {
  max-width: 80vw;
  max-height: 90vh;
}

.developerAgreement__dialogHeader {
  padding: 0px;
}

.developerAgreement__spinner {
  width: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.developerAgreement__frame {
  width: 100%;
  height: 600px;
}

a.solution-review__developerAgreement {
  cursor: pointer;
  padding-left: 16px;
  color: #904778;
  font-size: 13px;
}

.App__signingPreviewComplete {
  margin: 200px auto;
  width: 20%;
}

.developerAgreement__zoomIn {
  transform: scale(1.4);
  transform-origin: 70% 0;
}

.developerAgreement__zoomOut {
  transform: scale(1);
  transform-origin: 70% 0;
}

.developerAgreement_zoomButtons {
  cursor: pointer;
  padding: 5px;
  font-size: 26px;
}

.developerAgreement_closeButton {
  cursor: pointer;
  padding: 5px;
  font-size: 26px;
}

.developerAgreement_controlButtons {
  float: right;
}

.developerAgreement__dialogBody {
  background: lightgray;
  transition: transform 0.2s;
}

.terms-of-use-subtitle {
  margin-top: 10px;
  font-weight: 400;
  letter-spacing: -0.5px;
  font-size: 16px;
}

.terms-of-use-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 5px;
}

.terms-of-use-font {
  font-family: $terms-of-use-header-text-font;
}

.terms-of-use-a {
  font-weight: 600;
}

.terms-of-use-consent-type {
  margin-top: 40px;
  font-weight: 600;
  font-size: 20px;
}

.terms-of-use-types {
  font-weight: 500;
  font-size: 16px;
}

.terms-of-use-types-description {
  margin-left: 40px;
  font-weight: 400;
  font-size: 14px;
}

.terms-of-use-file-selector {
  margin-top: 20px;
}

.admin-dealer-consent-file-selector {
  margin-top: 20px;
  display: inline;
}

.admin-dealer-consent-api-container {
  max-height: 40vh;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 10px 10px;
  margin-top: 75px;
}

.all-apis-container {
  overflow-y: auto;
  max-height: 30vh;
}

.select-all-apis-checkbox {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.select-api-checkbox {
  margin-bottom: 3px;
}

.select-api-checkbox-disabled {
  margin-bottom: 3px;
  cursor: no-drop !important;
}

.api-checkbox {
  padding: 10px 10px 5px 10px;
}

.disabled-checkbox {
  cursor: no-drop;
}

.enabled-checkbox {
  cursor: pointer;
}

.admin-dealer-consent-data {
  width: 80%;
  padding-right: 0px;
  margin-block-end: 0px;
}

.terms-of-use-cancel-save {
  padding: 50px 0 80px 0;
  float: right;
}

.terms-of-use-upload-title {
  margin-top: 10px;
  font-weight: 400;
  letter-spacing: -0.5px;
  font-size: 16px;
}

.terms-of-use-show-types-button {
  margin-top: 20px;
  border: 2px solid #904778;
  border-radius: 5px;
}

.terms-of-use-navigate-back-container {
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
}

.terms-of-use-navigate-back-div {
  height: 40px;
}

.terms-of-use-back-button {
  padding: 5px;
}

.terms-of-use-icon {
  color: rgba(0, 0, 0, 0.54);
}

.terms-of-use-navigate-back-text {
  margin-top: 3px;
}

.capture-coordinates-header-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 56px;
}

.terms-of-use-header-text {
  color: $terms-of-use-header-text;
  font-family: $terms-of-use-header-text-font;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
}

.capture-coordinates-fields-container {
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.capture-coordinates__field {
  display: inline-block;
  border: 0.5px solid #f3c118;
  background: $capture-coordinates__field;
  border-radius: 10%;
  position: absolute;
  z-index: 1;
  width: 50px;
  height: 50px;
}

.capture-coordinates__sign {
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 500;
}

.capture-coordinates__sign_icon {
  display: flex;
  justify-content: center;
}

.capture-coordinates__sign_icon img {
  width: 70%;
}

.fullscreen-children-alignment {
  margin-top: 60px;
}

.capture-coordinates-button {
  margin-left: 5px;
  color: $capture-coordinates-button;
  border-radius: inherit;
}

.capture-coordinates-no-border-button {
  margin-left: 5px;
  color: $capture-coordinates-no-border-button;
}

.capture-coordinates-pdf-container {
  background: $capture-coordinates-pdf-container;
}

.capture-coordinates-pdf-body {
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100vw;
}

.pdf-page-enter {
  opacity: 0.5;
}

.pdf-page-enter-active {
  opacity: 1;
}

.pdf-page-exit {
  opacity: 1;
}

.pdf-page-exit-active {
  opacity: 0.5;
}

.capture-coordinates__buttons {
  padding-left: 18px;
}

.react-pdf__Document {
  padding: 80px;
  display: grid;
  justify-content: center;
  padding-top: 10px;
}

.preview__container {
  margin-top: 60px;
}

.preview__frame {
  width: 100%;
  height: 1000px;
}

.preview__footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: whitesmoke;
  height: 120px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.6);
}

.preview__footer div {
  height: 24px;
  font-weight: 600;
  margin: 10px;
  font-size: 20px;
  line-height: 24px;
  align-content: center;
}

.preview__footer__button__container {
  display: block;
  padding: 10px;
}

.preview__footer__button__returnToEdit {
  border: 2px solid #904778;
  border-radius: 5px;
  width: 260px;
}

.preview__footer__button__acceptTerms {
  margin-left: 30px;
  width: 260px;
}

.capture-coordinates__currentPage {
  font-family: $capture-coordinates__currentPage_font;
  margin-left: 10px;
  letter-spacing: 3px;
}

.capture-coordinates__page_navigator_button {
  background: $capture-coordinates__page_navigator_button;
  border-radius: 50%;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 36px;
  width: 36px;
}

.capture-coordinates__page_navigator_button i {
  font-size: 36px;
  color: $capture-coordinates__page_navigator_button_i;
}

.capture-coordinates__navigation_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.capture-coordinates__navigation_container_div {
  margin: 20px;
}

.spinnerWithText {
  height: 70px;
  width: 300px;
}

#spinnerWithTextDialog {
  overflow: hidden;
}

.spinnerWithText__h3 {
  display: inline;
  padding: 10px;
  vertical-align: top;
}

.capture-coordinates__fillable_panel {
  overflow: scroll;
  height: 92vh;
  padding-top: 5px;
  background: $capture-coordinates-pdf-container;
}

.capture-coordinates-button__vibrate_signature_coordinates {
  transform: translate3d(0, 0, 0);
  animation: shake 0.3s forwards;
  -webkit-animation: shake 0.3s forwards;
}

.capture-coordinates__delete_button {
  background: transparent;
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  padding: 0px;
  border-radius: 50%;
  cursor: pointer;
}

.capture-coordinates__delete_button img {
  height: 16px;
}

.capture-coordinates__field_container {
  display: block;
  margin: 10px;
}

.capture-coordinates__signature_icon {
  width: 36px;
  height: 20px;
  margin-left: 10px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
    border: $capture-coordinates-button-border;
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
    border: $capture-coordinates-button-border;
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
    border: $capture-coordinates-button-border;
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
    border: $capture-coordinates-button-border;
  }

  100% {
    border: none;
  }
}

.persistent-search-box-div {
  & .mdc-text-field.persistent-search-box {
    margin: 0;
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-fg-scale: 0;
    --mdc-ripple-fg-translate: none;
    font-family: $Raleway;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.25;
    letter-spacing: 0.1px;
    text-align: left;
    height: 48px;
    box-sizing: border-box;
    width: 100%;
    border: 0;

    border-radius: 4px;
    .mdc-text-field__bottom-line,
    .mdc-line-ripple {
      display: none;
    }
    .mdc-text-field__label {
      padding: 0;
      margin-bottom: -18px;
    }
    .mdc-text-field__input {
      padding: 0 16px 0 32px;
      border: 0;
    }
    .mdc-text-field__icon:first-of-type {
      bottom: 12px;
      left: 16px;
      height: 24px;
      width: 24px;
    }
    .mdc-text-field__icon:last-of-type {
      bottom: 12px;
      right: 16px;
      height: 24px;
      width: 24px;
    }

    &.primary {
      .mdc-text-field__input {
        background-color: $white;
        padding-left: 7px;
      }
    }

    ::placeholder {
      color: $fortellis_light_gray5 !important;
      opacity: 1;
      font-family: $Raleway;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.1px;
      line-height: 24px;
    }
  }

  .cdk-persistent-search {
    &__container {
      @include mdc-elevation(2);
      width: 99%;
      margin-left: 2px;
      margin-right: 2px;

      .mdc-text-field--box:hover::before,
      .mdc-text-field--box:not(.mdc-ripple-upgraded):focus::before,
      .mdc-text-field--box:not(.mdc-ripple-upgraded):focus-within::before,
      .mdc-text-field--box.mdc-ripple-upgraded--background-focused::before {
        opacity: 0;
      }
    }

    &__result-container {
      border-top: 1px solid $border;
      border-left: 0.5px solid $border;
      border-right: 0.5px solid $border;
      border-bottom: 1px solid $border;
      background-color: $white;
      border-radius: 4px;
      max-height: 400px;
      overflow: scroll;
      z-index: 2;
      position: absolute;
      width: 100%;
      box-shadow: 0px 2px 1px -2px $border2, 0px 1.5px 2px 0px $border3,
        0px 1px 0px 0px $border;
    }

    &__results {
      cursor: pointer;
      color: $fortellis_black1;
      font-family: $Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }

    &__search-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border-top: 1px solid $border;
      border-left: 1px solid $border;
      border-right: 1px solid $border;
      border-bottom: 1px solid $border;
      border-radius: 4px;
    }

    &__watch-icon {
      display: none;
    }

    &__result-text {
      padding-left: 56px;
    }

    &__back {
      width: 24px;
      height: 24px;
      margin-left: 16px;
      color: $fortellis_light_gray5;
    }

    &__cancel {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      color: $fortellis_light_gray5;
    }
  }

  & .cdk-persistent-search__container {
    box-shadow: 0px 2px 1px -2px $border2, 0px 1.5px 2px 0px $border3,
      0px 1px 0px 0px $border;
    border-radius: 4px;
    position: inherit;
  }

  & .cdkicon-arrow_back:before {
    content: '\E8B6';
    color: $fortellis_text_color3;
  }

  & .cdkicon-search {
    color: $fortellis_text_color3;
  }

  & .cdk-persistent-search__back:after {
    width: 44px;
    height: 24px;
    margin-right: 16px;
  }

  & .cdk-persistent-search__search-bar:focus-within {
    border: 2px solid $fortellis_purple;
  }
}

.circle {
  height: 6px;
  width: 6px;
  background: rgba(0, 0, 0, 0.54);
  border-radius: 100%;
  display: inline-block;
  margin: 1px;
  animation: pulse 1.2s ease-in infinite;
  -webkit-animation: pulse 1.2s ease-in infinite;
}

.circle:nth-of-type(2) {
  animation-delay: 0.2s;
}

.circle:nth-of-type(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.loading {
  padding: 1px;
  margin-top: 4px;
}

#consent-dialog_dialog .mdc-dialog__surface {
  max-width: 80%;
  max-height: 95%;
  padding: 10px;
}

.consent-dialog__dialogHeader {
  padding: 0px 0px 0;
}

.consent-dialog_controlButtons {
  float: right;
}

.consent-dialog_closeButton {
  cursor: pointer;
  padding: 5px;
  font-size: 26px;
}

.consent-dialog__dialogBody {
  background: lightgray;
  transition: transform 0.2s;
  min-height: 80vh;
}

.consent-dialog__spinner {
  min-height: 500px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.consent-dialog__frame {
  width: calc(100% - 10px);
  height: 80vh;
}

.consent_dialog_title_div {
  display: flex;
  background-color: #904778;
  padding: 1% 2%;
}

.consent-dialog_back_btn {
  color: #ffffff;
  margin-top: 5px;
}

.consent-dialog_title {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin-left: 12px;
}

.consent-pdf-container {
  height: 75vh;
  background: url('./assets/freeLoader_white_bg.gif') no-repeat top;
  background-color: #dedede;
  background-position: center;

  li {
    background-color: #dedede;
  }
}

.consent-dialog-footer__container {
  box-shadow: inset 0px 4px 3px lightgrey;
  display: flex;
  justify-content: center;
}

.consent-dialog_footer {
  display: block !important;
  align-self: center;
  padding: 5px;
}

.consent-dialog_footer {
  .consent-dialog_footer_text {
    color: rgba(0, 0, 0, 0.87);
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 24px;
    text-align: center;
  }
}

.consent-dialog_button {
  box-sizing: border-box;
  height: 36px;
  width: 160px;
  border-radius: 4px;
  //background-color: rgba(255, 255, 255, 0);
  margin: 8px;
  color: #904778;
}

.button-border {
  border: 1px solid #904778;
}

.consent-dialog-confirmation {
  .mdc-dialog--open .mdc-dialog__surface {
    padding: 0px 20px;
    margin: auto;
    width: 560px;
    max-width: 800px;
  }
}

#dialogSpinnerWithText .mdc-dialog__surface {
  width: auto;
}

.consent-terms-confirmation-dialog__textField {
  margin-top: 15px;
}

.mdc-select__native-control.mdc-select--invalid.formSelectfield > option {
  color: rgba(0, 0, 0, 0.87);
}

.ProviderPricing__radio {
  cursor: pointer;
}

.online-mode-checkboxes {
  padding-left: 4vw;
  & .check-box-item-checkBox {
    margin-bottom: 5px;
  }
}

.layoutcellRadio-online {
  padding-left: 10px !important;
  margin-top: -10px !important;
}

.offline-checkbox-label {
  height: 24px;
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 0px;
}

.stripe-checkbox,
.cdk-checkbox {
  padding-top: 1.04%;
  width: 25%;
}

.payment-form-caption {
  padding-top: 1.04%;
}

.checkbox-slider-parent {
  display: flex;
  padding: 2%;
}

.revenue-slider {
  display: flex;

  & .check-box-item-label {
    padding-top: 1.04%;
    padding-right: 20px;
  }
}

.revenue-share-container {
  display: flex;
  text-align: center;
}

.revenue-share-item-left {
  width: 25%;
  padding-left: 4vw;
}

span.MuiSlider-root {
  color: #904778;
  padding-bottom: 0px;
  padding-top: 25px;
}

div.MuiGrid-spacing-xs-2 > div.MuiGrid-item {
  padding-bottom: 0px;
  padding-top: 0px;
}

.admin-control-additional-info-container {
  padding: 0px 25px;
}

.additional-info-control-item-div {
  display: flex;
  padding: 20px 10px;
}

.catalog-number-info-container {
  & .additional-info-control-item-label {
    flex: 1;
    margin-top: 40px;
    margin-bottom: auto;
    font-weight: 500;
  }

  & .catalog-number-textfield {
    flex: 2;
    margin-left: 10px;
  }

  & .catalog-number-text-end {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    align-items: center;
    margin: auto;
  }

  & .catalog-number-add-button {
    position: relative;
    bottom: -8px;
  }
  & .character-remaining-div {
    font-size: 12px;
    color: #686868;
    font-weight: 500;
    font-family: Raleway;
    position: relative;
    top: 16px;
  }
}

.catalog-number-chipset-container {
  margin: 24px 0;
}

.catalog-number-input-chipset {
  margin-left: 32%;
}

.catalog-number-show-more {
  margin-left: 12px;
  cursor: pointer;
}

.vision-case-confirmation-container {
  & label {
    flex: 1;
    font-weight: 500;
  }

  & .radio-box-container-for-vison-case {
    flex: 2;

    & .mdc-radio.radio-item-radio {
      display: -ms-inline-grid; //for IE-11 case
      display: inline-grid;
    }
  }
}

.vision-case-queue-info-container {
  padding-top: 0px;
  & .additional-info-control-item-label {
    flex: 1;
    margin-top: 40px;
    margin-bottom: auto;
    font-weight: 500;
  }

  & .vision-case-queue-textfield {
    flex: 2;
    margin-left: 10px;
  }
}

.edit-catalog-and-vision-case-dialog {
  & .mdc-dialog__surface {
    max-width: 50%;
    width: 50%;
  }
}
.admin-apps-option-menu {
  & .mdc-menu.mdc-menu--open {
    left: -105px !important;
    transform-origin: right top !important;
  }
}

//IE-11 specific
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mdc-button.manage-subscription-top {
    display: table-cell;
    vertical-align: middle;
  }
}

// adding changes for moving button from left to right.
.Viewer-Fillable-SaveButton-wrapper {
  right: 4%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .Viewer-Fillable-SaveButton-wrapper {
    right: 14%;
  }
}

//IE-11 specific changes for Capture Cordinatets screen buttons..
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .capture-coordinates__page_navigator_button i {
    margin-left: -10px;
  }
}

/* dot spinner used in FormUploadFile.js */
.consentType__cell {
  .dot-loader__consent:after {
    color: balck;
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }
  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: black;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
    }
  }
}
/* Billing History Date Range and download icons */
.row-container {
  display: inline-grid;
  grid-template-columns: 40% 20% auto;
  width: 100%;
}
.row-container-message {
  display: inline-grid;
  grid-template-columns: auto;
  width: 100%;
}
.row-container-search {
  display: inline-grid;
  grid-template-columns: auto;
  width: 100%;
  padding-bottom: 20px;
  height: 70px;
}
.row-container-message .account-management-panel-header {
  padding: 20px 20px 20px 5px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
}
.max-width-searchBox {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto; //for table overflow in any page
  position: relative;
}

.row-container-search .cdk-persistent-search__container {
  max-width: 400px;
  overflow-x: auto;
  position: absolute;
}

.row-container-search .cdk-persistent-search__result-container {
  z-index: 2;
}

.row-container .DayPicker {
  z-index: 3;
}
.row-container-search .mdc-text-field__input {
  background-color: #ffffff;
  padding: 5px !important;
}
.download_icons {
  padding-top: 24px;
}
.row-item .mdc-button .mdc-button__icon {
  color: #904778;
}

.row-item .mdc-button__label {
  color: #904778;
}
.download_csv_btn {
  margin-left: auto;
  vertical-align: text-bottom;
  margin-top: auto;
}
.row-container .mdc-text-field__input {
  width: 200px;
}
.row-container .cdk-date-picker {
  background-position: right 4px bottom 30px !important;
}
.row-container .DatePickerInput__wrapper {
  padding-top: 15px;
}

a[data-cy='side_menu_Authorized SignOn'] {
  display: none;
}
.subscriptioncaption {
  margin-top: 5px;
}

.app-subscriptions-title-anchor {
  font-weight: 600;
  color: #904778;
}
a:link.app-subscriptions-title-anchor,
a:visited.app-subscriptions-title-anchor {
  color: #904778;
}

.app-activation-subscriptions .cdk-data-table th:last-of-type {
  padding-right: 56px;
}
.payment-checkout-dialog-spinner {
  min-height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0px;
}
.verify-account-link-container {
  font-size: 12px;
  font-family: $Raleway;
  color: $fortellis_purple;
  line-height: 12px;
  text-align: end;
  margin-right: 4px;
  margin-top: -12px;
  font-weight: 600;
}
.another-pay-option-anchor {
  cursor: pointer;
}
.my-soln-list-app-block {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.my-soln-list-app-logo {
  width: 32px;
  height: 32px;
  left: 16px;
  top: 12px;
  border-radius: 4px;
}

.my-soln-list-app-logo.greyed-logo {
  filter: grayscale(100%);
}

.my-soln-list-app-logo.default-app-logo {
  background: #5b5f66;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
}

.my-soln-list-row {
  height: 56px;
  left: 64px;
  top: 72px;
}

.my-soln-page-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 34px !important;
  line-height: 40px !important;
  margin: -4px -4px auto;
  color: #151b25;
  padding: 0px !important;
}

.my-soln-page-content-title-note {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #151b25;
  margin-bottom: 16px;
}

.my-soln-page-content-grid {
  padding-top: 8px !important;
  padding-bottom: 32px !important;
}

/* mySolutionList-radial-change */
.my-soln-row-container-title {
  display: inline-grid;
  grid-template-columns: 70px auto;
  width: 100%;
}

.my-soln-list-table {
  border: 0px;
}

.ag-root-wrapper {
  border: 0px !important;
}

.my-soln-list-app-name-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #151b25;
  padding-left: 8px;
  padding-top: 5px;
}

.my-soln-list-table-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.my-soln-list-table-text.app-description {
  max-width: 250px;
}

.my-soln-list-table-text.app-subscription-type {
  font-size: 12px;
  line-height: 14px;
}

.my-soln-list-table-link {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #5e2368;
  cursor: pointer;
}

.my-apps-error-text {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  color: #5e2368;
}

// #rowMyAppsTitle #rowMyAppsTitleNote{
//   padding: 2px !important;
// }
#mySolnListTableWrapper {
  border: 0px !important;
}

.my-soln-list-table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #151b25;
}

#rowMyAppsTitleNote {
  margin-top: -8px !important;
}

.my-soln-list-table-row {
  height: 56px;
  cursor: pointer;
}

#myAppsSolnTable > tbody > tr:last-child {
  border-bottom: inset !important;
}

.my-soln-list-table-head {
  border: 2px !important;
}

#mySolnListTableWrapper table .sc-qPwPv {
  display: revert !important;
}

.soln-type-text-header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: #151b25;
}

.redirect-to-admin {
  margin-bottom: 10px;
  color: #904778;
  display: flex;
}

.redirect-to-admin-text {
  padding-top: 3px;
  font-weight: 500;
}
.autopayment-div {
  margin-top: 3px;
  margin-bottom: 2px;
}

.my-soln-list-app-logo-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.my-soln-list-app-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Open Sans';
}

.my-soln-list-app-name-mp {
  font-size: 14px;
  font-weight: 600;
}

.my-soln-list-app-name-dnp {
  font-size: 11px;
  font-weight: 400;
}

.payment-history-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
}

.payment-history-title {
  color: #151b25;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.payment-history-caption {
  color: rgba(21, 27, 37, 0.7);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.payment-history-text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.payment-history-amount-tooltip {
  font-weight: 600;
  color: rgba(21, 27, 37, 0.702);
  text-align: right;
  width: 130px;
}

.delete_bank_dialog > div:nth-child(2){
  display: flex !important;
}