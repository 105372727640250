.denied-activation-dialog-help-text {
  height: 14px;
  color: rgba(0,0,0,0.6);
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 14px;
  margin-left: 10px;
  margin-top: 4px;
  margin-bottom: 20px;
}

.app-activation-subscription-div .mdc-dialog__header__title {
    height: 24px;
    color: rgba(0,0,0,0.87);
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 24px;
}

.app-activation-subscription-div .mdc-dialog__body {
    padding-bottom: 0px;
    margin-top: 0px;
}

.denied-activation-dialog-textArea {
    width: 520px;
}

.app-activation-subscription-div .mdc-text-field--textarea .mdc-floating-label {
    left: 10px;
    right: 0px;
}

.app-activation-subscription-div .mdc-text-field .mdc-text-field__input {
    padding-top: 0px;
}

.app-activation-subscription-div .mdc-text-field--textarea .mdc-floating-label--float-above {
    margin-top: 0px;
    width: 35%;
    left: 0px;
}