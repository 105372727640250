@import '../../../variables.scss';

.solution-card__component {
  width: 100%;
  max-width: 90%;
  min-height: 261px;
  height: 101%;
  float: left;
  padding: 16px;
  margin-right: 16px;
}

.solution-card__component:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.solution-card__logo_freeTrial_div {
  display: flex;
  min-height: 100px;
}

.solution-card__top_div {
  height: 70%;
}

.solution-card__logo {
  width: 46px;
  height: 46px;
  border-radius: 1px;
  display: inline-flex;
}

.solution-card__featured_div {
  padding: 5px 0px 5px 0px;
}

.solution-card__featured {
  width: 100%;
  height: 15vh;
  min-width: 90%;
  border-radius: 1px;
  display: inline-flex;
  object-fit: cover;
}

.solution-card__default_logo {
  width: 46px;
  height: 46px;
  border-radius: 1px;
  background-color: #d8d8d8;
  display: inline-flex;
}

.solution-card__freeTrial_div {
  background: #d9eff8;
  height: 25px;
  width: 72px;
  padding: 6px, 10px, 6px, 10px;
  float: left;
  margin-top: 6px;
}

.solution-card__freeTrial_tag {
  font-family: $OpenSans;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: #0b587b;
  padding: 6px 10px;
}

.solution-card__empty_freeTrial_div {
  height: 26px;
}

.solution-card__solutionTitle_div {
  color: #151b25;
  display: grid;
  width: 100%;
  word-break: break-word;
  margin-left: 16px;
  font-family: $Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.15px;
  //IE-11 specific changes
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    -ms-word-break: break-all;
  }
}

.solution-card__solutionDeveloper {
  float: left;
  font-family: $OpenSans;
  font-size: 12px;
  line-height: 14px;
  color: rgba(21, 27, 37, 0.701961);
  font-weight: 500;
  margin-top: 2px;
  height: fit-content;
}

.solution-card__solutionDesciption_div {
  width: 100%;
  height: 80px;
  color: $fortellis_light_gray2;
  margin-top: 12px;
  overflow: hidden;
  display: block;
  position: relative;
  font-family: $OpenSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  padding-bottom: 10px;
}

.solution-card__ruler {
  height: 1px;
  width: 100%;
  background-color: gray;
  margin-top: 25px;
  opacity: 0.45;
}

.solution-card__worksWith_category_div {
  width: 100%;
  color: $fortellis_text_color3;
  font-family: $OpenSans;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.46px;
  line-height: 16px;
  margin: 16px 16px 0px 0px;
}

.solution-card__worksWith_div {
  display: inline-block;
  font-family: $OpenSans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
}

.solution-card__category_div {
  float: right;
  margin-right: 5px;
}

.solution-card__worksWith_solutionName {
  display: block;
  color: $fortellis_light_gray2;
  width: 55%;
  word-break: break-word;
  font-family: $OpenSans;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  font-weight: bold;
}

.solution-card__category_categoryName {
  float: right;
  width: 51px;
  color: $fortellis_light_gray2;
  font-family: $OpenSans;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 16px;
  margin-right: 5px;
}

.solution-card__private-badge-icon-div {
  opacity: 0.55;
  float: right;
  display: inline-flex;
  margin-top: 5px;
}
