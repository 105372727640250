.dialogs-with-no-scroll {
  height: 417px;
  text-align: center;
}

.ds-login-dialog {
  .mdc-dialog--open .mdc-dialog__surface {
    width: 496px;
  }
}

.ds-content-dialogue {
  height: 48px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
}

.ds-button-content {
  box-sizing: border-box;
  height: 36px;
  width: 384px;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.ds-button-blue,
.ds-button-content {
  border: none;
}
.ds-button-blue {
  box-sizing: border-box;
  height: 36px;
  width: 384px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.cdkLogo {
  padding-top: 25px;
  width: 224px;
  height: 44px;
}

.ds-button-blue-text {
  color: #ffffff;
  height: 36px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 36px;
  text-align: center;
}

.ds-fortellis-logo {
  padding-top: 20px;
  height: 30px;
}

.ds-content {
  height: 48px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding-top: 10px;
}

.common-button-margin {
  margin-top: 20px;
}

.line-margin {
  padding: 20px 65px 0 65px;
  height: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Montserrat;
  font-size: 16px;
  line-height: 24px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.separator::before {
  margin-right: 0.25em;
}

.separator::after {
  margin-left: 0.25em;
}
