.skeleton__logo {
  width: 46px;
  height: 46px;
  border-radius: 1px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  background: #f2f2f2;
}

.skeleton__logo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 100%;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton__solutionTitle_div {
  line-height: 24px;
  margin-top: 8px;
  width: 100%;
  word-break: break-word;
  height: 24px;
  position: relative;
  overflow: hidden;
  background: #f2f2f2;
}

.skeleton__solutionTitle_div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 100%;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton__solutionDesciption_div {
  width: 100%;
  height: 60px;
  line-height: 20px;
  margin-top: 12px;
  overflow: hidden;
  display: block;
  position: relative;
  background: #f2f2f2;
}

.skeleton__solutionDesciption_div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 100%;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton__worksWith_solutionName {
  display: inline-block;
  line-height: 16px;
  width: 55%;
  word-break: break-word;
  background: #f2f2f2;
}

@keyframes loading {
  0% {
    transform: translateX(0);
  }
  50%,
  100% {
    transform: translateX(460px);
  }
}
