.verify_button {
  margin: 8px !important;
}

.verify-account {
  display: inline;
  margin: auto;
  cursor: pointer;
}

.verify-account label {
  cursor: pointer !important;
}

#dialog-micro_deposit h2 {
  margin-right: 4px;
  font-size: calc(min(4vw, 18px));
  height: 24px;
  color: #151b25;
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.micro_deposit_label_text {
  margin-right: 4px;
  font-size: calc(min(4vw, 16px));
  height: 24px;
  opacity: 0.7;
  color: #151b25;
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
}

.micro_deposit_inlineInput-left {
  padding: 0px 10px 0px 0px;
}
.micro_deposit_inlineInput-right {
  padding: 0px 10px 0px 10px;
}

.verify_account_actions {
  margin-left: auto;
}

.verify_account_error {
  padding: 8px 16px;
  border: 2px solid #f443368c;
  border-radius: 9px;
  color: #f44336;
  max-width: 375px;
}
#dialog-micro_deposit .jSaQeL {
  padding: 5px 5px 5px 10px;
}
.verify-ach-account-dropdown {
  width: 98% !important;
}
