@import '../../../variables.scss';

.solution-card-group__title_div {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
  margin-left: 22px;
}

.solution-card-group__big_ruler {
  height: 1px;
  width: 95%;
  background-color: $border;
  margin: 10px auto 16px auto;
}

@media screen and (max-width: 450px) {
  .solution-card-group__big_ruler {
    height: 1px;
    width: 180px;
    background-color: $border;
    margin: 10px 15px 16px 17px;
  }
}

.solution-card-group__showMoreButton_div {
  text-align: center;
  margin-bottom: 32px;
}

.solution-card-group__loading_div {
  height: 38px;
}

.spinner_div {
  text-align: center;
}

.showmore-loading {
  height: 10px;
  width: 10px;
  background: #904778;
  border-radius: 100%;
  display: inline-block;
  margin: 1px;
  animation: pulse 1s ease-in infinite;
  -webkit-animation: pulse 1.2s ease-in infinite;
}

.loader {
  padding: 1px;
  margin-top: 15px;
}
