@import '../../variables.scss';

/*===================SELL IN MP - HERO==================*/
.sell-in-mp-hero-bg {
  background: url('../common/images/sell-on-marketplace-header.svg');
  background-size: cover;
}

.max-width-resp-sell-in-mp-hero {
  max-width: 1280px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  overflow-x: auto; //for table overflow in any page
  /* border: 1px solid;*/
  height: 164px;
  padding: 1% 18% 1% 1%;
}

.sell-in-mp-hero-container {
  font-family: $OpenSans, Arial, Helvetica, sans-serif;
  // font-family: $OpenSans;

  .sell-hero-col-left {
    height: 164px;

    .sell-hero-col-left-inner {
      margin-top: 65px;
      .sell-on-mp-title {
        font-family: $OpenSans;
        font-weight: 500;
        font-size: 34px;
        letter-spacing: -1.2px;
        line-height: 40px;
        margin: -30px 0px 10px 0;
      }

      .sell-on-mp-title-bold {
        color: #6d2a71;
        font-weight: bold;
      }

      .create-an-app {
        font-family: $OpenSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #ffffff;
        margin: 8px 4px;
        padding: 9px 12px;
      }

      .learn-more {
        font-family: $OpenSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #5e2368;
        margin: 0px 4px;
      }
    }
  }

  .sell-in-mp-hero-image {
    height: 330px;
    float: right;
  }

  @media screen and (max-width: 1260px) {
    .sell-in-mp-hero-image {
      height: 220px;
    }
  }
  @media screen and (max-width: 1015px) {
    .sell-in-mp-hero-image {
      display: none;
    }
  }
}

@media screen and (max-width: 420px) {
  .sell-in-mp-hero-container {
    .sell-hero-col-left {
      margin-right: 16px;
      .sell-hero-col-left-inner {
        .sell-on-mp-title {
          font-size: 32px;
          line-height: 34px;
          margin: -30px 0 10px 0;
        }

        .create-an-app {
          margin-top: 16px;
        }

        .learn-more {
          margin-top: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 321px) {
  .sell-in-mp-hero-container .sell-hero-col-left .sell-hero-col-left-inner {
    margin-top: 64px;
  }
}

/*===================end of - SELL IN MP - HERO==================*/

/*====================SELL IN MP - Banner==================*/

.sell-in-mp-banner-grid {
  padding: 0;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .mdc-layout-grid__inner {
    grid-gap: 0;
  }
}

.sell-in-mp-banner-item {
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 77px;
  padding-bottom: 77px;

  .title {
    color: $white;
    font-family: $Montserrat;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.15px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  .description {
    color: $white;
    font-family: $OpenSans;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  }
}

/*====================end of SELL IN MP - Banner=====================*/

/*====================How to Sell==================*/
.how-to-sell-grid {
  padding: 0;
}

.sell-solution-section-header {
  margin-top: 26px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 28px;
}

.icon-background-circle {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  background-color: #6d2a71;
  margin: auto;
}

.icon-sell-page {
  color: white;
  padding-top: 11px;
  padding-left: 12px;
}

.summary-title-sell-page {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 16px;
}

.summary-desc-sell-page {
  color: rgba(0, 0, 0, 0.87);
  font-family: $Raleway;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 27px;
}

.highlighted-text {
  color: $fortellis_purple;
  cursor: pointer;
}

.summary-sell-page {
  padding: 10px;
}

/*====================end of How to Sell=====================*/

/*====================Published Apps==================*/

.published-apps-bg {
  background: url('../common/images/published-apps-sell-page.svg');
  background-size: cover;
  padding-bottom: 35px;
}

.published-apps-title {
  color: #f70d72;
  font-family: $Montserrat;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 32px;
  text-align: center;
  padding-top: 25px;
}

.published-apps-subtitle {
  color: #f6f9fa;
  font-family: $OpenSans;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 20px;
}

.loading-published-apps {
  height: 194px;
  text-align: center;
}

.no-solutions-here {
  color: #f6f9fa;
  font-family: $Raleway;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.published-apps-grid {
  padding: 0;
}

.inline-featured-solution {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featured-list-item-sp {
  text-align: center;
  margin-bottom: 70px;
}

.featured-sol-name {
  color: rgba(255, 255, 255, 0.87);
  font-family: $OpenSans;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
}
.published-apps-sol-logo {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
/*====================end of Published Apps=====================*/

/*====================Go to Market Section==================*/

.go-to-market-section {
  background-color: #019cdf;
  height: 288px;
}

.prospective-partner-txt {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
  text-align: center;
  padding-top: 70px;
}

.review-txt {
  color: #ffffff;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
}

.view-go-to-market-guide {
  border: 2px solid #ffffff;
  border-radius: 4px;
}

.btn-div {
  text-align: center;
  margin-top: 32px;
}

.btn-txt {
  color: #ffffff;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 36px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .go-to-market-section {
    height: 380px;
  }
}

/*====================End of Go to Market Section==================*/

/*===================== Start a Conversation ========================*/
.start-a-conversation {
  .title {
    color: #151b25;
    font-family: $Montserrat;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 32px;

    margin-top: 40px;
    margin-bottom: 16px;
  }
  .description {
    color: #151b25;
    font-family: $OpenSans;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;

    margin-bottom: 32px;
  }
  .start-a-conversation_grid {
    padding: 0;
  }

  .margin-bottom-16 {
    margin-bottom: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px;
  }

  .userPreference_radio-group_container {
    label {
      color: $fortellis_light_gray2;
      font-family: $Raleway;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      margin-right: 8px;
    }
    .mdc-form-field {
      width: auto !important;
      //border: 1px solid;
      // margin-top: 24px;
      margin-top: -25px;
      margin-left: 16px;
      margin-right: 16px;
      // display: "inline-flex";
    }
  }
  .action-container {
    margin-top: 36px;
    margin-bottom: 70px;
  }

  .message-class {
    div {
      label {
        span {
          padding-top: 0.175rem;
        }
      }
    }
  }

  .submit_que_spinner {
    height: 36px;
  }
}

.character-counter {
  color: rgba(21, 27, 37, 0.701961);
  font-family: $OpenSans;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 14px;
  float: right;
  text-align: right;
}

/*====================end of  Start a Conversation ==================*/

.spinner-text {
  color: white;
  text-align: center;
  font-family: Montserrat;
}
