@import '../../variables.scss';

.consent-pdf-container {
  overflow-x: hidden;
  text-align: center;
  li {
    background-color: rgb(82, 86, 89);
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+
  .consent-pdf-container {
    height: 65vh;
  }
}

.consent-pdf::-webkit-scrollbar {
  display: none;
}

.consent-pdf {
  height: 380px;
  width: 100%;
  margin-bottom: -14px;
}

.consent-input-container {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Raleway;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  .mdc-form-field {
    padding-bottom: 0;
  }
  margin-left: 3%;
}

.dialog-description {
  margin-top: 16px;
  margin-bottom: 16px;
}

.consent-acceptance-input-field-container {
  margin-left: 40px;
  .mdc-text-field {
    .mdc-floating-label--float-above {
      -webkit-transform: translateY(-50%) scale(0.75);
      transform: translateY(-50%) scale(0.75);
    }
  }
}
.consent-rejection-input-field-container {
  margin-left: 40px;

  .mdc-text-field--textarea .mdc-floating-label {
    margin-top: -2px;
  }
  .mdc-text-field .mdc-text-field__input {
    padding-top: 26px;
  }

  .mdc-text-field--textarea .mdc-text-field__input {
    border: none;
  }

  .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
    margin-top: 0;
  }
}

.ul-no-disc {
  list-style-type: none;
  margin: 0;
  padding: 70px;
  background-color: #dedede;
}

.tnc-pdf-container {
  background: #fafafa url('../../assets/freeLoader_white_bg.gif') no-repeat
    center center;
  text-align: center;
}
.electronic-consent-dialog .mdc-dialog--open .mdc-dialog__container,
.consent-dialog .mdc-dialog--open .mdc-dialog__container {
  height: inherit;
  width: inherit;
}

.ul-no-disc li > canvas {
  width: 100%;
}

.consent_dialog_title_div {
  display: flex;
  background-color: #904778;
  padding: 12px 20px;
}

.consent-dialog_title {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
}

.consent-dialog_back_btn {
  color: #ffffff;
  cursor: pointer;
}

.consent-dialog {
  .mdc-dialog--open .mdc-dialog__surface {
    max-width: 80vw;
    max-height: 90vh;
  }
}

.consent-dialog__unableToLoadPdf {
  margin-top: 60px;
}

.consent-dialog__unableToLoadPdf a {
  color: #904778;
}

.consent-dialog__viewer {
  margin-top: -60px;
  transform: scale(1.5);
  transform-origin: 50% 0%;
}

#user-consent-dialog__dialog_content {
  overflow-y: hidden;
}

.session-refresh-dialog-marketplace .mdc-dialog__container {
  height: auto;
  width: auto;
}

//IE 10+ only changes...
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .consent-dialog-confirmation .mdc-dialog__container {
    height: auto;
  }
}
