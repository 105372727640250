@import '../../../../variables';

.sp_text-field {
  .mdc-text-field .mdc-floating-label {
    //color: rgba(0, 0, 0, 0.6);
    font-family: $Raleway;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }

  .mdc-text-field--dense .mdc-floating-label--float-above {
    -webkit-transform: translateY(-100%) scale(0.923);
    transform: translateY(-100%) scale(0.923);
  }

  .mdc-text-field .mdc-floating-label {
    left: 16px;
  }

  .mdc-text-field .mdc-floating-label--float-above {
    left: 16px;
  }
  .mdc-text-field .mdc-text-field__input {
    padding-left: 16px;
    padding-right: 16px;
  }

  .mdc-text-field__input {
    //color: rgba(0,0,0,0.6);
    font-family: $Raleway;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: $fortellis_error_red;
  }

  .mdc-text-field + .mdc-text-field-helper-text {
    padding-left: 16px;
  }
}
