.frc-footer {
  &--cover {
    width: 100%;
  }
}

.frc-footer__row--account {
  background: url('../../images/subpage-header-bg.svg') center no-repeat;
  background-size: cover;
}

.frc-drawer__footer {
  &--primary {
    background: url('../../images/subpage-header-bg.svg') center no-repeat;
    background-size: cover;
  }
}
