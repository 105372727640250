@import '../../variables.scss';

.extensibility {
  width: 100%;
  position: relative;
}
.extensibility-grid {
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  display: inline-block;
  scrollbar-width: none;
}

.extensibility-grid::-webkit-scrollbar {
  display: none;
}

.extensibility-row {
  height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.extensibility-card__component {
  width: 280px;
  height: 200px;
  display: inline-block;
  padding: 16px;
  margin: 18px 8px;
}

.extensibility-card__component:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.extensibility-card__top_div {
  height: 76%;
}

.extensibility-card__logo {
  width: 46px;
  height: 46px;
  border-radius: 1px;
  display: inline-flex;
}

.extensibility-card__default_logo {
  width: 46px;
  height: 46px;
  border-radius: 1px;
  background-color: #d8d8d8;
  display: inline-flex;
}

.extensibility-card__solutionTitle_div {
  color: $fortellis_light_gray2;
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin-top: 8px;
  width: 100%;
  word-break: break-word;
}

.extensibility-card__solutionDeveloper_div {
  height: 16px;
  color: $fortellis_text_color3;
  font-family: $Raleway;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
}

.extensibility-card__solutionDesciption_div {
  width: 100%;
  height: 60px;

  color: $fortellis_light_gray2;
  font-family: $Raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 12px;
  overflow: hidden;
  display: block;
  position: relative;
}

.extensibility-card__private-badge-icon-div {
  opacity: 0.5;
  display: inline-flex;
  margin-top: 8px;
  margin-left: auto;
}

.extensibility_back-arrow {
  position: absolute !important;
  height: 55px !important;
  width: 55px !important;
  z-index: 1;
  cursor: pointer;
  bottom: 86px;
}
.extensibility_next-arrow {
  position: absolute !important;
  height: 55px !important;
  width: 55px !important;
  left: -30px;
  z-index: 1;
  cursor: pointer;
  bottom: 86px;
}

.extensibility_navigation-arrow {
  position: relative;
  height: 0px !important;
  width: 0px !important;
}
